import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { consumerKey, consumerSecret } from "../apiUrls";
import { useState } from "react";

const useAddOrder = () => {
  const [orderLoadingBtn, setOrderLoadingBtn] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const queryClient = useQueryClient();

  const addOrder = async (orderData) => {
    try {
      setOrderLoadingBtn(true);
      const userId = localStorage.getItem("userId");
      const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
      const ordersUrl = `https://admin.wiscart.in/wp-json/wc/v3/orders?customer=${userId}`;
      console.log("in hook:", ordersUrl, orderData);
      const response = await axios.post(ordersUrl, orderData, {
        headers: {
          Authorization: authHeader,
        },
      });
      console.log("order response", response);
      setOrderLoadingBtn(false);
      setOrderSuccess(true);
      return response.data;
    } catch (error) {
      console.log("Error adding order:", error);
      setOrderLoadingBtn(false);
      setOrderSuccess(true);
      throw error;
    }
  };

  const mutation = useMutation(addOrder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("orders");
    },
  });

  return {
    addOrder: mutation.mutate,
    orderLoadingBtn: mutation.isLoading || orderLoadingBtn,
    orderSuccess: orderSuccess,
  };
};

export { useAddOrder };
