import React, { useEffect, useState } from "react";
import {
  FilterIconDiv,
  FilterIconText,
  ListHead,
  ListProducts,
  ListTitle,
  ListTypeIcon,
  ListTypeIcons,
  ProductsListDiv,
  ProductsListing,
} from "./ProductsListElements";
import ProductFilter from "./ProductFilter";
import AllProducts from "../AllProducts/AllProducts";
import MenuBar from "../Navbar/MenuBar";

import { useParams } from "react-router-dom";
import { ProductBox } from "../Home/HomeElements";
import { useSelector } from "react-redux";
import { useProductsQuery } from "../../queries/api";
import { useQuery } from "@tanstack/react-query";
import {
  attributes_api,
  backendUrl,
  consumerKey,
  consumerSecret,
} from "../../apiUrls";

const ProductsList = () => {
  // GET CATEGORY DETAILS FROM PARAMS
  const { category, categoryId } = useParams();

  // STATE HOOKS
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  // const [notFiltering, setNotFiltering] = useState(false);

  // FETCHING API DATA
  const { data: products } = useProductsQuery();

  // GET FILTER ATTRIBUTE FROM REDUX
  const { filterAttribute } = useSelector((state) => state.product);
  console.log("test 1: ", filterAttribute, "test2: ", filteredProducts)
  // console.log("test some: ", filterAttribute ? "filterAttribute" : "filteredProducts")

  // CAROUSAL RESPONSIVE STYLES
  const productListRes = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 500 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  // FUNCTION TO HANDLE TOGGLE MENU
  const handleToggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  // FETCH ATTRIBUTES
  const fetchAttributes = async () => {
    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const attributesResponse = await fetch(
      `${backendUrl}${attributes_api}/${categoryId}`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
    const attributesData = await attributesResponse.json();
    return attributesData;
  };

  const {
    data: attributes,
    error: attributesError,
    isLoading: attributesLoading,
    refetch: refetchAttributes,
  } = useQuery(["attributes"], fetchAttributes);

  const propAttributes = {
    attributes: attributes || [],
    attributesError: attributesError,
    attributesLoading: attributesLoading,
  };

  // USEEFFECT FOR UPDATE FILTERED PRODUCTS
  useEffect(() => {
    const updatedFilteredProducts = products?.filter((item) => {
      return item.categories.some(
        (categoryObj) => categoryObj.name.toLowerCase() === category.toLowerCase()
      );
    });
    setFilteredProducts(updatedFilteredProducts);
  }, [categoryId, products, category]);

  // USEEFFECT FOR REFETCH ATTRIBUTES
  useEffect(() => {
    refetchAttributes();
    // setNotFiltering(true);
  }, [categoryId, refetchAttributes]);

  if (attributesError) return <div>error</div>;
  return (
    <ProductsListing>
      <ListHead>
        <ListTitle>Product Listing</ListTitle>
        {/* <ListTypeIcons>
          <ListTypeIcon>
            <i className="fas fa-solid fa-th-large"></i>
          </ListTypeIcon>
          <ListTypeIcon>
            <i className="fas fa-solid fa-list"></i>
          </ListTypeIcon>
        </ListTypeIcons> */}
        <MenuBar
          menuOpen={menuOpen}
          onCloseMenu={handleToggleMenu}
          isFilter={!attributesLoading && attributes}
        />
        <FilterIconDiv onClick={handleToggleMenu}>
          <FilterIconText>Filter</FilterIconText>
          <i className="fas fa-light fa-filter"></i>
        </FilterIconDiv>
      </ListHead>

      <ListProducts>
        <ProductFilter propAttributes={propAttributes} />
        <ProductsListDiv>
          <ProductBox>
            <AllProducts
              data={filterAttribute?.length > 0 ? filterAttribute : filteredProducts}
              // data={notFiltering ? filterAttribute : filteredProducts}
              responsive={productListRes}
              shop={true}
            />
          </ProductBox>
        </ProductsListDiv>
      </ListProducts>
    </ProductsListing>
  );
};

export default ProductsList;
