import React from "react";
import styled from "styled-components";

const ButtonStyle = styled.button`
  background-color: #82ba32;
  color: #fff;
  border: 1px solid #9d9999;
  border-radius: 1rem;
  transition: all 0.3s ease-in;
  width: 6rem;
  height: 2rem; /* Adjusted height for media query */

  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 800px) {
    width: 8rem; /* Media query width */
    height: 2.3rem; /* Media query height */
    &:hover {
      background-color: #82ba32;
    }
  }
`;

const Temp = ({ title, style, onClick, type }) => {
  const mergedStyles = { ...ButtonStyle.defaultProps, ...style };

  return (
    <ButtonStyle type={type} onClick={onClick} style={mergedStyles}>
      {title}
    </ButtonStyle>
  );
};

export default Temp;
