import React, { useState } from "react";
import {
  NavInput,
  SearchContent,
  SearchCount,
  // SearchIcon,
  // SearchImg,
  SearchImgIcon,
  SearchInputBar,
  SearchList,
} from "./NavbarElements";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../Home/Home";
import { useProductsQuery } from "../../queries/api";
import SearchLogo from "../../images/search.png";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid #d0d0d0;
  width: 500px;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px 20px;
  border: 0px solid #d0d0d0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: whitesmoke;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  background-color: whitesmoke;
`;

const MagnifyingGlassIcon = styled.i`
  cursor: pointer;
  color: #d0d0d0;
  font-size: 25px;
`;

const ResultsInfo = styled.div`
  border-top: 1.5px solid #d0d0d0;
  border-bottom: 1.5px solid #d0d0d0;
  background-color: whitesmoke;
  padding: 5px 20px;
  color: #9d9999;
`;

const FilteredList = styled.div`
  padding: 0px 0px;
  height: 250px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9d9999;
  }
`;

const ProductItem = styled.div`
  padding: 10px 20px;
  border-bottom: 0.1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  color: #9d9999;
  cursor: pointer;
`;

const SearchImg = styled.img`
  width: 28px;
  height: 50px;
`;

const SearchBar = () => {
  // SEARCH TERM START
  const navigate = useNavigate();

  // STATE HOOKS
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearch, setIsSearch] = useState("");

  // FETCHING API DATA
  const { data: productsList, isLoading: loading } = useProductsQuery();

  // FUNCTION TO HANDLE INPUTS
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    setIsSearch(event.target.value);
  };

  // FUNCITON TO FILTER PRODUCTS FOR SEARCH
  const filteredProducts =
    !loading &&
    productsList?.filter((product) => {
      const nameMatch = product?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const priceMatch = product?.price?.toString().includes(searchQuery);
      const categoryMatch = product?.categories[0]?.name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || priceMatch || categoryMatch;
    });

  // FUNCTION HANDLE TO REDIRECT
  const handleClick = (item) => {
    const product_name = item?.name.replace(/\s/g, "-");
    setIsSearch("");
    navigate(`/product/${item.categories[0].name}/${product_name}/${item?.id}`);
    scrollToTop();
  };

  return (
    // <SearchInputBar>
    // <NavInput type="text" value={searchQuery} onChange={handleInputChange} />
    // <SearchImgIcon
    //   onClick={() => {
    //     navigate("/products");
    //   }}
    //   src={SearchLogo}
    // />
    //   {isSearch && (
    //     <SearchList>
    //       <SearchCount>{filteredProducts.length} Items Found</SearchCount>
    // {filteredProducts.map((product) => (
    //   <SearchContent
    //     onClick={() => handleClick(product)}
    //     key={product.id}
    //   >
    //     <SearchImg
    //       className="img-object"
    //       src={product?.images[0]?.src}
    //       alt=""
    //     />
    //     <p>{product?.name}</p>
    //   </SearchContent>
    // ))}
    //     </SearchList>
    //   )}
    // </SearchInputBar>
    <Container>
      <ContentWrapper>
        <Header>
          <SearchInput
            type="text"
            value={searchQuery}
            placeholder="Search here"
            onChange={handleInputChange}
          />
          <MagnifyingGlassIcon
            onClick={() => {
              navigate("/products");
            }}
            className="fa-solid fa-magnifying-glass"
          ></MagnifyingGlassIcon>
        </Header>
      </ContentWrapper>
      <ResultsInfo>
        <p>{filteredProducts?.length} results found</p>
      </ResultsInfo>
      <FilteredList>
        {!loading &&
          filteredProducts?.map((product) => (
            <ProductItem onClick={() => handleClick(product)} key={product.id}>
              <SearchImg
                className="img-object"
                src={product?.images[0]?.src}
                alt=""
              />
              <p>{product?.name}</p>
            </ProductItem>
          ))}
      </FilteredList>
    </Container>
  );
};

export default SearchBar;

// {/* <div style={{ border: "1px solid #d0d0d0", width: "500px" }}>
//   <div style={{ padding: "20px 20px", border: "0px solid #d0d0d0" }}>
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         padding: "8px 10px",
//         backgroundColor: "whitesmoke",
//       }}
//     >
//       <NavInput
//         type="text"
//         value={searchQuery}
//         placeholder="Search here"
//         onChange={handleInputChange}
//       />

//       <i
//         style={{ cursor: "pointer", color: "#d0d0d0", fontSize: "25px" }}
//         onClick={() => {
//           navigate("/products");
//         }}
//         class="fa-solid fa-magnifying-glass"
//       ></i>
//     </div>
//   </div>
//   <div
//     style={{
//       borderTop: "1.5px solid #d0d0d0",
//       borderBottom: "1.5px solid #d0d0d0",
//       backgroundColor: "whitesmoke",
//       padding: "5px 20px",
//       color: "#9d9999",
//     }}
//   >
//     <p>{filteredProducts?.length} results found</p>
//   </div>
//   <div style={{ padding: "0px 0px", height: "250px", overflowY: "scroll" }}>
//     {!loading &&
//       filteredProducts?.map((product) => (
//         <div
//           style={{
//             padding: "10px 20px",
//             borderBottom: ".1px solid #d0d0d0",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "start",
//             gap: "20px",
//             color: "#9d9999",
//           }}
//           onClick={() => handleClick(product)}
//           key={product.id}
//         >
//           <SearchImg
//             className="img-object"
//             src={product?.images[0]?.src}
//             alt=""
//           />
//           <p>{product?.name}</p>
//         </div>
//       ))}
//   </div>
// </div>; */}
