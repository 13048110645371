import styled from "styled-components";

export const BlogsSection = styled.div`
  margin: 2rem 5rem;
  display: grid;
  gap: 2rem;

  @media (max-width: 768px) {
    margin: 2rem 1rem;
  }
`;
export const LatestBlog = styled.div`
  display: grid;
  gap: 10px;
`;
export const BlogTypeTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
`;
export const LatestBlogCard = styled.div`
  display: flex;
  gap: 20px;
  padding: 1rem;
  border: 1px solid #9d9999;
  border-radius: 2rem;

  @media (max-width: 768px) {
    display: block;
    gap: 0px;
    border-radius: 1rem;
    padding: 0.7rem;
  }
`;
export const LatestBlogImgDiv = styled.div`
  flex: 1;
`;
export const LatestBlogImg = styled.img`
  width: 100%;
  border-radius: 2rem;

  @media (max-width: 768px) {
    border-radius: 1.5rem;
  }
`;
export const LatestBlogContent = styled.div`
  flex: 1;
  display: grid;

  @media (max-width: 768px) {
    padding: 0 0.5rem;
  }
`;
export const LatestBlogTitle = styled.p`
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;
export const LatestBlogDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
`;
// blog list

export const BlogsList = styled.div`
  display: grid;
  gap: 10px;
`;
export const BlogCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media (max-width: 768px) {
    gap: 2px;
  }
`;
export const BlogCard = styled.div`
  width: 250px;
  padding: 1rem;
  border: 1px solid #9d9999;
  border-radius: 1rem;
  flex-basis: calc(25.22% - 10px);

  @media (max-width: 768px) {
    flex-basis: calc(48%);
    padding: 0.5rem;
    border-radius: 0.7rem;
  }
`;
export const BlogImgDiv = styled.div``;
export const BlogImg = styled.img`
  width: 100%;
  border-radius: 1rem;
  @media (max-width: 768px) {
    border-radius: 0.7rem;
  }
`;
export const BlogContent = styled.div``;
export const BlogTitle = styled.p`
  font-size: 15px;
  font-weight: 600;
`;
export const BlogDescription = styled.p`
  font-size: 15px;
  font-weight: 300;
`;
export const BlogBtnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
export const BlogDate = styled.p`
  font-size: 10px;
  font-weight: 300;
`;
export const BlogBtn = styled.button`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #9d9999;
  background-color: #9d9999;
  font-size: 10px;
  cursor: pointer;

  :hover {
    background-color: #9d9999;
  }
`;

// Single Blog Page

export const BlogSection = styled.div`
  margin: 2rem 5rem;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    margin: 2rem 1rem;
    display: grid;
  }
`;
export const SingleBlog = styled.div`
  flex: 3;
  display: grid;
  gap: 20px;

  border: 1px solid #9d9999;
  border-radius: 2rem;
  padding: 1rem;
`;
export const SingleBlogTitle = styled.p`
  font-size: 25px;
  font-weight: 600;
`;
export const SingleBlogImgDiv = styled.div``;
export const SingleBlogImg = styled.img`
  border-radius: 2rem;
  width: 100%;
  /* height: 70vh; */
  /* object-fit: fill; */
  object-fit: contain;
`;
export const BlogDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BlogCategory = styled.p`
  font-weight: 300;
  font-size: 14px;
`;
export const MoreBlogsDiv = styled.div`
  flex: 1;
  height: fit-content;

  border: 1px solid #9d9999;
  border-radius: 2rem;
  padding: 1rem;
`;
export const MoreBlogs = styled.div``;
export const MoreBlog = styled.div`
  margin: 1rem 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #9d9999;
`;
export const MoreBlogTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: #9d9999;
  }
`;
export const MoreBlogDesc = styled.p`
  font-size: 13px;
  font-weight: 300;
`;

export const MoreCategoriesDiv = styled.div`
  padding-top: 1rem;
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
`;
export const CategoriesDiv = styled.div`
  display: grid;
  gap: 10px;
`;
export const CategoryName = styled.p`
  border-bottom: 1px solid #9d9999;
  padding: 0.5rem 0;
  cursor: pointer;
  :hover {
    color: #9d9999;
  }
`;
