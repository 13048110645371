import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { InnerBanner } from "../components/Home/Home";
import Footer from "../components/Footer/Footer";
import Category from "../components/Categories/Category";

const CategoriesPage = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner />
      <Category />
      <Footer />
    </div>
  );
};

export default CategoriesPage;

{
  /* <div>
        {!categoriesLdg && (
          <MegaMen>
            {topLevelCategories?.map((category, i) => {
              const categoryName = category?.name?.toLowerCase();
              return (
                <MegaMenuDi
                  style={
                    topLevelCategories.length > 15
                      ? { borderBottom: "0px solid #9d9999" }
                      : null
                  }
                  key={i}
                >
                  <MegaHea
                    onClick={() => {
                      navigate(`/${categoryName}/${category?.id}/products`);
                    }}
                  >
                    {category?.name}
                  </MegaHea>
                  <MegaSubss>
                    {categories
                      ?.filter(
                        (subcategory) => subcategory.parent === category.id
                      )
                      .map((subcategory, index) => {
                        const subCateName = subcategory?.name?.toLowerCase();
                        return (
                          <MegaSu key={index}>
                            <MegaTex
                              onClick={() => {
                                navigate(
                                  `/${subCateName}/${subcategory?.id}/products`
                                );
                              }}
                            >
                              {subcategory.name}
                            </MegaTex>
                          </MegaSu>
                        );
                      })}
                  </MegaSubss>
                </MegaMenuDi>
              );
            })}
          </MegaMen>
        )}
      </div> */
}
