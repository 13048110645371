import React from "react";
import Button from "../Button";
import styled from "styled-components";
import { useCartQuery } from "../../queries/api";
import { useNavigate } from "react-router-dom";

const PriceDetailsContainer = styled.div`
  width: 70%;
  display: grid;
`;

const SummaryText = styled.p`
  font-size: 14px;
`;

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 5px 0;
  font-size: 13px;
  color: #000000a5;
`;

const SubSection = styled.div`
  font-size: 13px;
  color: #000000a5;
  display: grid;
  gap: 10px;
  padding: 15px 0;
`;

const SubSectionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PriceTotalDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 13px;
`;

const PriceDetails = ({ isCheckout }) => {
  const navigate = useNavigate();

  // FETCHING API DATA
  const { data: cartList, error: cartErr, isLoading: cartLdg } = useCartQuery();

  // CART TOTAL
  const cartTotal = !cartLdg && cartList?.cart_total && cartList?.cart_total[0];
  console.log("cartTotal", cartTotal)

  // FUNCTION TO HANDLE PLACE ORDER
  const handlePlaceOrder = () => {
    const token = localStorage.getItem("token");
    const isToken = token === "null" ? null : parseFloat(token);

    if (isToken) {
      navigate("/checkout/buy");
    } else {
      navigate("/login");
    }
  };
  return (
    <PriceDetailsContainer>
      <SummaryText>Summery</SummaryText>
      <SectionWrapper>
        <p>Estimate Shipping and Tax</p>
        <i className="fa-solid fa-angle-down"></i>
      </SectionWrapper>
      <SubSection>
        <SubSectionItem>
          <p>Subtotal</p>
          <p>${cartTotal?.cart_total}</p>
        </SubSectionItem>
        <SubSectionItem>
          <p>Shipping (Flat Rate-Fixed)</p>
          <p>$0</p>
        </SubSectionItem>
      </SubSection>
      <div>
        <PriceTotalDetails>
          <SummaryText>Estimate Shipping and Tax</SummaryText>
          <p>${cartTotal?.subtotal}</p>
        </PriceTotalDetails>
        <SectionWrapper>
          <p style={{ fontSize: "13px" }}>Apply Discount Code</p>
          <i className="fa-solid fa-angle-down"></i>
        </SectionWrapper>
      </div>
      <Button
        onClick={isCheckout || handlePlaceOrder}
        style={{
          width: "100%",
          borderRadius: "0",
          height: "35px",
          marginTop: "10px",
          fontSize: "13px",
        }}
        title={"Proceed To Checkout"}
      />
    </PriceDetailsContainer>
  );
};

export default PriceDetails;
