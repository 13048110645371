import React from "react";
import AboutUs from "../components/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";
import { HomeSliderSection } from "../components/Home/Home";
import Navbar from "../components/Navbar/Navbar";

const AboutUsPage = () => {
  return (
    <div>
      <Navbar />
      <HomeSliderSection />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
