import React from "react";
import WishlistTable, { WishlistLoading } from "./WishlistTable";
import { useWishlistQuery } from "../../queries/api";
import { WishListNull, WishListSection, WishTitle } from "./WishListElements";
import Skeleton from "react-loading-skeleton";

const WishList = () => {
  // FETCHING API DATA
  const { data: wishList, isLoading: wishLoading } = useWishlistQuery();

  return (
    <WishListSection>
      <WishTitle>Wishlist</WishTitle>
      {wishLoading ? (
        <WishListNull>
          <div style={{ width: "100%" }}>
            <Skeleton height={25} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
        </WishListNull>
      ) : wishList.status === false ? (
        <WishListNull>{wishList.msg}</WishListNull>
      ) : (
        <WishlistTable wishLoading={wishLoading} wishList={wishList} />
      )}
    </WishListSection>
  );
};

export default WishList;
