import styled, { keyframes } from "styled-components";

// ------- TOP NAV
export const Nav = styled.div`
  width: 100%;
  z-index: 1001;
  @media screen and (min-width: 950px) {
    margin-bottom: ${({ isSticky }) => (isSticky ? "5rem" : "1rem")};
  }
  @media screen and (max-width: 950px) {
    margin-bottom: 5rem;
  }
`;

export const MobileNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 10px;
  @media screen and (min-width: 950px) {
    display: none;
  }
`;
export const WebViewNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* position: relative; */
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const TopNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 2.5rem; */
  border-bottom: 1.5px solid #9d9999;
  height: 45px;
  padding-left: 5px;
  background-color: white;

  @media screen and (max-width: 950px) {
    height: 65px;
    /* position: fixed; */
    position: ${(props) => (props.pathname === "/login" ? "sticky" : "fixed")};
    top: 0;
    width: 100%;
    z-index: 1100;
    margin-bottom: 100px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border: none;
  }
`;

export const ShopLogoMob = styled.img`
  cursor: pointer;
  width: 130px;
  @media screen and (min-width: 950px) {
    display: none;
  }
`;
export const ShopNameTop = styled.p`
  color: green;
  font-size: 22px;
  font-weight: 600;

  @media screen and (min-width: 950px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  gap: 5px;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
export const MobIcon = styled.img`
  margin-right: 6px;
  cursor: pointer;
`;
export const MobNumber = styled.p`
  margin-right: 6px;
`;

export const UserDetails = styled.div`
  display: flex;
  color: #5c5959;
  align-items: center;
  margin-right: 0.5rem;
`;

export const Delivery = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 300;
  color: #5c5959;

  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 11px;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    font-size: 9px;
    margin-right: 0.5rem;
  }
`;
export const DeliIcon = styled.img`
  margin-right: 4px;

  @media screen and (max-width: 950px) {
    width: 12px;
    margin-right: 2px;
  }
`;
export const DeliText = styled.p``;

export const IconDiv = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 300;
  color: #5c5959;

  margin-right: 13px;
  cursor: pointer;

  display: grid;
  justify-content: center;

  @media screen and (max-width: 950px) {
    margin-right: 3px;
  }
  position: relative;
`;
export const Icon = styled.img`
  width: 25px;
  margin-left: 7px;
  border-radius: 50%;
`;
export const UserText = styled.p`
  font-size: 9px;
  text-align: center;
`;

// ------- TOP NAV

// ------- MAIN NAV

export const MainNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  align-items: center;
  height: 75px;
  background-color: white;
  transition: background-color 0.3s linear;

  @media screen and (max-width: 950px) {
    display: none;
    /* display: ${({ isSearc }) => (isSearc ? "flex" : "none")}; */
  }
  position: relative;
`;
export const ShopLogoWeb = styled.img`
  cursor: pointer;
  width: 150px;
  @media screen and (max-width: 950px) {
    display: none;
  }
`;
export const ShopName = styled.p`
  color: green;
  font-size: 30px;
  font-weight: 600;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
export const NavLinks = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 16.5px;
  div {
    height: 100%;
    display: block;
    align-items: center;
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
export const NavLink = styled.a`
  margin-right: 2rem;
  cursor: pointer;
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #82ba32;
  }
`;
export const NavCart = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 21px;
  cursor: pointer;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CartCount = styled.span`
  font-size: 13px;
  /* background-color: #82ba32; */
  background-color: #2faa04;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -7px;
  right: -6px;
`;

export const NavFav = styled.div`
  margin-right: 1rem;
  font-size: 21px;
  cursor: pointer;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
  @media screen and (max-width: 950px) {
    /* display: block; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FavCount = styled.span`
  font-size: 13px;
  background-color: #82ba32;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -7px;
  right: -6px;
`;

// // export const SearchInputBar = styled.div`
// //   background-color: white;
// //   display: flex;
// //   justify-content: center;
// //   align-items: center;
// //   /* width: 350px; */
// //   /* width: 35%; */
// //   /* height: 32px; */
// //   height: 35px;
// //   padding: 0 15px;
// //   border-radius: 50px;
// //   border: 1px solid #9d9999;
// //   position: relative;
// //   width: 500px;
// //   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

// //   @media screen and (max-width: 950px) {
// //     width: 300px;
// //   }
// // `;

// export const SearchInputBar = styled.div`
//   background-color: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 35px;
//   padding: 0 15px;
//   border-radius: 50px;
//   border: 1px solid #9d9999;
//   position: relative;
//   width: 500px;
//   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
//   animation: slideIn 0.5s forwards;

//   @media screen and (max-width: 950px) {
//     width: 300px;
//   }

//   @keyframes slideIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }
// `;

// export const NavInput = styled.input`
//   width: 100%;
//   height: 100%;
//   border: none;
//   outline: none;
//   font-size: 15px;
//   font-weight: 500;
//   background-color: whitesmoke;
// `;
// export const SearchIcon = styled.div`
//   font-size: 21px;
//   cursor: pointer;
//   background-color: whitesmoke;
// `;
// // export const SearchImgIcon = styled.img`
// //   cursor: pointer;
// //   width: 21px;
// // `;

// export const SearchList = styled.div`
//   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
//   width: 100%;
//   background-color: white;
//   position: absolute;
//   top: 2rem;
//   z-index: 1001;
//   border: 1px solid #9d9999;
//   padding: 0.5rem;
//   max-height: 300px;
//   overflow-y: auto;

//   ::-webkit-scrollbar {
//     width: 2px;
//   }

//   ::-webkit-scrollbar-track {
//     background-color: transparent;
//   }

//   ::-webkit-scrollbar-thumb {
//     background-color: #9d9999;
//   }
// `;

// export const SearchCount = styled.p`
//   display: flex;
//   font-size: 12px;
//   justify-content: center;
//   align-items: center;
//   border-bottom: 1px solid #9d9999;
// `;
// export const SearchContent = styled.div`
//   display: flex;
//   font-size: 10px;
//   align-items: center;
//   gap: 10px;
//   cursor: pointer;
//   :hover {
//     background-color: aliceblue;
//   }
// `;
// export const SearchImg = styled.img`
//   width: 28px;
//   height: 50px;
// `;

export const CartIcon = styled.img``;

// ------- MAIN NAV

// ------- MEGA MENU

export const PopUpSearch = styled.div`
  position: absolute;
  top: 92px;
  right: 13rem;

  display: grid;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1100;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 950px) {
    top: 70px;
    right: 0;
  }
`;

export const PopUpMenu = styled.div`
  position: absolute;
  left: 0;

  display: grid;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1100;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;
export const MegaMenu = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: top;
  background-color: #fff;

  /* position: absolute; */
  z-index: 1001;
  border: 1px solid #9d9999;

  @media screen and (max-width: 950px) {
    position: fixed;
    bottom: 3.3rem;
    display: grid;
  }

  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transition: all 1s ease-in;
`;
export const MegaMenuDiv = styled.div`
  padding: 0.5rem 2rem;
  height: 70vh;

  @media screen and (max-width: 950px) {
    margin: 1rem 3rem;
  }
`;
export const MegaHead = styled.p`
  margin-bottom: 0.7rem;
  font-weight: 600;
  font-size: 19px;
  cursor: pointer;
  color: #82ba32;
`;
export const MegaSubs = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start;
  gap: 2rem;
`;
export const MegaSub = styled.div`
  /* margin-right: 1rem; */
  cursor: pointer;
  &:hover {
    font-size: 20px;
  }
  @media screen and (max-width: 950px) {
    margin-right: 3rem;
  }
`;
export const MegaText = styled.p`
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 13px;

  color: #000000;

  &:hover {
    color: #9d9999;
    /* color: #82ba32; */
  }
`;
export const MoreBtn = styled.button`
  color: green;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  position: absolute;
  right: 2rem;
  bottom: 1rem;
`;

// ------- MENU BAR

export const MenuSection = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1001;

  @media screen and (min-width: 950px) {
    display: none;
  }
`;
export const MenuLinks = styled.div`
  background-color: #82ba32;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
`;
export const MenuLink = styled.button`
  display: grid;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  margin: 0.5rem 0;
  background-color: transparent;
  border: none;
`;
export const MenuIcon = styled.p`
  font-size: 15px;
`;
export const MenuTitle = styled.p`
  font-size: 10px;
`;

// SLIDE MENU

export const NavBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: whitesmoke;
  width: 100%;
  /* height: 100vh;  */
  height: 100%;
  z-index: 1100;
  color: #000000;

  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;
`;
export const MenuItemsDiv = styled.div``;

export const MenuHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #9d9999;
  padding: 0 30px;
  height: 65px;
  background-color: #82ba32;

  i {
    font-size: 25px;
  }
  h1 {
    font-size: 18px;
    font-weight: 300;
  }
`;
export const MenuIco = styled.div`
  cursor: pointer;
  i {
    font-size: 22px;
  }
  @media screen and (min-width: 950px) {
    display: none;
  }
`;
export const HeaderText = styled.h1``;

export const MenuItems = styled.div`
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0; /* Chrome, Safari, and Opera */
  }
`;
export const MenuSelectItems = styled.select``;
export const MenuAttributes = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AttributeArrow = styled.img`
  width: 20px;
`;

export const MenuItem = styled.div`
  border-bottom: 1px solid #9d9999;
  margin: 0 20px;
  padding: 0 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    border-color: green;
    background-color: #f8f8f8;
  }
`;

export const MenuBottomDiv = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: whitesmoke;
`;
export const AccountMenu = styled.div`
  padding: 0 20px;
  height: 60px;
  border-top: 2px solid #9d9999;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
export const AccountIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  i {
    font-size: 20px;
  }
`;

export const AccountBtnDiv = styled.div``;

// Overlay
export const Overlay = styled.div`
  position: fixed;
  /* top: 145px; */
  top: 120px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  filter: brightness(0.5);
  z-index: 1;

  @media screen and (max-width: 950px) {
    top: 100px;
  }
`;
