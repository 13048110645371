import React from "react";
import Spinner from "../../images/spinner.gif";
import {
  ChangePassButton,
  ChangePassInput,
  ChangePassword,
  InputsDiv,
  MyProfileHead,
  ProfileOption,
} from "./MyProfileElements";
import { useState } from "react";
import { useUserDetails } from "../../hook/useUserDetails";
import styled from "styled-components";

const Inputs = styled.input`
  width: 300px;
  height: 45px;
  padding-left: 8px;
  margin-right: 32px;
  border: 1px solid #9d9999;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 32px;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }
`;

const ChangePasswordSection = () => {
  // STATE HOOKS
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // IMPORTED HOOKS FUNCTIONS
  const { changePassword, passLoadingBtn } = useUserDetails();

  // FUNCTION TO HANDLE UPDATE PASSWORD
  const handleChangePassword = () => {
    const userId = localStorage.getItem("userId");
    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    const data = {
      user_id: userId,
      current_password: oldPassword,
      password: newPassword,
    };

    changePassword(data);

    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrorMessage("");
  };
  return (
    <>
      <MyProfileHead>Change password</MyProfileHead>
      <ChangePassword>
        <label htmlFor="">Old Password</label>
        <br />
        <ChangePassInput
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type="text"
          placeholder="Old Password"
        />{" "}
        <InputsDiv>
          <div style={{ display: "grid" }}>
            <label htmlFor="">New Password</label>
            <ChangePassInput
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="text"
              placeholder="New Password"
            />
          </div>
          <div style={{ display: "grid" }}>
            <label htmlFor="">New Password Again</label>
            <ChangePassInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="text"
              placeholder="New Password Again"
            />
          </div>
        </InputsDiv>
        <p style={{ fontSize: "10px", color: "red" }}>{errorMessage}</p>
        <br />
        {passLoadingBtn ? (
          <ChangePassButton>
            <img className="button-loading" src={Spinner} alt="" />
          </ChangePassButton>
        ) : (
          <ChangePassButton onClick={handleChangePassword}>
            Change Password
          </ChangePassButton>
        )}
      </ChangePassword>
    </>
  );
};

export default ChangePasswordSection;

{
  /* <ChangePassword>
  <ChangePassInput
    value={oldPassword}
    onChange={(e) => setOldPassword(e.target.value)}
    type="text"
    placeholder="Old Password"
  />{" "}
  <br />
<ChangePassInput
  value={newPassword}
  onChange={(e) => setNewPassword(e.target.value)}
  type="text"
  placeholder="New Password"
/>
<ChangePassInput
  value={confirmPassword}
  onChange={(e) => setConfirmPassword(e.target.value)}
  type="text"
  placeholder="New Password Again"
/>
  <p style={{ fontSize: "10px", color: "red" }}>{errorMessage}</p>
  <br />
  {passLoadingBtn ? (
    <ChangePassButton>
      <img className="button-loading" src={Spinner} alt="" />
    </ChangePassButton>
  ) : (
    <ChangePassButton onClick={handleChangePassword}>
      Change Password
    </ChangePassButton>
  )}
</ChangePassword>; */
}
