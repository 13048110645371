import React from "react";
import {
  BlogBtn,
  BlogBtnDiv,
  BlogCard,
  BlogCards,
  BlogContent,
  BlogDate,
  BlogDescription,
  BlogImg,
  BlogImgDiv,
  BlogTitle,
  BlogTypeTitle,
  BlogsList,
  BlogsSection,
  LatestBlog,
  LatestBlogCard,
  LatestBlogContent,
  LatestBlogDescription,
  LatestBlogImg,
  LatestBlogImgDiv,
  LatestBlogTitle,
} from "./BlogsElements";
import { useState } from "react";
import ReactPaginate from "react-paginate";

import { getText } from "../../apiUrls";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../Home/Home";
import { useBlogQuery } from "../../queries/api";

const Blogs = () => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [currentPage, setCurrentPage] = useState(0);

  // REDIRECT FEATURES
  const handleRedirect = (page) => {
    scrollToTop();
    navigate(page);
  };

  // FETCHING API DATA
  const { data: blogs, isLoading: blogsLoading } = useBlogQuery();

  // DISPLAY ONE BLOG FROM BLOGS
  const latestBlogData = !blogsLoading && blogs[blogs.length - 1];

  // PAGINATION
  const itemsPerPage = 8;
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogsLoading
    ? []
    : blogs?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    window.scroll(0, 700);
  };

  return (
    <BlogsSection>
      {blogsLoading ? (
        <BlogsList>
          <BlogTypeTitle>Latest Blogs</BlogTypeTitle>

          <BlogCards>
            {Array.from({ length: 4 }).map((item) => {
              return (
                <BlogCard>
                  <BlogImgDiv>
                    <Skeleton />
                  </BlogImgDiv>
                  <BlogContent>
                    <BlogTitle>
                      <Skeleton />
                    </BlogTitle>
                    <BlogDescription>
                      <Skeleton />
                    </BlogDescription>
                  </BlogContent>
                  <BlogBtnDiv>
                    <BlogDate>
                      <Skeleton />
                    </BlogDate>
                    <BlogBtn>
                      <i class="fa-solid fa-angle-right"></i>
                    </BlogBtn>
                  </BlogBtnDiv>
                </BlogCard>
              );
            })}
          </BlogCards>
        </BlogsList>
      ) : (
        <BlogsList>
          <BlogTypeTitle>Latest Blogs</BlogTypeTitle>

          <BlogCards>
            {blogs.slice(0, 4).map((item) => {
              const desc = getText(item?.content?.rendered);
              const shortDesc = desc.split(" ").splice(0, 3).join(" ");
              return (
                <BlogCard>
                  <BlogImgDiv>
                    <BlogImg src={item?.additional_data_values?.image_url} />
                  </BlogImgDiv>
                  <BlogContent>
                    <BlogTitle>{item?.title?.rendered}</BlogTitle>
                    <BlogDescription>{shortDesc}</BlogDescription>
                  </BlogContent>
                  <BlogBtnDiv>
                    <BlogDate>01/01/1111</BlogDate>
                    <BlogBtn onClick={() => handleRedirect(`/blog/${item.id}`)}>
                      <i class="fa-solid fa-angle-right"></i>
                    </BlogBtn>
                  </BlogBtnDiv>
                </BlogCard>
              );
            })}
          </BlogCards>
        </BlogsList>
      )}
      {blogsLoading ? (
        <BlogsList>
          <BlogTypeTitle>Blogs</BlogTypeTitle>

          <BlogCards>
            {Array.from({ length: 8 }).map((item) => {
              return (
                <BlogCard>
                  <BlogImgDiv>
                    <Skeleton />
                  </BlogImgDiv>
                  <BlogContent>
                    <BlogTitle>
                      <Skeleton />
                    </BlogTitle>
                    <BlogDescription>
                      <Skeleton />
                    </BlogDescription>
                  </BlogContent>
                  <BlogBtnDiv>
                    <BlogDate>
                      <Skeleton />
                    </BlogDate>
                    <BlogBtn>
                      <i class="fa-solid fa-angle-right"></i>
                    </BlogBtn>
                  </BlogBtnDiv>
                </BlogCard>
              );
            })}
          </BlogCards>
        </BlogsList>
      ) : (
        <BlogsList>
          <BlogTypeTitle>Blogs</BlogTypeTitle>

          <BlogCards>
            {currentItems.map((item) => {
              const desc = getText(item?.content?.rendered);
              const shortDesc = desc.split(" ").splice(0, 3).join(" ");
              return (
                <BlogCard>
                  <BlogImgDiv>
                    <BlogImg src={item?.additional_data_values?.image_url} />
                  </BlogImgDiv>
                  <BlogContent>
                    <BlogTitle>{item?.title?.rendered}</BlogTitle>
                    <BlogDescription>{shortDesc}</BlogDescription>
                  </BlogContent>
                  <BlogBtnDiv>
                    <BlogDate>01/01/1111</BlogDate>
                    <BlogBtn onClick={() => handleRedirect(`/blog/${item.id}`)}>
                      <i class="fa-solid fa-angle-right"></i>
                    </BlogBtn>
                  </BlogBtnDiv>
                </BlogCard>
              );
            })}
          </BlogCards>
          {blogs.length > 8 && (
            <ReactPaginate
              previousLabel={<i class="fa-solid fa-angle-left"></i>}
              nextLabel={<i class="fa-solid fa-angle-right"></i>}
              breakLabel={"..."}
              pageCount={Math.ceil(blogs.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"custom-pagination"}
              pageClassName={"custom-pagination-item"}
              previousClassName={"custom-pagination-item"}
              nextClassName={"custom-pagination-item"}
              activeClassName={"custom-pagination-active"}
            />
          )}
        </BlogsList>
      )}
    </BlogsSection>
  );
};

export default Blogs;

//////////////////
// {/* <LatestBlog>
//   <BlogTypeTitle>Latest Blog</BlogTypeTitle>
//   <LatestBlogCard>
//     <LatestBlogImgDiv>
//       {blogsLoading ? (
//         <Skeleton />
//       ) : (
//         <LatestBlogImg
//           src={latestBlogData?.additional_data_values?.image_url}
//         />
//       )}
//     </LatestBlogImgDiv>
//     <LatestBlogContent>
//       <div>
//         <LatestBlogTitle>
//           {blogsLoading ? <Skeleton /> : latestBlogData?.title?.rendered}
//         </LatestBlogTitle>
//         <LatestBlogDescription>
//           {blogsLoading ? (
//             <Skeleton />
//           ) : (
//             getText(latestBlogData?.content?.rendered)
//           )}
//         </LatestBlogDescription>
//       </div>
//       <BlogBtnDiv>
//         <BlogDate>01/01/1111</BlogDate>
//         <BlogBtn onClick={() => handleRedirect(`/blog/${latestBlogData.id}`)}>
//           <i class="fa-solid fa-angle-right"></i>
//         </BlogBtn>
//       </BlogBtnDiv>
//     </LatestBlogContent>
//   </LatestBlogCard>
// </LatestBlog>; */}
//////////////////
