import React from "react";
import CategoryButton from "./CategoryButton";
import {
  CategoriesDiv,
  CategoryButtonDiv,
  SubCategoriesDiv,
} from "./CategoryElement";
import { useCategoriesQuery } from "../../queries/api";
import CategoriesCard from "./CategoriesCard";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Category = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState();

  const { data: categories, isLoading: categoriesLdg } = useCategoriesQuery();

  useEffect(() => {
    const findCategory = categories?.find(
      (category) => category.parent === 0 && category.name !== "Uncategorized"
    );
    setSelectedCategory(findCategory?.id);
  }, [categories]);

  return (
    <CategoriesDiv>
      <CategoryButtonDiv>
        {!categoriesLdg &&
          categories
            ?.filter(
              (category) =>
                category.parent === 0 && category.name !== "Uncategorized"
            )
            .map((category) => (
              <CategoryButton
                key={category.id}
                category={category}
                onClick={() => setSelectedCategory(category.id)}
                isSelected={category.id === selectedCategory}
              />
            ))}
      </CategoryButtonDiv>
      <SubCategoriesDiv>
        {!categoriesLdg &&
          categories
            .filter((category) => category.parent === selectedCategory)
            .map((category) => {
              const subCateName = category?.name?.toLowerCase();
              return (
                <CategoriesCard
                  onClick={() => {
                    navigate(`/${subCateName}/${category?.id}/products`);
                  }}
                  key={category._id}
                  category={category}
                />
              );
            })}
      </SubCategoriesDiv>
    </CategoriesDiv>
  );
};

export default Category;
