import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { InnerBanner } from "../components/Home/Home";
import Blogs from "../components/Blogs/Blogs";
import Footer from "../components/Footer/Footer";

const BlogsPage = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner />
      <Blogs />
      <Footer />
    </div>
  );
};

export default BlogsPage;
