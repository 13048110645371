import React from "react";
import {
  ContactBox,
  ContactBoxIcon,
  ContactBoxText,
  ContactBoxTitle,
  ContactDiv,
  ContactImgDiv,
  ContactInput,
  ContactInputMessage,
  // ContactSubmit, button
  ContactTitle,
  ContactTitleDiv,
  ContactUs,
  ContactUsForm,
  FormDiv,
} from "./ContactElements";
import Skeleton from "react-loading-skeleton";
import { useSettingsQuery } from "../../queries/api";
import Button from "../Button";

const Contact = () => {
  // FETCHING API DATA
  const { data: settings, isLoading: settingsLoading } = useSettingsQuery();

  return (
    <ContactUs>
      <ContactTitleDiv>
        <ContactTitle>Contact Us</ContactTitle>
      </ContactTitleDiv>
      {settingsLoading ? (
        <ContactDiv>
          {Array.from({ length: 3 }).map((_, i) => {
            return (
              <ContactBox>
                <ContactBoxIcon>
                  <Skeleton />
                </ContactBoxIcon>
                <ContactBoxTitle>
                  <Skeleton />
                </ContactBoxTitle>
                <ContactBoxText>
                  <Skeleton />
                </ContactBoxText>
              </ContactBox>
            );
          })}
        </ContactDiv>
      ) : (
        <ContactDiv>
          <ContactBox>
            <ContactBoxIcon>
              <i class="fa-solid fa-address-book"></i>
            </ContactBoxIcon>
            <ContactBoxTitle>Address</ContactBoxTitle>
            <ContactBoxText>
              <strong></strong> {settings["contact-info"].address}
            </ContactBoxText>
          </ContactBox>
          <ContactBox>
            <ContactBoxIcon>
              <i className="fas fa-regular fa-user"></i>
            </ContactBoxIcon>
            <ContactBoxTitle>Phone</ContactBoxTitle>
            <ContactBoxText>
              <strong></strong> {settings["contact-info"].phone}
            </ContactBoxText>
          </ContactBox>
          <ContactBox>
            <ContactBoxIcon>
              <i className="fas fa-regular fa-envelope"></i>
            </ContactBoxIcon>
            <ContactBoxTitle>Email</ContactBoxTitle>
            <ContactBoxText>{settings["contact-info"].email}</ContactBoxText>
          </ContactBox>
        </ContactDiv>
      )}
      <ContactTitleDiv>
        <ContactTitle>Send us a Message</ContactTitle>
      </ContactTitleDiv>
      <ContactUsForm>
        <FormDiv>
          <ContactInput placeholder="Your Name *" type="text" />
          <ContactInput placeholder="Email" type="email" />
          <ContactInputMessage placeholder="Your Comment *" type="text" />
          {/* <ContactSubmit type="submit">Submit</ContactSubmit> */}
          <Button
            type={"submit"}
            title={"Submit"}
            style={{
              borderRadius: "0",
              width: "100%",
              height: "2rem",
              fontSize: "11px",
              float: "right",
              marginTop: "8px",
            }}
          />
        </FormDiv>
        <ContactImgDiv>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6735.150731341057!2d75.7827107329392!3d11.257863065482008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65938563d4747%3A0x32150641ca32ecab!2sKozhikode%2C%20Kerala!5e0!3m2!1sen!2sin!4v1699897554169!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </ContactImgDiv>
      </ContactUsForm>
    </ContactUs>
  );
};

export default Contact;
