import React from "react";
import {
  AddAddressDiv,
  AddAddressForm,
  AddressButton,
  AddressCancekBtn,
  AddressDiv,
  AddressHead,
  AddressHeadText,
  AddressInput,
  AddressLineInput,
  AddressNumber,
  AddressSaveBtn,
  AddressText,
  AddressType,
  ButtonDiv,
  ButtonsDiv,
  DefaultButton,
  ProfileOption,
  SelectAddress,
  SelectAddressDiv,
  SelectAddressIcons,
  SelectAddresses,
} from "./MyProfileElements";
import { ErrorMessage } from "../CheckOut/CheckOutElements";
import { useState } from "react";
import { useAddressQuery } from "../../queries/api";
import { useAddAddress } from "../../hook/useAddAddress";

const AddressSection = () => {
  // GET USER ID FROM LOCAL STORAGE
  const uIdString = localStorage.getItem("userId");
  const uId = uIdString === "null" ? null : parseFloat(uIdString);

  // STATE HOOKS
  const [showForm, setShowForm] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(null);
  const [isDeleteAddress, setIsDeleteAddress] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [addressData, setAddressData] = useState({
    user_id: uId,
    address_line_1: "",
    address_line_2: "",
    state: "",
    country: "",
    city: "",
    default_address: 1,
    zipcode: "",
    contact_number: "",
    first_name: "",
    last_name: "",
  });

  // FETCHING API DATA
  const { data: userAddress } = useAddressQuery();

  // IMPORTED HOOKS FUNCTIONS
  const { addAddress, deleteAddress, updateAddress, defaultAddress } =
    useAddAddress();

  // FUNCTION TO HANDLE SET DEFAULT ADDRESS
  const handleSetAsDefault = (value) => {
    const requestBody = {
      user_id: value.user_id,
      address_id: value.ID,
    };

    defaultAddress(requestBody);
  };

  // FUNCTION TO HANDLE DELETE ADDRESS
  const handleDeleteAddress = (addressId) => {
    deleteAddress(addressId);
  };

  // FUNCTION TO HANDLE CANCEL ADDRESS INPUTS
  const handleCancel = () => {
    setAddressData({
      user_id: uId,
      address_line_1: "",
      address_line_2: "",
      state: "",
      country: "",
      city: "",
      default_address: 1,
      zipcode: "",
      contact_number: "",
      first_name: "",
      last_name: "",
    });
    setRequiredFields([]);
  };

  // FUNCTION TO HANDLE EDIT INPUT FIELDS
  const handleEditClick = (item) => {
    setIsEditAddress(item.ID);
    setAddressData({
      user_id: uId,
      address_line_1: item.address_line_1,
      address_line_2: item.address_line_2,
      state: item.state,
      country: item.country,
      city: item.city,
      default_address: 1,
      zipcode: item.zipcode,
      contact_number: item.contact_number,
      first_name: item.first_name,
      last_name: item.last_name,
    });
  };

  // FUNCTION TO HANDLE ONCHAGE ADDRESS
  const handleInputAddress = (event) => {
    const { name, value } = event.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // FUNCTION TO ADD ADDRESS
  const handleAddAddress = () => {
    const updatedAddressData = isEditAddress
      ? { ...addressData, address_id: isEditAddress }
      : addressData;

    const requiredFieldsList = [
      // "user_id",
      "first_name",
      "last_name",
      "zipcode",
      "contact_number",
      "address_line_1",
      "city",
      "state",
    ];

    // const emptyFields = requiredFieldsList.filter(
    //   (field) => addressData[field].trim() === ""
    // );
    const emptyFields = requiredFieldsList.filter((field) => {
      const fieldValue = addressData[field];
      return typeof fieldValue !== "string" || fieldValue.trim() === "";
    });

    setRequiredFields(emptyFields);

    if (emptyFields.length > 0) return;
    if (isEditAddress) {
      updateAddress(updatedAddressData);
    } else {
      addAddress(updatedAddressData);
    }

    setShowForm(false);
    setIsEditAddress(null);
    handleCancel();
    setRequiredFields([]);
  };
  return (
    <AddressDiv>
      <div>
        <AddressHeadText>Manage Address</AddressHeadText>

        {!showForm && !isEditAddress ? (
          userAddress?.status ? (
            <div
              style={{
                display: "grid",
                gap: "25px",
                maxHeight: "220px",
                overflowY: "auto",
                marginBottom: "20px",
              }}
            >
              {userAddress?.result?.map((address, index) => {
                console.log("address", address);
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1rem",
                      border: "1px solid #d0d0d0",
                      boxShadow: "0px 5px 10px -5px #d0d0d0",
                      width: "85%",
                    }}
                  >
                    <div style={{ fontSize: "13px" }}>
                      <p>{`${address.first_name} ${address.last_name}`}</p>
                      <p>Mobile: {address.contact_number}</p>
                      <p>{`${address.address_line_1}, ${address.zipcode}, ${address.city}, ${address.state}`}</p>
                    </div>
                    <div>
                      {isDeleteAddress === address.ID ? (
                        <div style={{ display: "flex", gap: "10px" }}>
                          <i
                            style={{ cursor: "pointer", fontSize: "18px" }}
                            onClick={() => handleDeleteAddress(address.ID)}
                            className="fas fa-check"
                          ></i>
                          <i
                            style={{ cursor: "pointer", fontSize: "18px" }}
                            onClick={() => setIsDeleteAddress(null)}
                            className="fas fa-times"
                          ></i>
                        </div>
                      ) : (
                        <div style={{ display: "flex", gap: "10px" }}>
                          <i
                            style={{ cursor: "pointer", fontSize: "18px" }}
                            onClick={() => handleEditClick(address)}
                            className="fa-regular fa-pen-to-square"
                          ></i>
                          <i
                            style={{ cursor: "pointer", fontSize: "18px" }}
                            onClick={() => setIsDeleteAddress(address.ID)}
                            className="fa-regular fa-trash-can"
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <SelectAddresses
              style={{
                display: "grid",
                placeItems: "center",
                color: "#9d9999",
              }}
            >
              <div>{userAddress.msg}</div>
            </SelectAddresses>
          )
        ) : (
          <>
            {/* // ADDRESS FORM */}
            <AddAddressDiv>
              <AddAddressForm>
                <AddressInput
                  type="text"
                  name="first_name"
                  value={addressData.first_name}
                  placeholder="First Name"
                  onChange={handleInputAddress}
                />
                {requiredFields.includes("first_name") && (
                  <ErrorMessage>Please provide your first name</ErrorMessage>
                )}
                <AddressInput
                  type="text"
                  name="last_name"
                  value={addressData.last_name}
                  placeholder="Last Name"
                  onChange={handleInputAddress}
                />
                <br />
                {requiredFields.includes("last_name") && (
                  <ErrorMessage>Please provide your last name</ErrorMessage>
                )}
                <AddressInput
                  type="number"
                  name="zipcode"
                  value={addressData.zipcode}
                  placeholder="Pincode"
                  onChange={handleInputAddress}
                />
                {requiredFields.includes("zipcode") && (
                  <ErrorMessage>Please provide your pincode</ErrorMessage>
                )}
                <AddressInput
                  type="number"
                  name="contact_number"
                  value={addressData.contact_number}
                  placeholder="Mobile Number"
                  onChange={handleInputAddress}
                />
                <br />
                {requiredFields.includes("contact_number") && (
                  <ErrorMessage>Please provide your mobile number</ErrorMessage>
                )}
                <AddressLineInput
                  type="text"
                  name="address_line_1"
                  value={addressData.address_line_1}
                  placeholder="Address"
                  // placeholder="House Name/No"
                  onChange={handleInputAddress}
                />
                <br />
                {requiredFields.includes("address_line_1") && (
                  <ErrorMessage>Please provide your address</ErrorMessage>
                )}
                <AddressInput
                  type="text"
                  name="city"
                  value={addressData.city}
                  placeholder="City/Destrict/Town"
                  onChange={handleInputAddress}
                />
                {requiredFields.includes("city") && (
                  <ErrorMessage>Please provide your city</ErrorMessage>
                )}
                {/* <AddressNameInput */}
                <AddressInput
                  type="text"
                  name="state"
                  value={addressData.state}
                  placeholder="State"
                  onChange={handleInputAddress}
                />
                {requiredFields.includes("state") && (
                  <ErrorMessage>Please provide your state</ErrorMessage>
                )}
              </AddAddressForm>
              <AddressType>
                <ButtonsDiv>
                  <AddressSaveBtn onClick={handleAddAddress}>
                    Save Changes
                  </AddressSaveBtn>
                  <AddressCancekBtn
                    onClick={() => {
                      setShowForm(false);
                      setIsEditAddress(null);
                      handleCancel();
                    }}
                  >
                    Cancel
                  </AddressCancekBtn>
                </ButtonsDiv>
              </AddressType>
            </AddAddressDiv>
            {/* // ADDRESS FORM */}
          </>
        )}
      </div>
      {!showForm && !isEditAddress ? (
        <AddressButton onClick={() => setShowForm(true)}>
          Add New Address
        </AddressButton>
      ) : null}
    </AddressDiv>
  );
};

export default AddressSection;

// {/* <SelectAddresses>
//   {userAddress?.result?.map((address, index) => (
//     <SelectAddressDiv key={index}>
//       <SelectAddress>
//         <AddressHead>
//           {address.first_name} {address.last_name}
//         </AddressHead>
//         <AddressNumber>{address.contact_number}</AddressNumber>
//         <AddressText>{`${address.address_line_1}, ${address.zipcode}, ${address.city}, ${address.state}`}</AddressText>
//         {address?.default_address === "0" && (
//           <DefaultButton onClick={() => handleSetAsDefault(address)}>
//             Set as default
//           </DefaultButton>
//         )}
//       </SelectAddress>

// {isDeleteAddress === address.ID ? (
//   <SelectAddressIcons>
//     <i
//       style={{ cursor: "pointer" }}
//       onClick={() => handleDeleteAddress(address.ID)}
//       className="fas fa-check"
//     ></i>
//     <i
//       style={{ cursor: "pointer" }}
//       onClick={() => setIsDeleteAddress(null)}
//       className="fas fa-times"
//     ></i>
//   </SelectAddressIcons>
// ) : (
//   <SelectAddressIcons>
//     <i
//       style={{ cursor: "pointer" }}
//       onClick={() => handleEditClick(address)}
//       className="fas fa-edit"
//     ></i>
//     <i
//       style={{ cursor: "pointer" }}
//       onClick={() => setIsDeleteAddress(address.ID)}
//       className="fas fa-trash"
//     ></i>
//   </SelectAddressIcons>
// )}
//     </SelectAddressDiv>
//   ))}
// </SelectAddresses>; */}

// {
//   !showForm && !isEditAddress ? (
// <ButtonDiv>
//   <AddressButton onClick={() => setShowForm(true)}>
//     Add New Address
//   </AddressButton>
// </ButtonDiv>
//   ) : null;
// }
