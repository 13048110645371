import React from "react";
import CheckOut from "../components/CheckOut/CheckOut";
import Footer from "../components/Footer/Footer";
import { InnerBanner } from "../components/Home/Home";
import Navbar from "../components/Navbar/Navbar";

const CheckOutPage = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner />
      <CheckOut />
      <Footer />
    </div>
  );
};

export default CheckOutPage;
