import styled from "styled-components";

// FOOTER

export const FooterSection = styled.div`
  margin: 4rem 2rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 950px) {
    margin: 4rem 0rem;
  }
  @media screen and (max-width: 800px) {
    margin: 4rem 0rem;
    display: grid;
  }
`;

export const FooterDiv1 = styled.div`
  width: 250px;
  margin: 0 2rem;
  @media screen and (max-width: 800px) {
    margin: 0 1rem;
    padding: 2rem 0;
    width: 90%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #9d9999;
    align-items: center;
  }
`;

export const FooterDiv2 = styled.div`
  width: 400px;
  margin: 0 2rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    margin: 0rem 1rem;
    padding: 2rem 0;
    width: 90%;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #9d9999;
  }
`;
export const FooterDiv3 = styled.div`
  width: 250px;
  margin: 0 2rem;

  @media screen and (max-width: 800px) {
    width: 70%;
    margin: 0rem 1rem;
    padding: 3rem 0rem 1rem 7rem;
    display: grid;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterContent = styled.div``;
export const ShopLogoFooter = styled.img`
  width: 150px;
  margin-bottom: 1rem;
  @media screen and (max-width: 800px) {
    margin-bottom: 0;
  }
`;
// export const FooterMainTitle = styled.p`
//   font-size: 30px;
//   font-weight: 700;
//   color: green;
//   margin-bottom: 1rem;

//   @media screen and (max-width: 800px) {
//     font-size: 26px;
//   }
// `;

export const FooterText = styled.p`
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 1rem;
`;
export const SocialIcons = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: 5px;
`;
export const SocialIcon = styled.i`
  cursor: pointer;
  font-size: 15px;
  color: #9d9999;
  border: 1px solid #9d9999;
  padding: 6px;
  border-radius: 5px;
`;
// export const SIcons = styled.img`
//   cursor: pointer;
//   border: 1px solid #e1dddd;
//   margin-right: 3px;
//   width: 30px;
//   padding: 5px;
// `;

export const FooterNav = styled.div``;
export const FooterTitle = styled.p`
  font-size: 15px;
  margin-bottom: 1rem;
`;
export const FooterNavLinks = styled.div`
  line-height: 1.7rem;
`;
export const FtNvLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const FtNvIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 6px;
`;
export const FtNvText = styled.p`
  font-size: 13px;
  font-weight: 300;
`;

export const FooterContacts = styled.div``;
export const ContactInfo = styled.div``;
export const Infos = styled.div`
  margin-bottom: 1rem;
`;
export const EachInfo = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
`;

export const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  cursor: pointer;
  margin-top: 2px;
`;

export const InfoTxt = styled.p`
  font-size: 13px;
  font-weight: 300;
`;

export const Newsletter = styled.div``;
export const FormDiv = styled.div`
  display: flex;
`;
export const FormInput = styled.input`
  width: 200px;
  height: 35px;
  padding: 0.7rem;
`;
export const SendIcon = styled.img`
  cursor: pointer;
`;

// RESPONSIVE

export const ResSocialIconsDiv = styled.div`
  @media screen and (min-width: 800px) {
    display: none;
  }
`;
export const SocialIconsDiv = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const ResContactInfoDiv = styled.div`
  @media screen and (min-width: 800px) {
    display: none;
  }
`;
export const ContactInfoDiv = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

// BOTTOM FOOTER

export const BottomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem 0 4rem;
  background-color: #f0f2f3;

  @media screen and (max-width: 800px) {
    margin: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: grid;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    /* bottom: 3.3rem; */
  }
`;
export const CopyRight = styled.p`
  font-size: 13px;
  font-weight: 300;

  @media screen and (max-width: 800px) {
    font-size: 8px;
  }
`;
export const PaymentIcon = styled.img`
  width: 250px;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const PaymentIconRes = styled.img`
  width: 250px;
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
    width: 130px;
    margin-bottom: 0.5rem;
  }
`;
