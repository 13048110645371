import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { address_api, backendUrl } from "../apiUrls";
import { useState } from "react";

const useAddAddress = () => {
  const [addressLodingBtn, setAddressLodingBtn] = useState(false);
  const queryClient = useQueryClient();

  // ADD
  const addAddress = async (data) => {
    try {
      setAddressLodingBtn(true);
      const response = await axios.put(
        `${backendUrl}${address_api}/add?`,
        data
      );
      console.log(response);
      queryClient.invalidateQueries("address");
      setAddressLodingBtn(false);
    } catch (error) {
      setAddressLodingBtn(false);
    }
  };

  // UPDATE
  const updateAddress = async (data) => {
    try {
      setAddressLodingBtn(true);
      const response = await axios.put(
        `${backendUrl}${address_api}/update?`,
        data
      );
      console.log(response.data);
      queryClient.invalidateQueries("address");
      setAddressLodingBtn(false);
    } catch (error) {
      console.error(error);
      setAddressLodingBtn(false);
    }
  };

  // UPDATE FOR DEFAULT
  const defaultAddress = async (data) => {
    try {
      setAddressLodingBtn(true);
      const response = await axios.put(
        `${backendUrl}${address_api}/default?`,
        data
      );
      console.log(response.data);
      queryClient.invalidateQueries("address");
      setAddressLodingBtn(false);
    } catch (error) {
      console.error(error);
      setAddressLodingBtn(false);
    }
  };

  // DELETE
  const deleteAddress = async (addressId) => {
    try {
      setAddressLodingBtn(true);
      const response = await axios.put(
        `${backendUrl}${address_api}/delete?address_id=${addressId}`
      );
      console.log(response.data);
      queryClient.invalidateQueries("address");
      setAddressLodingBtn(false);
    } catch (error) {
      console.error(error);
      setAddressLodingBtn(false);
    }
  };

  const mutation = useMutation(addAddress);
  const mutationUpdate = useMutation(updateAddress);
  const mutationDefault = useMutation(defaultAddress);

  return {
    addAddress: mutation.mutate,
    deleteAddress,
    updateAddress: mutationUpdate.mutate,
    defaultAddress: mutationDefault.mutate,
    addressLodingBtn,
  };
};

export { useAddAddress };
