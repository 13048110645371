import styled from "styled-components";
import backgroundImage from "../../images/ads.png";
// INNER HOME
// export const InnerHomeSliderDiv = styled.div`
//   height: 150px;
//   display: grid;
//   place-items: center;
//   background-color: lightgray;
//   margin: 0 2rem;
//   @media screen and (max-width: 950px) {
//     margin: 0 0.5rem;
//   }
// `;

export const InnerHomeSliderDiv = styled.div`
  height: 150px;
  display: grid;
  place-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(${backgroundImage});
  /* background-image: url(${backgroundImage}); */
  background-size: cover;
  background-position: center;
  background-color: lightgray;
  margin: 0 2rem;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);  */

  @media screen and (max-width: 950px) {
    margin: 0 0.5rem;
  }
`;
export const InnerHomeSlider = styled.p``;
// INNER HOME

export const BannerSection = styled.div`
  display: flex;
  gap: 15px;

  margin: 0 2rem 0 2rem;

  @media screen and (max-width: 950px) {
    margin: 0 0.5rem 0 0.5rem;
  }
`;

export const MainBanner = styled.div`
  flex: 2;
  width: 200px;

  @media (min-width: 768px) {
    .skeleton-banner {
      height: 400px;
    }
  }
  @media (max-width: 767px) {
    .skeleton-banner {
      height: 200px;
    }
  }
`;
export const Slide = styled.div`
  background-color: #f3f3f5;

  height: 400px;
  padding: 0 45px 0 45px;
  justify-content: space-between;

  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    padding: 1rem 1.2rem 1rem 1.2rem;
    height: 200px;
  }
`;
export const SliderText = styled.div``;

export const SliderPic = styled.div``;

export const SliderImg = styled.img`
  width: 400px;
  /* height: 400px; */

  @media screen and (max-width: 950px) {
    width: 150px;
  }
`;

export const SliderTitle = styled.p`
  color: #82ba32;
  margin-bottom: 1rem;

  @media screen and (max-width: 950px) {
    margin-bottom: 0.5rem;
  }
`;
export const SliderDesc = styled.p`
  color: green;
  font-size: 40px;
  font-weight: 600;
  line-height: 2.7rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 950px) {
    font-size: 25px;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;
export const SliderPrice = styled.p`
  color: black;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;

  @media screen and (max-width: 950px) {
    margin-bottom: 0.5rem;
  }
`;
// export const SliderBtn = styled.button`
//   background-color: #82ba32;
//   color: #fff;
//   border: 1px solid #9d9999;
//   border-radius: 1rem;
//   transition: all 0.3s ease-in;
//   width: 6rem;
//   height: 1.7rem;
//   font-size: 12px;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 950px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

///////// SIDE PICS
export const SideBanner = styled.div`
  flex: 0.8;

  display: grid;
  gap: 20px;
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const SidePic = styled.div`
  display: grid;
  align-items: center;
  background-size: cover;
  width: 420px;
  height: 190px;
`;
export const SideText = styled.div`
  padding-left: 2rem;
  max-width: 140px;
`;
export const SideTitle = styled.p`
  color: #82ba32;
  margin-bottom: 0.4rem;
`;
export const SideDesc = styled.p`
  color: white;
  margin-bottom: 0.4rem;
`;
// export const SideBtn = styled.button`
//   background-color: #82ba32;
//   color: #fff;
//   border: 1px solid #9d9999;
//   border-radius: 1rem;
//   transition: all 0.3s ease-in;
//   width: 5rem;
//   height: 1.7rem;
//   font-size: 11px;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 950px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

export const HomeDiv = styled.div`
  margin: 0 2rem 0 2rem;

  @media screen and (max-width: 950px) {
    margin: 0 0.5rem 0 0.5rem;
  }
`;
export const HomeSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2rem 0 2rem;
  @media screen and (max-width: 950px) {
    margin: 0 0.5rem 0 0.5rem;
  }
`;

// TOP CATEGORY

export const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin: 3rem;

  @media screen and (max-width: 950px) {
    display: grid;
    margin: 0;
  }
`;
export const CateContentBox = styled.div`
  width: 240px;
  height: 400px;
  text-align: end;
  display: grid;
  align-content: center;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 300px;
    text-align: center;
  }
`;
export const CateTxtContent = styled.div`
  justify-items: end;
`;
export const CateTitle = styled.p`
  font-size: 32px;
  color: green;
  font-weight: 800;
  line-height: 2.5rem;
  margin-bottom: 1rem;
`;
export const CateDesc = styled.p`
  font-size: 13px;
  color: #9d9999;
  margin-bottom: 2rem;
`;
// export const CateBtn = styled.button`
//   background-color: #82ba32;
//   color: #fff;
//   border: 1px solid #9d9999;
//   border-radius: 1rem;
//   transition: all 0.3s ease-in;
//   width: 6rem;
//   height: 1.7rem;
//   font-size: 12px;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 950px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

export const CategoryDiv = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    justify-content: space-between;
  }
`;
export const CateContent = styled.div`
  width: 240px;
  height: 400px;
  margin: 1rem;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin: 0.1rem;
  }
`;
export const CateImg = styled.div`
  background-size: cover;
  background-position: center;
  height: 400px;
  display: grid;
  align-items: end;
  padding: 0 0 1.5rem 1rem;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    height: 300px;
    margin: 0.1rem;
  }
`;
export const CateImgTitle = styled.p`
  @media screen and (max-width: 950px) {
    /* display: none; */
    font-size: 12px;
  }
`;

// PRODUCTS

export const Products = styled.div`
  margin-top: 0rem;

  @media screen and (max-width: 950px) {
    margin-top: 0rem;
  }
`;
export const ProductBox = styled.div`
  text-align: center;
  margin-top: 3rem;
`;
export const OurProducts = styled.p`
  font-weight: 700;
  font-size: 30px;
  color: green;
`;
export const FilterTitles = styled.div`
  display: flex;
  justify-content: center;
  height: 55px;
`;
export const FilterTitle = styled.button`
  border: none;
  background-color: transparent;

  margin: 1rem;
  transition: all 0.1s ease-in;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    font-size: 13px;
    &:hover {
      color: black;
    }
  }
`;

export const ResProdShow = styled.div`
  @media screen and (min-width: 950px) {
    display: none;
  }
`;
export const ProductList = styled.div`
  justify-content: start;
  text-align: start;
  margin: 0 2rem 0 2rem;

  @media screen and (max-width: 950px) {
    /* margin: 2rem 0.5rem 0 0.5rem; */
    margin: 2rem 0 0 0;
  }
`;

// new product card

export const ProductCardWrapper = styled.div`
  position: relative;
  /* width: 250px; */
  margin: 2rem;
  border: 1px solid #9d9999;
  padding: 1rem;

  @media (max-width: 950px) {
    /* width: 165px; */
    width: 170px;
    /* margin: 2rem 0.5rem 0 0.5rem; */
    margin: 0.5rem 0rem 0 0rem;
    padding: 2px;

    .skeleton-product {
      width: 165px;
      height: 200px;
    }
  }
  @media (min-width: 950px) {
    .skeleton-product {
      width: 250px;
      height: 330px;
    }
  }
`;

export const ProductImg = styled.div`
  position: relative;
  height: 300px;
  width: 250px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    .product-buttons {
      opacity: 1;
    }
  }

  @media (max-width: 950px) {
    width: 165px;
    height: 200px;

    .product-buttons {
      opacity: 1;
    }
  }
`;
export const FavDiv = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 22px;

  .heart {
    cursor: pointer;
  }
`;
export const ProductButtons = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 33px;
  opacity: 0;

  button:nth-child(1) {
    /* background-color: #82ba32; */
    background-color: #2faa04;
    color: white;
    width: 80%;
    border-top: none;
    border-bottom: none;
    border-right: 0.1px solid #fff;
    border-left: none;
    font-size: 13px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:nth-child(2) {
    /* background-color: #82ba32; */
    background-color: #2faa04;
    color: white;
    width: 20%;
    border-top: none;
    border-bottom: none;
    border-left: 0.1px solid #fff;
    border-right: none;
    font-size: 13px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProductDetails = styled.div``;
export const PriceReview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ProductCategory = styled.p`
  font-size: 12px;
  font-weight: 300;
`;

export const ProductName = styled.p`
  font-size: 15px;
  font-weight: 400;

  @media (max-width: 950px) {
    font-size: 13px;
  }
`;

export const ProductPrice = styled.p`
  font-size: 15px;

  @media (max-width: 950px) {
    font-size: 13px;
  }
`;

// new product card

// export const ProductCard = styled.div`
//   position: relative;
//   width: 250px;

//   @media (max-width: 950px) {
//     width: 165px;
//   }
//   /* width: 250px;
//   margin: 3rem;
//   justify-content: center; */

//   @media screen and (min-width: 950px) {
//     .skeleton-product {
//       width: 250px;
//       height: 330px;
//     }
//   }

//   @media screen and (max-width: 950px) {
//     margin: 2rem 0.5rem 1rem 0;
//     min-width: 165px;

//     .skeleton-product {
//       width: 160px;
//       height: 200px;
//     }
//   }
// `;

// export const ProductImg = styled.div`
//   width: 250px;
//   height: 300px;
//   background-position: center;
//   background-size: cover;

//   @media screen and (max-width: 950px) {
//     width: 165px;
//     height: 200px;
//   }
// `; edited
// export const ProductCateNam = styled.div`
//   font-size: 12px;
//   font-weight: 300;

//   @media screen and (max-width: 950px) {
//     font-size: 11px;
//   }
// `; edited
// export const ProductTitle = styled.p`
//   font-size: 15px;
//   font-weight: 400;

//   @media screen and (max-width: 950px) {
//     font-size: 12px;
//   }
// `; edited
// export const PriceAndRate = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 250px;

//   @media screen and (max-width: 950px) {
//     width: 165px;
//   }
// `; edited
// export const ProductPrice = styled.p`
//   font-size: 15px;

//   @media screen and (max-width: 950px) {
//     font-size: 13px;
//   }
// `; edited
export const ActualPrice = styled.span`
  font-size: 13px;
  font-weight: 300;
  margin-left: 5px;
  text-decoration: line-through;

  @media screen and (max-width: 950px) {
    font-size: 11px;
  }
`;
export const ProductRate = styled.div``;
export const StarIcon = styled.img`
  cursor: pointer;
  width: 15px;
  height: 17px;

  @media screen and (max-width: 950px) {
    width: 12px;
    height: 14px;
  }
`;

// export const HoverList = styled.div`
//   position: relative;
//   top: 265px;
//   width: 250px;
//   height: 35px;
//   display: flex;
//   background-color: #82ba32;
//   align-items: center;
//   justify-content: space-evenly;

//   @media screen and (max-width: 950px) {
//     position: relative;
//     top: 165px;
//     width: 165px;
//   }
// `; edited
// export const HoverIcon = styled.button`
//   display: flex;
//   color: #fff;
//   cursor: pointer;
//   align-items: center;
//   background-color: transparent;
//   border: none;
//   @media screen and (max-width: 950px) {
//     font-size: 11px;
//   }

//   &:hover {
//     font-weight: 300;
//   }
// `; edited
// export const HoverCenterLine = styled.div`
//   color: #fff;
// `; edited
// export const HoverTxt = styled.p`
//   font-size: 12px;
//   margin-left: 5px;

//   @media screen and (max-width: 950px) {
//     font-size: 10px;
//     margin-left: 5px;
//   }
// `; edited

// ADVERTISE BANNER

export const AdBanner = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 0 3rem 0;
  align-items: center;
`;
export const BannerImgLeft = styled.img`
  width: 600px;
  margin-left: -0.02px;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const BannerImgRight = styled.img`
  width: 600px;
  margin-left: -0.02px;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
export const AdvBannerCard = styled.div`
  position: absolute;
  display: grid;
  text-align: center;
  color: #fff;
`;
export const AdvBannerTxt = styled.div``;
export const OfferBanner = styled.p`
  background-color: #82ba32;
  margin: 0 10rem 0 10rem;
  padding: 0.4rem;
  margin-bottom: 0.6rem;

  @media screen and (max-width: 950px) {
    margin: 0 7.5rem 0 7.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;
export const AdvTitle = styled.p`
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 2rem;

  @media screen and (max-width: 950px) {
    font-size: 30px;
    margin-bottom: 0rem;
  }
`;
export const TotalTime = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;
export const Times = styled.div`
  margin: 0.3rem;
`;
export const Time = styled.p`
  font-size: 23px;
  font-weight: 500;

  @media screen and (max-width: 950px) {
    font-size: 22px;
  }
`;
export const TimeTxt = styled.p`
  background-color: #82ba32;
  width: 55px;
  height: 20px;
  font-size: 13px;
  margin-bottom: 1rem;

  @media screen and (max-width: 950px) {
    margin-bottom: 0rem;
  }
`;
export const AdvButton = styled.button`
  width: 110px;
  height: 45px;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;
`;

// LATEST NEWS

export const LatestNews = styled.div`
  text-align: center;
  margin-top: 3rem;

  @media screen and (max-width: 950px) {
    margin-top: 4rem;
  }
`;
export const NewsHead = styled.p`
  font-weight: 700;
  font-size: 30px;
  color: green;
`;
export const News = styled.div`
  text-align: start;
  display: flex;
  margin: 4rem;

  @media screen and (max-width: 950px) {
    margin: 4rem 0.5rem;
    display: grid;
  }

  @media (max-width: 767px) {
    .skeleton-image {
      height: 150px;
      width: 150px;
    }

    .skeleton-title {
      width: 100px;
    }

    .skeleton-text {
      width: 200px;
    }

    .skeleton-button {
      width: 80px;
    }
  }

  @media (min-width: 768px) {
    .skeleton-image {
      height: 200px;
      width: 200px;
    }

    .skeleton-title {
      width: 150px;
    }

    .skeleton-text {
      width: 350px;
    }

    .skeleton-button {
      width: 100px;
    }
  }
`;
export const NewsDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 950px) {
    width: 100%;
    display: grid;
  }
`;
export const NewsImg = styled.img`
  width: 200px;
  height: 200px;

  @media screen and (max-width: 950px) {
    /* width: 150px; */
    /* height: 100%; */
    width: 100%;
    height: 100%;
  }
`;
export const NewsContent = styled.div`
  padding: 1rem;
  display: grid;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 950px) {
    padding: 0.5rem 0.2rem 0.2rem 0.2rem;
  }
`;
export const NewsTitle = styled.p`
  color: green;
  font-size: 18px;
  font-weight: 500;
`;
export const NewsText = styled.p`
  font-size: 14px;
  font-weight: 300;
`;
// export const NewsBtn = styled.button`
//   width: 100px;
//   height: 32px;
//   border-radius: 10px;
//   background-color: #82ba32;
//   border: none;
//   color: #fff;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 950px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

// ABOUT
export const About = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 6rem 2rem 6rem;
  background-color: #f0f2f3;
  margin: 0 -2rem 0 -2rem;

  @media screen and (max-width: 950px) {
    padding: 3rem 0.5rem;
    margin: 3rem -0.5rem 0 -0.5rem;
    display: block;
  }
`;
export const AboutContentDiv = styled.div`
  display: grid;
  align-items: center;

  @media screen and (max-width: 950px) {
    text-align: center;
    gap: 20px;
  }
`;
export const AboutContent = styled.div``;
export const AboutTitle = styled.p`
  color: green;
  font-size: 26px;
  font-weight: 700;

  @media screen and (max-width: 950px) {
    margin-bottom: 1rem;
  }
`;
export const AboutText = styled.p`
  font-size: 14px;
  font-weight: 300;
  /* margin-top: -10rem; */
  margin-bottom: 1rem;
  width: 450px;

  @media screen and (max-width: 950px) {
    text-align: center;
    width: 100%;
    margin: 0;
  }
`;
export const AboutImg = styled.img`
  width: 50%;
  object-fit: contain;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;

//export const ProductCard = styled.div`
//   width: 200px;
//   margin: 3rem;
//   justify-content: center;

//   @media screen and (min-width: 950px) {
//     #hoverlist {
//       opacity: 0;
//     }

//     .skeleton-product {
//       width: 250px;
//       height: 330px;
//     }
//   }
//   &:hover {
//     #hoverlist {
//       transition: all 0.5s ease-in-out;
//       opacity: 1;
//     }
//   }

//   @media screen and (max-width: 950px) {
//     margin: 2rem 0.5rem 1rem 0;
//     min-width: 165px;

//     .skeleton-product {
//       width: 160px;
//       height: 200px;
//     }
//   }
// `;
