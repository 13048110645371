import React from "react";
import userPic from "../../assets/images/yx0bl_512.webp";
import {
  OptionsBtnSection,
  OptionsButton,
  ProfileOptions,
  ProfilePic,
  ProfileSection,
} from "./MyProfileElements";
import { useState } from "react";

const MyProfileOptions = ({ onStateChange }) => {
  const [isOption, setIsOption] = useState("myprofile");

  const handleIsOption = (value) => {
    setIsOption(value);
    onStateChange(value);
  };

  const getOptionLabel = (option) => {
    switch (option) {
      case "myprofile":
        return "my profile";
      case "myorders":
        return "my orders";
      case "address":
        return "manage address";
      case "changepass":
        return "change password";
      default:
        return "myprofile";
    }
  };

  return (
    <>
      <ProfileSection>
        <ProfilePic src={userPic} alt="" />
        <p>User name</p>
      </ProfileSection>
      <OptionsBtnSection>
        {["myprofile", "myorders", "address", "changepass"].map((option) => (
          <OptionsButton
            key={option}
            style={{ color: isOption === option && "green" }}
            onClick={() => handleIsOption(option)}
          >
            {getOptionLabel(option)}
          </OptionsButton>
        ))}
      </OptionsBtnSection>
    </>
  );
};

export default MyProfileOptions;
