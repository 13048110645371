import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";

const useAddToCart = () => {
  const queryClient = useQueryClient();
  const [cartLoadingBtn, setCartLoadingBtn] = useState(false);

  const addToCart = async (data) => {
    try {
      setCartLoadingBtn(true);
      const response = await axios.post(
        "https://admin.wiscart.in/wp-json/wc/cart/add/products",
        data
      );
      localStorage.setItem("cartId", response.data.cart_data[0].ID);
      if (!data.user_id || data.user_id === "null") {
        localStorage.setItem("userId", response.data.cart_data[0].user_id);
      }
      console.log("isCart response", response);
      queryClient.invalidateQueries("cart");
      setCartLoadingBtn(false);
    } catch (error) {
      console.error(error);
      setCartLoadingBtn(false);
    }
  };

  const updateCart = async (data) => {
    try {
      setCartLoadingBtn(true);
      const response = await axios.post(
        "https://admin.wiscart.in//wp-json/wc/cart/update/product",
        data
      );
      console.log("cart update response", data);
      console.log("cart update response", response);
      queryClient.invalidateQueries("cart");
      setCartLoadingBtn(false);
    } catch (error) {
      console.error(error);
      setCartLoadingBtn(false);
    }
  };

  const deleteCart = async (data) => {
    try {
      setCartLoadingBtn(true);
      const response = await axios.post(
        "https://admin.wiscart.in/wp-json/wc/cart/delete/product/product_key",
        data
      );
      console.log("delete response", response);
      queryClient.invalidateQueries("cart");
      setCartLoadingBtn(false);
    } catch (error) {
      console.error(error);
      setCartLoadingBtn(false);
    }
  };

  const mutation = useMutation(addToCart);
  const updateMutation = useMutation(updateCart);
  const deleteMutation = useMutation(deleteCart);

  return {
    addToCart: mutation.mutate,
    updateCart: updateMutation.mutate,
    deleteCart: deleteMutation.mutate,
    cartLoadingBtn: mutation.isLoading || cartLoadingBtn,
  };
};

export { useAddToCart };
