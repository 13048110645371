import React from "react";

import InfoIcon1 from "../../images/address-1.png";
import InfoIcon2 from "../../images/address-2.png";
import InfoIcon3 from "../../images/address-3.png";

import SendIcon1 from "../../images/send.png";

import PaymentImg from "../../images/payment-options.png";

import ArrowRight from "../../images/toRight.png";

import {
  FooterTitle,
  FooterText,
  SocialIcons,
  FooterContent,
  FooterNav,
  FooterNavLinks,
  FtNvLink,
  FtNvIcon,
  FtNvText,
  FooterContacts,
  ContactInfo,
  EachInfo,
  Infos,
  InfoIcon,
  InfoTxt,
  Newsletter,
  FormDiv,
  FormInput,
  SendIcon,
  // SIcons,
  BottomFooter,
  CopyRight,
  PaymentIcon,
  PaymentIconRes,
  FooterSection,
  FooterDiv1,
  FooterDiv2,
  FooterDiv3,
  ResContactInfoDiv,
  ResSocialIconsDiv,
  ContactInfoDiv,
  SocialIconsDiv,
  ShopLogoFooter,
  SocialIcon,
} from "./FooterElement";
import shopLogo from "../../images//logos/logo512footer.png";
// import { ShopLogoWeb } from "../Navbar/NavbarElements";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../Home/Home";
import Skeleton from "react-loading-skeleton";
import { useCategoriesQuery, useSettingsQuery } from "../../queries/api";

const Footer = () => {
  const navigate = useNavigate();

  // REDIRECT FEATURES
  const handleRedirect = (page) => {
    scrollToTop();
    navigate(page);
  };

  // FETCHING API DATA
  const { data: settings, isLoading: settingsLoading } = useSettingsQuery();
  const { data: categories, isLoading: categoriesLdg } = useCategoriesQuery();

  const ResContactInfo = () => {
    // FETCHING API DATA
    const { data: settings, isLoading: settingsLoading } = useSettingsQuery();
    return (
      <>
        {settingsLoading ? (
          <Skeleton count={4} />
        ) : (
          <>
            <ContactInfo>
              <FooterTitle>Contact Us</FooterTitle>
              <Infos>
                <EachInfo>
                  <InfoIcon src={InfoIcon1} alt="..." />
                  <InfoTxt>{settings["contact-info"].address}</InfoTxt>
                </EachInfo>
                <EachInfo>
                  <InfoIcon src={InfoIcon2} alt="..." />
                  <InfoTxt>{settings["contact-info"].email}</InfoTxt>
                </EachInfo>
                <EachInfo>
                  <InfoIcon src={InfoIcon3} alt="..." />
                  <InfoTxt>{settings["contact-info"].phone}</InfoTxt>
                </EachInfo>
              </Infos>
            </ContactInfo>
          </>
        )}
      </>
    );
  };

  const ResSocialIcons = () => {
    // FETCHING API DATA
    const { data: settings, isLoading: settingsLoading } = useSettingsQuery();

    if (settingsLoading) {
      return <div>null</div>;
    }

    return (
      <SocialIcons>
        <a
          href={settings["social-medias"]?.fb}
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon className="fa-brands fa-facebook"></SocialIcon>
        </a>
        <a
          href={settings["social-medias"]?.twitter}
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon className="fa-brands fa-twitter"></SocialIcon>
        </a>
        <a
          href={settings["social-medias"]?.instagram}
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon className="fa-brands fa-instagram"></SocialIcon>
        </a>
        <a
          href={settings["social-medias"]?.linkedin}
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon className="fa-brands fa-linkedin"></SocialIcon>
        </a>
        <a
          href={settings["social-medias"]?.youtube}
          target="_blank"
          rel="noreferrer"
        >
          <SocialIcon className="fa-brands fa-youtube"></SocialIcon>
        </a>
      </SocialIcons>
    );
  };

  return (
    <>
      <FooterSection>
        <FooterDiv1>
          <FooterContent>
            <ShopLogoFooter src={shopLogo} alt="shopLogo" />
            <FooterText>
              {settingsLoading ? (
                <Skeleton count={4} />
              ) : (
                settings?.about?.content?.split(" ")?.slice(0, 30)?.join(" ")
              )}
              ...
            </FooterText>
          </FooterContent>

          <SocialIconsDiv>
            <ResSocialIcons settings={settings} />
          </SocialIconsDiv>

          <ResContactInfoDiv>
            <ResContactInfo settings={settings} />
          </ResContactInfoDiv>
        </FooterDiv1>

        <FooterDiv2>
          {categoriesLdg ? (
            <FooterNav>
              <FooterTitle>
                <Skeleton />
              </FooterTitle>
              <FooterNavLinks>
                {Array.from({ length: 4 }).map((item, i) => {
                  return (
                    <FtNvLink key={i}>
                      <Skeleton width={10} />
                      <FtNvText>
                        <Skeleton width={100} />
                      </FtNvText>
                    </FtNvLink>
                  );
                })}
              </FooterNavLinks>
            </FooterNav>
          ) : (
            <FooterNav>
              {/* <FooterTitle>Useful Links</FooterTitle> */}
              <FooterTitle>Shop</FooterTitle>
              <FooterNavLinks>
                {categories.slice(0, 6).map((item, i) => {
                  return (
                    <FtNvLink
                      key={i}
                      onClick={() => {
                        scrollToTop();
                        navigate(`/${item?.name}/${item.id}/products`);
                      }}
                    >
                      <FtNvIcon src={ArrowRight} />
                      <FtNvText>{item.name}</FtNvText>
                    </FtNvLink>
                  );
                })}
              </FooterNavLinks>
            </FooterNav>
          )}

          {/* ------ */}

          <FooterNav>
            <FooterTitle>Quick Links</FooterTitle>
            <FooterNavLinks>
              <FtNvLink onClick={() => handleRedirect("/")}>
                <FtNvIcon src={ArrowRight} alt="..." />
                <FtNvText>Home</FtNvText>
              </FtNvLink>
              <FtNvLink onClick={() => handleRedirect("/")}>
                <FtNvIcon src={ArrowRight} alt="..." />
                <FtNvText>New Arrivals</FtNvText>
              </FtNvLink>
              <FtNvLink onClick={() => handleRedirect("/blog")}>
                <FtNvIcon src={ArrowRight} alt="..." />
                <FtNvText>Blog</FtNvText>
              </FtNvLink>
              <FtNvLink onClick={() => handleRedirect("/contact")}>
                <FtNvIcon src={ArrowRight} alt="..." />
                <FtNvText>Contact Us</FtNvText>
              </FtNvLink>
            </FooterNavLinks>
          </FooterNav>
        </FooterDiv2>

        <FooterDiv3>
          <ContactInfoDiv>
            <ResContactInfo settings={settings} />
          </ContactInfoDiv>

          <ResSocialIconsDiv>
            <ResSocialIcons settings={settings} />
          </ResSocialIconsDiv>

          <FooterContacts>
            <Newsletter>
              <FooterTitle>Newsletter</FooterTitle>
              <FormDiv>
                <FormInput type="email" placeholder="Enter your email" />
                <SendIcon src={SendIcon1} alt="..." />
              </FormDiv>
            </Newsletter>
          </FooterContacts>
        </FooterDiv3>
      </FooterSection>

      <BottomFooter>
        <PaymentIconRes src={PaymentImg} alt="..." />
        <CopyRight>Copyright all reserved for spicekart</CopyRight>
        <PaymentIcon src={PaymentImg} alt="..." />
      </BottomFooter>
    </>
  );
};

export default Footer;
