import React, { useCallback, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  // BrandCheck,
  BrandName,
  ClearListFilteres,
  FilterPrice,
  FilteredList,
  FilteredListContainer,
  ListFilteredWrapper,
  MaxMinInput,
  MoreProdBox1,
  MoreProdBtn,
  MoreProdCount,
  MoreProdTitle,
  MoreProducts,
  PriceTitle,
  ProdBrand,
  ProdBrands,
  ProductFilterDiv,
  RangeCount,
  RangeCountInput,
  RangeCountTitle,
  RangeCounts,
  RangeDevider,
  RangeInput,
  RangeProgress,
  RangeSlider,
  SearchBar,
} from "./ProductsListElements";
import { useDispatch } from "react-redux";
import { getFilterAttribute } from "../../redux/reducers/productsReducer";
import { useNavigate, useParams } from "react-router-dom";
import { consumerKey, consumerSecret } from "../../apiUrls";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  // AttributeArrow,
  MenuAttributes,
  MenuItem,
  MenuItems,
} from "../Navbar/NavbarElements";
import { scrollToTop } from "../Home/Home";
import { useCategoriesQuery } from "../../queries/api";
import { getRender } from "../../redux/reducers/handleRender";

const ProductFilter = ({ propAttributes, isFilter }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();


  // GET CATEGORY DETAILS FROM PARAMS
  const { category } = useParams();

  // FETCHING API DATA
  const { data: categories, isLoading: categoriesLdg } = useCategoriesQuery();

  // STATE HOOKS
  const [activeItem, setActiveItem] = useState(null);
  const [minRangeValue, setMinRangeValue] = useState(0);
  const [maxRangeValue, setMaxRangeValue] = useState(10000);

  const [selectedFilters, setSelectedFilters] = useState([]);
  console.log("selectedFilters", selectedFilters);

  useEffect(() => {
    setSelectedFilters([]);
  }, [category]);

  const handleRangeChange = (newMinValue, newMaxValue) => {
    setMinRangeValue(newMinValue);
    setMaxRangeValue(newMaxValue);
    const updatedFilters = selectedFilters.filter(
      (filter) =>
        !(
          filter["filter[min_price]"] !== undefined ||
          filter["filter[max_price]"] !== undefined
        )
    );

    updatedFilters.push({ "filter[min_price]": newMinValue });
    updatedFilters.push({ "filter[max_price]": newMaxValue });

    setSelectedFilters(updatedFilters);
  };

  // FUNCTION TO CHANGE CHECKBOX
  const handleCheckboxChange = (slug, value) => {
    const filterKey = `filter[${slug}]`;
    const existingFilterIndex = selectedFilters.findIndex(
      (filter) => filter[filterKey] === value.id
    );

    if (existingFilterIndex !== -1) {
      const updatedFilters = selectedFilters.filter(
        (_, index) => index !== existingFilterIndex
      );
      setSelectedFilters(updatedFilters);
    } else {
      setSelectedFilters((prevFilters) => [
        ...prevFilters,
        {
          [filterKey]: value.id,
          name: value.name,
          slug: filterKey.match(/\[([^[\]]+)\]/)[1],
          id: value.id,
        },
      ]);
      // setSelectedFilters((prevFilters) => [
      //   ...prevFilters,
      //   { [filterKey]: value.id },
      // ]);
    }
  };

  const handleClearFilters = () => {
    setMinRangeValue(0);
    setMaxRangeValue(10000);
    setSelectedFilters([]);
  };

  // QUERY FOR PRODUCT LISTING WITH FILTERS AND SORT BY PRICE DESCENDING
  const filterAttributes = async () => {
    const converted = selectedFilters
      .reduce((acc, obj) => {
        const [key] = Object.keys(obj);
        const value = obj[key];
        if (value !== "") {
          const existingItem = acc.find((item) => item.key === key);

          if (existingItem) {
            existingItem.values.push(value);
          } else {
            acc.push({ key, values: [value] });
          }
        }
        return acc;
      }, [])
      .map((item) => {
        const key = encodeURIComponent(item.key);
        const values = item.values.map((val) => encodeURIComponent(val));
        return `${key}=${values.join(",")}`;
      })
      .join("&");
    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const filterUrl = `https://admin.wiscart.in/wp-json/wc/products/filter?category=${category}&${converted}&per_page=10&offset=1&order=DESC&orderby=price`;
    const filterResponse = await fetch(filterUrl, {
      headers: {
        Authorization: authHeader,
      },
    });
    const filterData = await filterResponse.json();
    console.log("test", minRangeValue === 0 && maxRangeValue === 10000 && selectedFilters?.length === 0)
    if (minRangeValue === 0 && maxRangeValue === 10000 && selectedFilters?.length === 0) {
      return []
    } else {
      return filterData;
    }
  };

  // GET FILTER ATTRIBUTE
  const {
    data: filterAttribute,
    isLoading: filterAttributeLoading,
    refetch: reFilterAttribute,
  } = useQuery(["filter-attributes"], filterAttributes);

  console.log("filterAttribute", filterAttribute)
  const renderData = {
    attributeProductsLoading: { loading: filterAttributeLoading },
  };

  // USEEFFECT REFILTERATTRIBUTES
  const [prevSelectedFiltersLength, setPrevSelectedFiltersLength] = useState(
    selectedFilters?.length
  );
  useEffect(() => {
    // DISPATCH TO FILTERED ATTRIBUTES
    dispatch(getFilterAttribute(filterAttribute));
    dispatch(getRender(renderData));

    if (selectedFilters?.length !== prevSelectedFiltersLength) {
      reFilterAttribute();
    }

    // Update prevSelectedFiltersLength for the next comparison
    setPrevSelectedFiltersLength(selectedFilters?.length);
  }, [
    selectedFilters,
    category,
    minRangeValue,
    maxRangeValue,
    reFilterAttribute,
    filterAttribute,
    renderData,
  ]);

  // FUNCTION TO HANDLE ACTIVE ITEM
  const handleItemClick = (item) => {
    if (activeItem === item) {
      setActiveItem(null);
    } else {
      setActiveItem(item);
    }
  };
  return (
    <div>
      {isFilter ? (
        <MenuItems>
          {isFilter?.map((item, index) => (
            <>
              <MenuItem key={index} className="">
                <MenuAttributes onClick={() => handleItemClick(item)}>
                  <p>{item.name}</p>
                  {activeItem?.id === item?.id ? (
                    // <AttributeArrow src={arrowDown} alt="" />
                    <i class="fa-solid fa-angle-up"></i>
                  ) : (
                    // <AttributeArrow src={arrowUp} alt="" />
                    <i class="fa-solid fa-angle-down"></i>
                  )}
                </MenuAttributes>
              </MenuItem>

              {activeItem === item && (
                <ProdBrands
                  style={{ margin: ".5rem 2rem" }}
                  className="dropdown-content"
                >
                  {item?.values?.map((value, i) => (
                    <ProdBrand style={{ gap: "10px" }} key={i}>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleCheckboxChange(item.slug, value)}
                      >
                        {selectedFilters.some(
                          (filter) =>
                            filter[`filter[${item.slug}]`] === value.id
                        ) ? (
                          <i class="fa-regular fa-square-check"></i>
                        ) : (
                          <i class="fa-regular fa-square"></i>
                        )}
                      </div>

                      <BrandName htmlFor={`filter_${item.slug}_${value.id}`}>
                        {value.name}
                      </BrandName>
                    </ProdBrand>
                  ))}
                </ProdBrands>
              )}
            </>
          ))}
          {/* ////////////// */}
          <ListFilteredWrapper style={{ margin: "1rem" }}>
            {selectedFilters
              .filter(
                (item) =>
                  item.slug !== "filter[min_price]" &&
                  item.slug !== "filter[max_price]" &&
                  item.name
              ) // Add the condition for a non-empty 'name'
              .map((item, i) => (
                <FilteredListContainer
                  onClick={() => handleCheckboxChange(item.slug, item)}
                  key={i}
                >
                  <FilteredList>{item.name}</FilteredList>
                </FilteredListContainer>
              ))}
          </ListFilteredWrapper>
          {/* ////////////// */}
        </MenuItems>
      ) : (
        <>
          <ProductFilterDiv>
            {propAttributes?.attributesLoading ? (
              <div style={{ margin: "2rem 0" }}>
                <Skeleton height={20} width={100} count={1} />
                <Skeleton height={20} width={200} count={1} />
                <Skeleton
                  height={20}
                  width={200}
                  count={1}
                  style={{ margin: "1rem 0" }}
                />
                <div style={{ display: "flex", gap: "10px" }}>
                  <Skeleton height={15} width={20} count={13} />
                  <Skeleton height={15} width={200} count={13} />
                </div>
              </div>
            ) : (
              <>
                <FilterPrice>
                  {/* <PriceTitle>
                    Products ({filterAttribute?.length || 0})
                  </PriceTitle> */}
                  {/* ////////////// */}
                  <ListFilteredWrapper>
                    {selectedFilters
                      .filter(
                        (item) =>
                          item.slug !== "filter[min_price]" &&
                          item.slug !== "filter[max_price]" &&
                          item.name
                      ) // Add the condition for a non-empty 'name'
                      .map((item, i) => (
                        <FilteredListContainer
                          onClick={() => handleCheckboxChange(item.slug, item)}
                          key={i}
                        >
                          <FilteredList>{item.name}</FilteredList>
                        </FilteredListContainer>
                      ))}
                  </ListFilteredWrapper>
                  {selectedFilters?.length > 0 && (
                    <ClearListFilteres>
                      <FilteredList onClick={handleClearFilters}>
                        clear all
                      </FilteredList>
                    </ClearListFilteres>
                  )}

                  {/* ////////////// */}

                  <PriceTitle>PRICE</PriceTitle>
                  <RangeSlider>
                    <RangeProgress
                      style={{
                        left: (minRangeValue / 10000) * 250,
                        right: ((10000 - maxRangeValue) / 10000) * 250,
                      }}
                    ></RangeProgress>
                  </RangeSlider>
                  <RangeInput>
                    <MaxMinInput
                      type="range"
                      min="0"
                      max="10000"
                      value={minRangeValue}
                      // onChange={(e) => setMinRangeValue(e.target.value)}
                      onChange={(e) =>
                        handleRangeChange(e.target.value, maxRangeValue)
                      }
                    />
                    <MaxMinInput
                      type="range"
                      min="0"
                      max="10000"
                      value={maxRangeValue}
                      // onChange={(e) => setMaxRangeValue(e.target.value)}
                      onChange={(e) =>
                        handleRangeChange(minRangeValue, e.target.value)
                      }
                    />
                  </RangeInput>

                  <RangeCounts>
                    <RangeCount>
                      <RangeCountTitle>Min</RangeCountTitle>
                      <RangeCountInput
                        type="number"
                        value={minRangeValue}
                        // onChange={(e) => setMinRangeValue(e.target.value)}
                        onChange={(e) =>
                          handleRangeChange(e.target.value, maxRangeValue)
                        }
                      />
                    </RangeCount>
                    <RangeDevider>to</RangeDevider>
                    <RangeCount>
                      <RangeCountTitle>Max</RangeCountTitle>
                      <RangeCountInput
                        type="number"
                        value={maxRangeValue}
                        // onChange={(e) => setMaxRangeValue(e.target.value)}
                        onChange={(e) =>
                          handleRangeChange(minRangeValue, e.target.value)
                        }
                      />
                    </RangeCount>
                  </RangeCounts>
                </FilterPrice>
                <FilterPrice>
                  {propAttributes?.attributes?.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <PriceTitle>{item.name}</PriceTitle>
                        <SearchBar>
                          {/* <SearchIcon src={SearchLogo} />
                    <NavInput type="text" placeholder={`Search ${item.name}`} /> */}
                        </SearchBar>
                        <ProdBrands>
                          {item?.values?.map((value) => (
                            <ProdBrand style={{ gap: "10px" }} key={value.id}>
                              {/* <BrandCheck
                                type="checkbox"
                                id={`filter_${item.slug}_${value.id}`}
                                checked={selectedFilters.some(
                                  (filter) =>
                                    filter[`filter[${item.slug}]`] === value.id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(item.slug, value.id)
                                }
                              /> */}
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleCheckboxChange(item.slug, value)
                                }
                              >
                                {selectedFilters.some(
                                  (filter) =>
                                    filter[`filter[${item.slug}]`] === value.id
                                ) ? (
                                  <i class="fa-regular fa-square-check"></i>
                                ) : (
                                  <i class="fa-regular fa-square"></i>
                                )}
                              </div>

                              <BrandName
                                htmlFor={`filter_${item.slug}_${value.id}`}
                              >
                                {value.name}
                              </BrandName>
                            </ProdBrand>
                          ))}
                        </ProdBrands>
                      </React.Fragment>
                    );
                  })}
                </FilterPrice>
              </>
            )}
            <MoreProducts>
              {categoriesLdg ? (
                <Skeleton
                  height={240}
                  width={240}
                  count={2}
                  style={{ margin: ".5rem 0" }}
                />
              ) : (
                <>
                  {categories?.slice(0, 2).map((item, i) => {
                    const isEvenIndex = i % 2 === 0;
                    const backgroundColor = isEvenIndex ? "#f9c802" : "#415adb";

                    return (
                      <MoreProdBox1 key={i} style={{ backgroundColor }}>
                        <MoreProdTitle>{item.name}</MoreProdTitle>
                        <MoreProdCount>{item.count} Products</MoreProdCount>
                        <MoreProdBtn
                          onClick={() => {
                            scrollToTop();
                            navigate(`/${item?.name}/${item.id}/products`);
                          }}
                        >
                          View more
                        </MoreProdBtn>
                      </MoreProdBox1>
                    );
                  })}
                </>
              )}
            </MoreProducts>
          </ProductFilterDiv>
        </>
      )}
    </div>
  );
};

export default ProductFilter;
