import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  OrderCompleteDiv,
  OrderCompleted,
  OrderCompleteBtn,
  CompleteHead,
  CompleteIcon,
  OrderThankText,
  OrderId,
  OrderText,
  CompleteIconDiv,
} from "./OrderCompleteElements";
import { useUsersQuery } from "../../queries/api";

const OrderComplete = () => {
  const navigate = useNavigate();
  // FETCHING API DATA
  const { data: userProfile } = useUsersQuery();

  // GET USER ID FROM LOCAL STORAGE
  const uIdString = localStorage.getItem("userId");
  const userId = uIdString === "null" ? null : parseFloat(uIdString);

  const handleRedirect = () => {
    // navigate(`/profile/${userProfile.username}/${userId}`);
    navigate("/");
    window.location.reload();
    
  };
  return (
    <OrderCompleteDiv>
      <OrderCompleted>
        <CompleteHead>Your order has been completed</CompleteHead>
        <CompleteIconDiv>
          <CompleteIcon>&#10003;</CompleteIcon>
        </CompleteIconDiv>
        <OrderThankText>Thank you for purcherse</OrderThankText>
        <OrderId>Your order ID: #47847392</OrderId>
        <OrderText>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae.
        </OrderText>

        <OrderCompleteBtn onClick={handleRedirect}>
          Continue Shopping
        </OrderCompleteBtn>
      </OrderCompleted>
    </OrderCompleteDiv>
  );
};

export default OrderComplete;
