import styled from "styled-components";

export const CartSection = styled.div`
  display: flex;
  justify-content: start;
  margin: 2rem 2rem;
  gap: 20px;

  @media screen and (max-width: 800px) {
    display: grid;
    margin: 0rem 0.5rem;
  }
`;

export const CartFilter = styled.div`
  display: grid;
  justify-content: start;
  height: 100%;
  border: 1px solid #9d9999;
  border-bottom: none;
  margin: 0 2rem 0 0;

  display: none;

  @media screen and (max-width: 800px) {
    /* margin: 1rem 2rem 3rem 0;
    width: 350px;
    height: auto; */

    display: none;
  }
`;
export const CartFilterTitle = styled.p`
  font-size: 20px;
  color: #0f7f08;
  border-bottom: 1px solid #9d9999;
  width: 350px;
  padding: 0.5rem 0.7rem;
`;
export const OrderStatusTitle = styled.p`
  border-bottom: 1px solid #9d9999;
  padding: 0.5rem 0.7rem;
`;
export const OrderStatusDiv = styled.div``;
export const OrderStatuses = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9d9999;
  padding: 0.5rem 0.7rem;
  font-size: 15px;
  font-weight: 300;
`;
export const OrderCheck = styled.input``;
export const OrderStatus = styled.p`
  margin-left: 0.4rem;
`;

export const SkeletonLine = styled.div`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const CartProducts = styled.div`
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  height: 15rem;
  color: #9d9999;
`;
export const CartProduct = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 50px;
  align-items: center;
  margin-bottom: 0.5rem;

  background-color: #f4f4f4;
  padding: 0 2rem 2rem 2rem;

  @media screen and (max-width: 600px) {
    justify-content: center;
    padding: 1rem 0 0 0.5rem;
    margin-bottom: 0.2rem;
  }
`;
export const CartResDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: grid;
    margin-left: 0rem;
  }
`;
export const CartProdImg = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    margin-top: -5rem;
  }
`;

export const CartProdResponsive = styled.div`
  /* display: grid;
  align-items: center; */
  cursor: pointer;
`;

export const CartProdTextDiv = styled.div`
  width: 200px;
  @media screen and (max-width: 600px) {
    width: 190px;
  }
`;
export const CartProdTitle = styled.p`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 600px) {
    color: green;
    font-size: 20px;
    font-weight: 400;
  }
`;
export const CartProdPrice = styled.p`
  margin-right: 4rem;
  font-size: 14px;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    /* font-weight: 300; */
    margin-right: 0;
  }
`;

export const CartCountDiv = styled.div`
  display: grid;
  /* padding-top: 0.5rem; */

  @media screen and (max-width: 600px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;
export const CartCountDivNew = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    display: grid;
  }
`;
export const CartStatusText = styled.p`
  font-size: 13px;
  font-weight: 300;
  display: grid;
  justify-content: end;

  position: absolute;
  right: 0;
  margin-right: 2.5rem;

  @media screen and (max-width: 600px) {
    justify-content: flex-start;
    margin-top: 1.5rem;
    display: none;
  }
`;
export const ResCartStatusText = styled.p`
  font-size: 13px;
  font-weight: 300;
  display: none;

  @media screen and (max-width: 600px) {
    display: grid;
    justify-content: flex-start;
    margin-top: 1.5rem;
  }
`;

export const RemoveItemaDiv = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  display: flex;
  justify-content: end;
  padding: 5px;

  @media screen and (max-width: 600px) {
    margin-top: 0.5rem;
  }
`;
export const RemoveItem = styled.div`
  width: 25px;
  height: 25px;
  /* border-radius: 50%; */
  font-weight: 300;
  border: 1px solid #fff;
  cursor: pointer;
  color: #9d9999;
  &:hover {
    font-weight: 500;
    border: 1px solid #9d9999;
    color: black;
  }
  display: grid;
  place-items: center;
`;

export const CartCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 0.5rem;
  margin-right: 1rem;

  @media screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;
export const CartCountText = styled.p`
  font-size: 14px;
  font-weight: 400;
`;
export const CounterDiv = styled.div`
  width: 110px;
  height: 35px;

  display: flex;
  justify-content: space-evenly;
  border: 1px solid #9d9999;
  border-radius: 6px;
  margin-left: 0.5rem;
  font-weight: 300;
`;
export const CounterUpdateDiv = styled.div`
  min-width: 110px;
  height: 35px;

  display: flex;
  justify-content: space-evenly;
  border: 1px solid #9d9999;
  border-radius: 6px;
  margin-left: 1rem;
  font-weight: 300;
`;

export const UpdateBtn = styled.button`
  background-color: #82ba32;
  color: #fff;
  border: 1px solid #9d9999;
  border-radius: 1rem;
  transition: all 0.3s ease-in;
  width: 6rem;
  height: 2rem;
  font-size: 12px;
  float: right;
  margin-top: 0.6rem;
  margin-left: 0.5rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 800px) {
    margin-left: 0rem;
    width: 8rem;
    height: 2.3rem;
    &:hover {
      background-color: #82ba32;
    }
  }
`;

export const SizeDiv = styled.div`
  width: 74px;
  height: 35px;

  display: flex;
  justify-content: space-evenly;
  border: 1px solid #9d9999;
  border-radius: 6px;
  margin-left: 0.5rem;
  font-weight: 300;
`;
export const CounterIcon = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
  color: #9d9999;

  &:hover {
    color: black;
  }
`;
export const CounterIconRemove = styled.div`
  display: grid;
  place-items: center;
  /* cursor: pointer; */
  color: #9d9999;
`;
export const ProductCounter = styled.div`
  display: grid;
  place-items: center;
`;

export const PriceDetailsResponse = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  @media screen and (max-width: 600px) {
    display: grid;
    padding: 0.5rem;
  }
`;

// // ADDRESS

// export const OwnAddressDiv = styled.div`
//   background-color: #f5f5f5;
//   border-bottom: 2px solid #fff;

//   display: none;

//   @media screen and (max-width: 800px) {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     margin-top: 2rem;
//     /* margin: 2rem 0.5rem 0 0.5rem; */
//     padding: 1rem 0.5rem;
//   }
// `;
// export const OwnAddressText = styled.p`
//   font-size: 15px;
//   font-weight: 300;
// `;

// export const ChangeAddBtn = styled.button`
//   background-color: #82ba32;
//   color: #fff;
//   border: 1px solid #9d9999;
//   border-radius: 1rem;
//   transition: all 0.3s ease-in;
//   width: 6rem;
//   height: 1.7rem;
//   font-size: 12px;
//   float: right;
//   margin-top: 0.5rem;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 950px) {
//     width: 8rem;
//     height: 2.3rem;

//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

// export const OrderBtn = styled.button`
//   background-color: #82ba32;
//   color: #fff;
//   border: 1px solid #9d9999;
//   border-radius: 1rem;
//   transition: all 0.3s ease-in;
//   width: 6rem;
//   height: 2rem;
//   font-size: 12px;
//   float: right;
//   margin-top: 0.3rem;
//   margin-right: 0.5rem;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 800px) {
//     width: 8rem;
//     height: 2.3rem;
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

export const Purchase = styled.p``;
