import React from "react";
import Footer from "../components/Footer/Footer";
import { InnerBanner } from "../components/Home/Home";
import Navbar from "../components/Navbar/Navbar";
import Contact from "../components/Contact/Contact";

const ContactPage = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner />
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactPage;
