import React from "react";
import { CategoryImg, CategorySection, CategoryTitle } from "./CategoryElement";
import clothing from "../../assets/images/yx0bl_512.webp";

const CategoriesCard = ({ category, onClick }) => {
  console.log("category", category);
  return (
    <CategorySection onClick={onClick}>
      <CategoryTitle>{category.name}</CategoryTitle>
      <CategoryImg src={clothing} alt="..." />
    </CategorySection>
  );
};

export default CategoriesCard;
