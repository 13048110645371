import "../App.css";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import Navbar from "../components/Navbar/Navbar";

const LoginPage = () => {
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    setShowPage(true);
  }, []);

  useEffect(() => {
    // Log the previous page's URL to the console
    console.log("document.referrer", window.history);
  }, []);

  // const currentPath = window.location.pathname;
  // const isHomePage = currentPath === "/";

  // const goBack = () => {
  //   window.history.back();
  // };

  return (
    <>
      {showPage && <Login />}
      <div className="popupbg">
        <Navbar />
        <Home />
        <Footer />
      </div>
    </>
  );
};

export default LoginPage;
