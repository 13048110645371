import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAddToCart } from "../../hook/useAddToCart";

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-transform: capitalize;
`;

const StyledTableRow = styled.tr`
  position: relative;
  border-bottom: 1px solid #dddddd;
  text-align: center;
`;

const StyledTableHeader = styled.th`
  font-size: 14px;
  font-weight: 500;
  padding: 0px 15px;
`;

const StyledTableCell = styled.td`
  font-size: 13px;
  font-weight: 500;
  padding: 10px 0;
`;

const StyledImage = styled.img`
  height: 100px;
  object-fit: contain;
`;

const QuantitySection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QunatityCount = styled.div`
  border: 1px solid #dddddd;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 70%;
  display: grid;
  place-items: center;
  height: 30px;
`;

const QunatityButtons = styled.div`
  display: grid;
  place-items: center;
  border: 1px solid #dddddd;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 30%;
  height: 30px;
`;

const EditControlsDiv = styled.div`
  /* padding: 20px 8px 5px 8px; */
  /* position: absolute;
  bottom: 0;
  right: 0; */
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CartXIcon = styled.i`
  color: #9d9999;
  cursor: pointer;
`;

const CartTable = ({ cartList }) => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [changeQty, setChangeQty] = useState(0);
  const [currentCartId, setCurrentCartId] = useState(null);
  const [isCartUpdate, setIsCartUpdate] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  // IMPORTED HOOKS FUNCTIONS
  const { updateCart, deleteCart, cartLoadingBtn } = useAddToCart();

  // Function to handle editing the cart
  const handleEditCart = (cartId, parseQty) => {
    setIsCartUpdate(true);

    if (cartId !== currentCartId) {
      setChangeQty(parseInt(parseQty));
      setClickCount(0);
      setCurrentCartId(cartId);
    }
  };

  // Function to cancel editing the cart
  const CancelEditCart = () => {
    setIsCartUpdate(false);
    setClickCount(0);
    setCurrentCartId(null);
  };

  // Function to handle changing the quantity
  const handleChangeQty = (condition, value) => {
    const parseQty =
      condition === "up"
        ? parseInt(value.quantity) + 1
        : parseInt(value.quantity);

    const cartId = value.ID;

    if (cartId !== currentCartId) {
      setChangeQty(parseQty);
      setClickCount(0);
      setCurrentCartId(cartId);
    } else {
      if (condition === "up") {
        if (clickCount === 0) {
          setChangeQty(parseQty);
        } else {
          setChangeQty(changeQty + 1);
        }
      } else if (condition === "down" && changeQty > 1) {
        if (clickCount === 0) {
          setChangeQty(parseQty - 1);
        } else {
          setChangeQty(changeQty - 1);
        }
      }
    }
    setClickCount(clickCount + 1);
  };

  // FUNCTION TO UPDATE ITEM QUANTITY
  const handleUpdateCart = (item) => {
    const guestId = localStorage.getItem("cartId");
    const userId = localStorage.getItem("userId");
    const data = {
      product_id: item.product_id,
      quantity: changeQty,
      cart_id: guestId,
      user_id: userId,
      // variation_id: item.variation_id,
    };
    updateCart(data);
    setCurrentCartId(null);
  };

  // FUNCTION TO DELETE ITEM FROM CART LIST
  const handleDeleteCart = (item) => {
    const guestId = localStorage.getItem("cartId");
    const data = {
      product_key: item.ID,
      cart_id: guestId,
    };
    deleteCart(data);
  };

  return (
    <StyledTable>
      <StyledTableRow>
        <StyledTableHeader>Item</StyledTableHeader>
        <StyledTableHeader></StyledTableHeader>
        <StyledTableHeader>Price</StyledTableHeader>
        <StyledTableHeader>Qty</StyledTableHeader>
        <StyledTableHeader>Subtotal</StyledTableHeader>
      </StyledTableRow>
      {cartList.cart_items.map((items, index) => {
        console.log(items);
        const body = items?.product_data?.body;
        const parseQty = parseInt(items.quantity);
        const productData = JSON.parse(body);
        const category = productData?.categories[0]?.name;
        const productName = productData?.name.replace(/\s/g, "-");
        // const variations = productData?.variations;
        // const matchingVariation = variations?.[items?.variation_id];
        const isImg = productData?.images.length > 0;
        const handleNavigate = () =>
          navigate(
            `/product/${category}/${productName}/${
              productData?.parent_id || productData?.id
            }`
          );
        return (
          <StyledTableRow key={index}>
            <StyledTableCell>
              <StyledImage
                onClick={handleNavigate}
                src={productData?.images[0]?.src}
                alt="..."
              />
            </StyledTableCell>
            <StyledTableCell>
              {items?.product_name?.split("").slice(0, 16).join("")}
            </StyledTableCell>
            <StyledTableCell>&#8377;{items.price}</StyledTableCell>
            <StyledTableCell>
              <QuantitySection>
                <QunatityCount>
                  {items.ID === currentCartId ? changeQty : items.quantity}
                </QunatityCount>
                <QunatityButtons>
                  {items.ID === currentCartId ? (
                    <>
                      <i
                        onClick={() => handleChangeQty("up", items)}
                        style={{ height: "5px", cursor: "pointer" }}
                        className="fa-solid fa-caret-up"
                      ></i>
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() => handleChangeQty("down", items)}
                        className="fa-solid fa-caret-down"
                      ></i>
                    </>
                  ) : (
                    <>
                      <i
                        style={{
                          height: "5px",
                          cursor: "pointer",
                          color: "#9d9999",
                        }}
                        className="fa-solid fa-caret-up"
                      ></i>
                      <i
                        style={{ cursor: "pointer", color: "#9d9999" }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    </>
                  )}
                </QunatityButtons>
              </QuantitySection>
            </StyledTableCell>
            <StyledTableCell>&#8377;{items.total}</StyledTableCell>
            <StyledTableCell>
              {isCartUpdate && items.ID === currentCartId ? (
                <EditControlsDiv>
                  <CartXIcon
                    onClick={CancelEditCart}
                    style={{
                      fontSize: "15px",
                    }}
                    className="fa-solid fa-xmark"
                  ></CartXIcon>
                  <CartXIcon
                    style={{
                      fontSize: "15px",
                    }}
                    onClick={() => handleUpdateCart(items)}
                    className="fa-solid fa-check"
                  ></CartXIcon>
                </EditControlsDiv>
              ) : (
                <EditControlsDiv>
                  <CartXIcon
                    onClick={() => handleEditCart(items.ID, items.quantity)}
                    className="fa-solid fa-pencil"
                  ></CartXIcon>

                  <CartXIcon
                    onClick={() => handleDeleteCart(items)}
                    className="fa-regular fa-trash-can"
                  ></CartXIcon>
                </EditControlsDiv>
              )}
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </StyledTable>
  );
};

export default CartTable;
