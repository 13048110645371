import styled, { keyframes } from "styled-components";
const slideInFromBottom = keyframes`
  0% {
    bottom: 0%;
    /* left: 0%; */
  }
  100% {
    
    bottom: 100%;
    left: 50%;
  }
`;
export const SignUpDiv = styled.div`
  display: grid;
  place-items: center;

  position: fixed;
  left: 50%;
  z-index: 1001;

  animation: ${slideInFromBottom} 0.4s ease-in-out forwards;
`;
export const SignFormDiv = styled.div`
  position: absolute;
  width: 400px;
  min-height: 480px;
  background-color: #fff;

  display: grid;
  place-items: center;

  /* box-shadow: 0 8px 12px rgba(0, 0, 0, 0.6); */

  top: 6rem;
  @media screen and (max-width: 950px) {
    width: 350px;
    height: 350px;
    top: 8rem;
  }
`;
export const SignForm = styled.form`
  display: grid;
  justify-content: center;

  .disabled {
    cursor: not-allowed;
  }
`;
export const SignLabel = styled.p`
  font-size: 16px;
  margin-bottom: 32px;
`;
export const SignUpInputLabel = styled.label`
  color: #9d9999;
  font-size: 12px;
`;
export const SignUpInput = styled.input`
  height: 35px;
  outline: none;
  margin-bottom: 16px;
  padding: 5px;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;
`;

export const SignUpBtn = styled.button`
  width: 100%;
  height: 40px;
  background-color: #82ba32;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;

// eye
// export const Eyeslash = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   height: 35px;
//   border: solid 1px #9d9999;
//   outline: none;
//   margin-bottom: 1px;
//   padding: 5px;
// `;

export const PassInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;
export const SearchIcon = styled.img`
  cursor: pointer;
  width: 25px;
`;
// remove button

export const RemoveItemaDiv = styled.div`
  position: absolute;
  top: 4rem;
  width: 400px;
  background-color: #fff;
  display: flex;
  justify-content: end;
  padding: 5px;
  z-index: 1001;

  @media screen and (max-width: 950px) {
    margin-top: 0.5rem;
    width: 350px;
    top: 5.5rem;
  }
`;
export const RemoveItem = styled.div`
  width: 25px;
  height: 25px;
  font-weight: 300;
  border: 1px solid #fff;
  color: #9d9999;

  &:hover {
    font-weight: 500;
    border: 1px solid #9d9999;
    color: black;
  }
  display: grid;
  place-items: center;
`;

// password check
export const HelperText = styled.ul`
  display: block;
  margin: 15px;
  font-size: 10px;
  /* padding: 8px; */
  border-radius: 6;
  line-height: 22px;
  color: #808080;

  .invalid {
    text-decoration: line-through;
  }
`;
export const InvalidText = styled.li``;
