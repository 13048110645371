import React, { useEffect, useState } from "react";
import { IsImg } from "../CheckOut/CheckOutElements";
import { useNavigate } from "react-router-dom";
import { useAddToCart } from "../../hook/useAddToCart";
import { PriceDetailsRes } from "../CheckOut/CheckOut";

import Skeleton from "react-loading-skeleton";
import Spinner from "../../images/spinner.gif";

import {
  CartCount,
  CartCountDiv,
  CartCountDivNew,
  CartCountText,
  CartFilter,
  CartFilterTitle,
  CartProdImg,
  CartProdPrice,
  CartProdResponsive,
  CartProdTextDiv,
  CartProdTitle,
  CartProduct,
  CartProducts,
  CartResDiv,
  CartSection,
  CartStatusText,
  CounterDiv,
  CounterIcon,
  // OrderBtn, // button
  OrderCheck,
  OrderStatus,
  OrderStatusDiv,
  OrderStatuses,
  OrderStatusTitle,
  PriceDetailsResponse,
  ProductCounter,
  RemoveItem,
  RemoveItemaDiv,
  ResCartStatusText,
  // SizeDiv,
  SkeletonLine,
  UpdateBtn,
} from "./CartElements";
import { useCartQuery } from "../../queries/api";
import Button from "../Button";
import Temp from "../Temp";
import CartTable from "./CartTable";
import { WishListNull } from "../WishList/WishListElements";
import PriceDetails from "./PriceDetails";

const Cart = () => {
  const navigate = useNavigate();

  // FETCHING API DATA
  const { data: cartList, error: cartErr, isLoading: cartLdg } = useCartQuery();

  // CHECKING CART DATA
  useEffect(() => {
    if (!cartErr && !cartLdg && cartList?.cart_id) {
      localStorage.setItem("cartId", cartList?.cart_data[0]?.ID);
    }
  }, [cartList, cartErr, cartLdg, navigate]);

  const isCartNull = !cartLdg && !cartList?.cart_items?.length > 0;
  // const isCartNull = !cartLdg && cartList?.status === false;

  return (
    <CartSection>
      <div style={{ display: "flex", flex: "2" }}>
        {cartLdg ? (
          <CartProducts>
            <div style={{ width: "100%" }}>
              <Skeleton height={25} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </div>
          </CartProducts>
        ) : isCartNull ? (
          <CartProducts>
            <div>Your shopping cart is empty.</div>
          </CartProducts>
        ) : (
          <CartTable cartList={cartList} />
        )}
      </div>

      {!cartLdg && !isCartNull && (
        <PriceDetailsResponse>
          <PriceDetails />
        </PriceDetailsResponse>
      )}
    </CartSection>
  );
};

export default Cart;

// const navigate = useNavigate();

// // STATE HOOKS
// const [changeQty, setChangeQty] = useState(0);
// const [isCartUpdate, setIsCartUpdate] = useState(false);
// const [clickCount, setClickCount] = useState(0);
// const [currentCartId, setCurrentCartId] = useState(null);

// // FETCHING API DATA
// const { data: cartList, error: cartErr, isLoading: cartLdg } = useCartQuery();

// // IMPORTED HOOKS FUNCTIONS
// const { updateCart, deleteCart, cartLoadingBtn } = useAddToCart();

// // CHECKING CART DATA
// useEffect(() => {
//   if (!cartErr && !cartLdg && cartList?.cart_id) {
//     localStorage.setItem("cartId", cartList?.cart_data[0]?.ID);
//   }

//   // if (!cartErr && !cartLdg && cartList?.status === false) {
//   //   navigate("/");
//   // }
// }, [cartList, cartErr, cartLdg, navigate]);

// const isCartNull = !cartLdg && !cartList?.cart_items?.length > 0;
// // const isCartNull = !cartLdg && cartList?.status === false;

// // FUNCTION TO HANDLE PLACE ORDER
// const handlePlaceOrder = () => {
//   const uIdString = localStorage.getItem("userId");
//   const user = uIdString === "null" ? null : parseFloat(uIdString);
//   if (user) {
//     navigate("/checkout/buy");
//   } else {
//     navigate("/login");
//   }
// };

// // FUNCTION TO CHANGE CART ITEM QUANTITY
// const handleChangeQty = (condition, value) => {
//   const parseQty =
//     condition === "up"
//       ? parseInt(value.quantity) + 1
//       : parseInt(value.quantity) - 1;
//   const cartId = value.ID;
//   setIsCartUpdate(true);

//   if (cartId !== currentCartId) {
//     setChangeQty(parseQty);
//     setClickCount(0);
//     setCurrentCartId(cartId);
//   } else {
//     if (condition === "up") {
//       if (clickCount === 0) {
//         setChangeQty(parseQty);
//       } else {
//         setChangeQty(changeQty + 1);
//       }
//     } else if (condition === "down" && changeQty > 1) {
//       if (clickCount === 0) {
//         setChangeQty(parseQty - 1);
//       } else {
//         setChangeQty(changeQty - 1);
//       }
//     }
//   }
//   setClickCount(clickCount + 1);
// };

// // FUNCTION TO UPDATE ITEM QUANTITY
// const handleUpdateCart = (item) => {
//   const guestId = localStorage.getItem("cartId");
//   const userId = localStorage.getItem("userId");
//   const data = {
//     product_id: item.product_id,
//     quantity: changeQty,
//     cart_id: guestId,
//     user_id: userId,
//     // variation_id: item.variation_id,
//   };
//   updateCart(data);
// };

// // FUNCTION TO DELETE ITEM FROM CART LIST
// const handleDeleteCart = (item) => {
//   const guestId = localStorage.getItem("cartId");
//   const data = {
//     product_key: item.ID,
//     cart_id: guestId,
//   };
//   deleteCart(data);
// };

// {/* <CartProducts>
//   {cartList?.cart_items?.map((items) => {
// const body = items?.product_data?.body;
// const parseQty = parseInt(items.quantity);
// const productData = JSON.parse(body);
// const category = productData?.categories[0]?.name;
// const productName = productData?.name.replace(/\s/g, "-");
// // const variations = productData?.variations;
// // const matchingVariation = variations?.[items?.variation_id];
// const isImg = productData?.images.length > 0;
// const handleNavigate = () =>
//   navigate(
//     `/product/${category}/${productName}/${
//       productData?.parent_id || productData?.id
//     }`
//   );
//     return (
//       <React.Fragment key={items.ID}>
//         <div>
//           <RemoveItemaDiv>
//             <RemoveItem onClick={() => handleDeleteCart(items)}>
//               <i className="fas fa-regular fa-xmark"></i>
//             </RemoveItem>
//           </RemoveItemaDiv>
//           <CartStatusText>Delivered by web-26</CartStatusText>
//           <CartProduct>
//             {isImg ? (
//               <CartProdImg
//                 className="img-object"
//                 onClick={handleNavigate}
//                 src={productData?.images[0]?.src}
//                 alt="..."
//               />
//             ) : (
//               <IsImg onClick={handleNavigate} />
//             )}
//             <CartResDiv>
//               <CartProdResponsive onClick={handleNavigate}>
//                 <CartProdTextDiv>
//                   <CartProdTitle>
//                     {items?.product_name?.split("").slice(0, 15).join("")}
//                     ...
//                   </CartProdTitle>
//                 </CartProdTextDiv>
//                 {productData.attributes.map((attribute, i) => (
//                   <CartProdPrice key={i}>
//                     {attribute.name} : {attribute.option}
//                   </CartProdPrice>
//                 ))}
//                 <CartProdPrice>&#8377; {items.price}</CartProdPrice>
//               </CartProdResponsive>
//               <CartCountDiv>
//                 <ResCartStatusText>Delivered by web-26</ResCartStatusText>

//                 <CartCountDivNew>
//                   <CartCount>
//                     <CartCountText>Quantity</CartCountText>
//                     <CounterDiv>
//                       <CounterIcon
//                         onClick={() => handleChangeQty("down", items)}
//                       >
//                         <i className="fas fa-solid fa-arrow-down"></i>
//                       </CounterIcon>

//                       <ProductCounter>
//                         {items.ID === currentCartId
//                           ? changeQty
//                           : items.quantity}
//                       </ProductCounter>

//                       <CounterIcon onClick={() => handleChangeQty("up", items)}>
//                         <i className="fas fa-solid fa-arrow-up"></i>
//                       </CounterIcon>
//                     </CounterDiv>
//                   </CartCount>

//                   {/* <CartCount>
//                               <CartCountText>Size</CartCountText>
//                               <SizeDiv>
//                                 <ProductCounter>L</ProductCounter>
//                                 <CounterIcon>
//                                   <i className="fas fa-solid fa-arrow-down"></i>
//                                 </CounterIcon>
//                               </SizeDiv>
//                             </CartCount> */}
//                   {changeQty !== parseQty &&
//                     items.ID === currentCartId &&
//                     isCartUpdate && (
//                       <React.Fragment>
//                         {/* <UpdateBtn
//                                     onClick={() => handleUpdateCart(items)}
//                                   >
//                                     {cartLoadingBtn ? (
//                                       <img
//                                         className="button-loading"
//                                         src={Spinner}
//                                         alt=""
//                                       />
//                                     ) : (
//                                       "Update"
//                                     )}
//                                   </UpdateBtn> */}
//                         <Button
//                           onClick={() => handleUpdateCart(items)}
//                           mediaSize={"800px"}
//                           style={{
//                             display: "flex",
//                             height: "2rem",
//                             float: "right",
//                             marginTop: "0.6rem",
//                             marginTeft: "0.5rem",
//                           }}
//                           title={
//                             cartLoadingBtn ? (
//                               <img
//                                 className="button-loading"
//                                 src={Spinner}
//                                 alt=""
//                               />
//                             ) : (
//                               "Update"
//                             )
//                           }
//                         />
//                       </React.Fragment>
//                     )}
//                 </CartCountDivNew>
//               </CartCountDiv>
//             </CartResDiv>
//           </CartProduct>
//         </div>
//       </React.Fragment>
//     );
//   })}
//   {/* <OrderBtn onClick={handlePlaceOrder}>Place Order</OrderBtn> button */}

//   <Button
//     title={"Place Order"}
//     onClick={handlePlaceOrder}
//     style={{
//       height: "2rem",
//       float: "right",
//       marginTop: "0.3rem",
//       marginRight: "0.5rem",
//     }}
//     mediaStyles={{
//       height: "2.3rem",
//       width: "8rem",
//     }}
//     mediaSize={"800px"}
//   />
// </CartProducts>; */}

////loading

// {/* <CartProducts>
//   {Array.from({ length: 2 }).map((_, i) => {
//     return (
//       <div
//         key={i}
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginTop: "1rem",
//         }}
//       >
//         <div style={{ display: "flex", gap: "10px" }}>
//           <Skeleton width={100} height={100} />
//           <Skeleton width={250} count={3} />
//         </div>
//         <SkeletonLine>
//           <Skeleton width={250} />
//         </SkeletonLine>
//       </div>
//     );
//   })}
// </CartProducts>; */}
