import styled from "styled-components";

export const CheckOutSection = styled.div`
  margin: 3rem 5rem;
  display: flex;
  justify-content: start;
  gap: 20px;

  @media screen and (max-width: 950px) {
    margin: 3rem 0.5rem;
  }
  @media screen and (max-width: 800px) {
    display: grid;
    place-items: center;
    margin: 3rem 0.5rem;
  }
`;
export const DelivAddress = styled.div`
  width: 50%;
  background-color: #fff;
  flex: 2;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;
export const AddressDiv = styled.div``;
export const AddressTitle = styled.p`
  /* background-color: #82ba32; */
  background-color: #2faa04;
  font-weight: 300;
  color: #fff;
  padding: 0.5rem 1rem;
  border: 1px solid #9d9999;
`;
export const OwnAddressDiv = styled.div`
  background-color: #f5f5f5;
  border-bottom: 2px solid #9d9999;
  padding: 1rem 0.5rem;

  display: flex;
  justify-content: space-between;
`;
export const OwnAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 950px) {
    /* align-items: flex-start; */
  }
`;
export const AddressPoint = styled.div`
  /* width: 12px;
  height: 12px;
  border-radius: 50%; */
  /* background-color: #9d9999; */
  color: #9d9999;

  @media screen and (max-width: 950px) {
    /* width: 10px;
    height: 10px; */
    /* margin-top: 6px; */
  }
`;
export const OwnAddressText = styled.p`
  font-size: 15px;
  font-weight: 300;
`;
//need
export const PriceDetailsSection = styled.div`
  /* width: 300px; */
  width: 500px; /* temp */
  height: 100%;
  margin: 0 3rem;
  border: 1px solid #9d9999;
  /* border-bottom: none; */

  @media screen and (max-width: 800px) {
    display: grid;
    place-items: center;
    margin: 2rem 0 0;
    width: 100%;
    height: auto;
  }
`;
export const CheckoutButton = styled.p`
  background-color: #82ba32;
  color: #fff;
  transition: all 0.3s ease-in;
  padding: 0.5rem;
  cursor: pointer;
  text-align-last: center;
  width: 100%;
  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 800px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;

// export const AddressBtn = styled.button`
//   background-color: #82ba32;
//   color: #fff;
//   border: 1px solid #9d9999;
//   border-radius: 1rem;
//   transition: all 0.3s ease-in;
//   min-width: 6rem;
//   max-width: 6rem;
//   height: 2rem;
//   font-size: 12px;
//   margin: 1rem 0 1rem 1rem;

//   &:hover {
//     background-color: green;
//   }
//   @media screen and (max-width: 800px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;
export const AddNewAddress = styled.button`
  display: flex;
  align-items: center;
  font-weight: 300;
  width: 100%;
  padding: 0.1rem 1rem;
  border: 1px solid #9d9999;
  border-top: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  // background-color: #82ba32;
  // color: #fff;
`;
export const PlusIcon = styled.p`
  font-size: 25px;
  margin-right: 1rem;
`;
export const AddText = styled.p`
  font-size: 15px;
`;
export const OrderPayDiv = styled.div`
  background-color: #f5f5f5;
  border-left: 1px solid #9d9999;
  border-right: 1px solid #9d9999;
`;
export const OrderPayText = styled.p`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #9d9999;
  border-top: 1px solid #9d9999;
  font-weight: 300;
  cursor: pointer;
`;

// PRICE DETAILS

export const PriceDetailsTitle = styled.p`
  font-size: 25px;
  color: #0f7f08;
  border-bottom: 1px solid #9d9999;
  /* width: 300px; */
  width: 100%;
  padding: 0.5rem 1rem;

  @media screen and (max-width: 800px) {
    padding: 1rem 1rem;
    width: 100%;
  }
`;

export const PriceDetailsDiv = styled.div`
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const PriceDetailses = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9d9999;
  padding: 1rem 1rem;
  font-size: 17px;
  font-weight: 300;

  @media screen and (max-width: 800px) {
    padding: 1rem 1rem;
  }
`;
export const Pricetext = styled.p``;
export const PriceTotal = styled.p`
  font-weight: 600;
`;

// order summery
export const OrderSummeryDivs = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #9d9999;
`;
export const OrderSummeryDiv = styled.div``;
export const OrderSummeryDate = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin-top: 1rem;

  @media screen and (max-width: 800px) {
    margin-top: 1.4rem;
    font-size: 11px;
  }
`;
export const OrderProductCart = styled.div`
  display: flex;
  justify-content: space-between;
  width: 230px;
  @media screen and (max-width: 800px) {
    width: 190px;
  }
`;
export const OrderProduct = styled.div`
  display: flex;
  place-items: center;
  margin-bottom: 16px;
`;
export const OrderProductImg = styled.img`
  height: 100px;
  margin-right: 8px;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    height: 85px;
  }
`;

export const IsImg = styled.img`
  height: 100px;
  width: 100px;

  margin-right: 8px;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    height: 85px;
    width: 85px;
  }
`;
export const OrderDetails = styled.div``;
export const OrderDetailsTitle = styled.div`
  font-size: 20px;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const OrderDetailsDesc = styled.div`
  max-width: 250px;
  color: #9d9999;
  @media screen and (max-width: 800px) {
    font-size: 13px;
  }
`;
export const ProductCount = styled.div`
  display: flex;
  align-items: center;
`;
export const CountIcon = styled.button`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: solid 1px #9d9999;
  background-color: transparent;
  font-size: 15px;
  cursor: pointer;
  display: grid;
  place-items: center;

  @media screen and (max-width: 800px) {
    width: 25px;
    height: 25px;
    font-size: 15px;
  }
`;
export const Count = styled.div`
  border: solid 1px;
  width: 3rem;
  height: 27px;
  text-align: center;
  border: solid 1px #9d9999;
  background-color: transparent;
  font-size: 15px;
  margin: 0 8px;
  display: grid;
  place-items: center;

  @media screen and (max-width: 800px) {
    width: 45px;
    height: 25px;
    font-size: 14px;
    margin: 0 5px;
  }
`;
export const RemoveBtn = styled.button`
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 19px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const OrderConfirmDiv = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9d9999;
  padding: 16px 8px;

  @media screen and (max-width: 800px) {
    display: grid;
  }
`;
export const OrderConfirmText = styled.p`
  font-size: 13px;
  @media screen and (max-width: 800px) {
    font-size: 11px;
    margin-bottom: 8px;
  }
`;

// export const OrderConfirmBtn = styled.button`
//   width: 130px;
//   height: 35px;
//   border-radius: 20px;
//   background-color: #82ba32;
//   border: none;
//   color: #fff;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }
//   @media screen and (max-width: 800px) {
//     width: 110px;
//     height: 33px;

//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;
// order summery

export const PaymentMethods = styled.div`
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  font-weight: 300;
  border-bottom: 1px solid #9d9999;
`;
export const PayMethod = styled.p`
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
`;

// address

export const ErrorMessage = styled.p`
  color: red;
  font-size: 10px;
  margin: 2px 0 0 8px;
`;
export const AddAddressDiv = styled.div``;
export const AddAddressForm = styled.form``;
export const AddInputDivs = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  @media screen and (max-width: 950px) {
    display: grid;
  }
`;
export const AddInputDiv = styled.div`
  width: 100%;
`;
export const AddressInput = styled.input`
  /* width: 280px; */
  width: 100%;
  height: 2.5rem;
  /* margin: 20px 16px 0 0; */
  padding: 0 0 0 0.5rem;
  outline: none;
  border: 1px solid #9d9999;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 2.5rem;
    /* margin: 20px 0 0 0; */
    padding: 0 0 0 0.5rem;
    outline: none;
    border: 1px solid #9d9999;
  }
`;
export const AddressTextInput = styled.textarea`
  /* width: 576px; */
  width: 100%;
  height: 80px;
  /* margin: 20px 16px 0 0; */
  padding: 0.5rem 0 0 0.5rem;
  outline: none;
  border: 1px solid #9d9999;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 80px;
    /* margin: 20px 0 0 0; */
    padding: 0 0 0 0.5rem;
    outline: none;
    border: 1px solid #9d9999;
  }
`;

export const AddressType = styled.form`
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  color: #9d9999;
  margin: 16px 0;
`;
export const AddressTypeLabel = styled.label``;
export const AddressInputText = styled.label`
  margin: 1rem 1rem 0 0;
`;
export const AddressTypeDiv = styled.div``;
export const AddressInputSlct = styled.input`
  margin: 1rem 0.5rem;
  width: 14px;
  height: 14px;
`;

export const ButtonsDiv = styled.div`
  margin: 0.5rem 0 2rem 0;
  display: flex;
`;
// export const AddressSaveBtn = styled.button`
//   width: 200px;
//   height: 40px;
//   border-radius: 20px;
//   background-color: #82ba32;
//   border: none;
//   color: #fff;
//   margin-right: 1rem;
//   cursor: pointer;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 800px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

export const AddressCancekBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
// address
