import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../images/spinner.gif";
import {
  // Eyeslash,
  HelperText,
  InvalidText,
  PassInput,
  // RemoveItem,
  // RemoveItemaDiv,
  SignForm,
  // SignFormDiv,
  // SignLabel,
  SignUpBtn,
  // SignUpDiv,
  SignUpInput,
  SignUpInputLabel,
} from "./SignUpElements";
import {
  backendUrl,
  consumerKey,
  consumerSecret,
  users_api,
} from "../../apiUrls";
import {
  AccountForm,
  AccountInput,
  Eyeslash,
  LoginBtn,
} from "../Login/LoginElements";

export const AlertBox = () => {
  return (
    <div class="notification">
      <div class="notification_body">notification message.</div>
      <div class="notification_progress"></div>
    </div>
  );
};

const SignUp = ({ isSigned, toggleLogin }) => {
  // STATE HOOKS
  // const [imageDataUrl, setImageDataUrl] = useState(null);
  const [passType, setPassType] = useState("password");
  const [password, setPassword] = useState("");
  // const [signedUp, setSignedUp] = useState(false);
  const [signedError, setSignedError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    // password: "",
    // avatar_url: "",
  });

  const [isValid, setIsValid] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    special: false,
  });

  const [checkPasswordStrength, setCheckPasswordStrength] = useState(false);
  console.log("checkPasswordStrength", checkPasswordStrength);

  // CHECK IT'S VALID PASSWORD
  useEffect(() => {
    setIsValid({
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      special: /[0-9!@#$%^&*]/.test(password),
    });
  }, [password]);

  const infos = {
    ...formData,
    password: password,
    // avatar_url: imageDataUrl,
  };

  // FUNCTION TO HANDLE INPUT CHANGES
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // FUNCTION TO HANDLE SIGNUP
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckPasswordStrength(true);
    if (
      !isValid.length ||
      !isValid.lowercase ||
      !isValid.uppercase
      // !isValid.special
    ) {
      return;
    }

    try {
      setSignedError(false);
      setIsLoading(true);
      // const response = await axios.post(`${backendUrl}${users_api}`, infos, {
      await axios.post(`${backendUrl}${users_api}`, infos, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });

      // setSignedUp(true);
      toggleLogin();
      setFormData("");
    } catch (error) {
      setSignedError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AccountForm onSubmit={handleSubmit}>
      <label>Username</label>
      <AccountInput
        type="text"
        name="username"
        value={formData.username}
        onChange={handleChange}
      />
      <label>Email</label>
      <AccountInput
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <label>Password</label>
      <Eyeslash>
        <PassInput
          type={passType}
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          onClick={() =>
            setPassType(passType === "password" ? "text" : "password")
          }
          style={{
            cursor: "pointer",
            fontSize: passType === "password" ? "11px" : "12px",
          }}
          className={`fas fa-light fa-eye${
            passType === "password" ? "" : "-slash"
          }`}
        ></i>
      </Eyeslash>

      {password && (
        <HelperText>
          <InvalidText
            className={isValid.length ? "invalid" : ""}
            style={{
              color: checkPasswordStrength && !isValid.length ? "red" : "",
            }}
          >
            Must be at least 8 characters long.
          </InvalidText>
          <InvalidText
            className={isValid.lowercase ? "invalid" : ""}
            style={{
              color: checkPasswordStrength && !isValid.lowercase ? "red" : "",
            }}
          >
            Must contain a lowercase letter.
          </InvalidText>
          <InvalidText
            className={isValid.uppercase ? "invalid" : ""}
            style={{
              color: checkPasswordStrength && !isValid.uppercase ? "red" : "",
            }}
          >
            Must contain an uppercase letter.
          </InvalidText>
        </HelperText>
      )}

      {isLoading ? (
        <LoginBtn>
          <img className="button-loading" src={Spinner} alt="" />
        </LoginBtn>
      ) : (
        <LoginBtn type="submit">Create Account</LoginBtn>
      )}

      {signedError && (
        <div class="notification">
          <div class="notification_body">Please signup again</div>
          <div class="notification_progress"></div>
        </div>
      )}
    </AccountForm>
  );
};

export default SignUp;
