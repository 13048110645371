import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  // CateBtn, // button
  CateContent,
  CateDesc,
  Categories,
  CateImg,
  CateImgTitle,
  CateTitle,
  // SideBtn, // button
  SideDesc,
  SidePic,
  SideText,
  SideTitle,
  // SliderBtn, // button
  SliderDesc,
  SliderImg,
  SliderPic,
  SliderPrice,
  SliderText,
  SliderTitle,
  HomeDiv,
  CateTxtContent,
  CateContentBox,
  Products,
  OurProducts,
  FilterTitles,
  FilterTitle,
  ProductBox,
  AdBanner,
  NewsBtn,
  NewsText,
  NewsTitle,
  NewsImg,
  News,
  NewsHead,
  LatestNews,
  NewsContent,
  NewsDiv,
  About,
  AboutContent,
  AboutTitle,
  AboutText,
  AboutImg,
  CategoryDiv,
  InnerHomeSliderDiv,
  MainBanner,
  Slide,
  SideBanner,
  BannerSection,
  AboutContentDiv,
} from "./HomeElements";
import Skeleton from "react-loading-skeleton";
import AllProducts from "../AllProducts/AllProducts";

import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

import {
  backendUrl,
  banner_api,
  consumerKey,
  consumerSecret,
  getText,
} from "../../apiUrls";

import {
  useBlogQuery,
  useCategoriesQuery,
  useFeaturedQuery,
  useSettingsQuery,
} from "../../queries/api";
import Button from "../Button";

// SCROLL TO TOP FUNCTION
export const scrollToTop = () => window.scrollTo(0, 0);

// INNER BANNER COMPONENT
export const InnerBanner = () => {
  return (
    <InnerHomeSliderDiv>
      {/* <InnerHomeSlider>User Profile</InnerHomeSlider> */}
    </InnerHomeSliderDiv>
  );
};

// SLIDE BANNER COMPONENT
export const HomeSliderSection = () => {
  // CAROUSEL REPONSIVE STYLE
  const silderRes = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  // FETCH BANNER
  const fetchBanner = async () => {
    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const bannerResponse = await fetch(
      `${backendUrl}${banner_api}?banner_cat=64`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
    const bannerData = await bannerResponse.json();
    return bannerData;
  };

  const { data: bannerData, isLoading: bannerLoading } = useQuery(
    ["mainbanner"],
    fetchBanner
  );

  // FETCH SIDE BANNER
  const fetchSideBanner = async () => {
    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const sideBannerResponse = await fetch(
      `${backendUrl}${banner_api}?banner_cat=65`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
    const sideBannerData = await sideBannerResponse.json();
    return sideBannerData;
  };

  const { data: sideBannerData, isLoading: sideBannerLoading } = useQuery(
    ["sidebanner"],
    fetchSideBanner
  );

  const customButtonStyle = {
    width: "5rem",
    fontSize: "11px",
  };

  return (
    <BannerSection>
      <MainBanner>
        {bannerLoading ? (
          <Skeleton className="skeleton-banner" />
        ) : (
          <Carousel
            autoPlay
            autoPlaySpeed={3000}
            responsive={silderRes}
            infinite
          >
            {bannerData?.map((slide, index) => (
              <Slide key={index}>
                <SliderText>
                  <SliderTitle>{slide?.title?.rendered}</SliderTitle>
                  <SliderPrice>
                    {slide?.additional_data_values?.offer_text}
                  </SliderPrice>
                  <SliderDesc>{getText(slide?.content?.rendered)}</SliderDesc>
                  {/* <SliderBtn>Shop now</SliderBtn> button */}
                  <Button mediaSize={"950px"} title={"Shop Now"} />
                </SliderText>
                <SliderPic>
                  <SliderImg
                    src={slide?.additional_data_values?.image_url}
                    alt="..."
                  />
                </SliderPic>
              </Slide>
            ))}
          </Carousel>
        )}
      </MainBanner>
      <SideBanner>
        {sideBannerLoading ? (
          <>
            <Skeleton height={187} className="skeleton-banner" />
            <Skeleton height={187} className="skeleton-banner" />
          </>
        ) : (
          sideBannerData.map((items, index) => {
            return (
              <SidePic
                key={index}
                style={{
                  backgroundImage: `url(${items?.additional_data_values?.image_url})`,
                }}
              >
                <SideText>
                  <SideTitle>{items.title.rendered}</SideTitle>
                  <SideDesc>{getText(items?.content?.rendered)}</SideDesc>
                  {/* <SideBtn>Shop now</SideBtn> button */}
                  <Button
                    mediaSize={"950px"}
                    title={"Shop Now"}
                    style={{
                      width: "5rem",
                      fontSize: "11px",
                    }}
                  />
                </SideText>
              </SidePic>
            );
          })
        )}
      </SideBanner>
    </BannerSection>
  );
};

const Home = () => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [type, setType] = useState("latest");

  // FETCHING API DATA
  const { data: featured, isLoading: featuredLoading } = useFeaturedQuery();
  const { data: categories, isLoading: categoriesLdg } = useCategoriesQuery();
  const { data: blogs, isLoading: blogsLoading } = useBlogQuery();
  const { data: settings, isLoading: settingsLoading } = useSettingsQuery();

  // FETCH PRODUCTS BY TYPE
  const fetchFeaturedData = async (type) => {
    try {
      const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
      const response = await fetch(
        `${backendUrl}/wp-json/wc/products/featured?tabs=${type}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      const data = await response.json();

      if (type === "latest") {
        return data.new;
      } else if (type === "rated") {
        return data.rated;
      } else if (type === "best") {
        return data.best;
      }
    } catch (error) {
      throw new Error("An error occurred while fetching data.");
    }
  };

  const { data: alterProduct, isLoading: alterProductLoading } = useQuery(
    ["featured", type],
    () => fetchFeaturedData(type)
  );
  console.log("alterProduct", alterProduct)

  const splitIndex = Math.ceil(alterProduct?.length / 2);
  const firstHalf = alterProduct?.slice(0, splitIndex);
  const secondHalf = alterProduct?.slice(splitIndex);

  // SWITCHING TO PRODUCTS LISTING TYPE
  const handleproductsType = (value) => {
    setType(value);
  };

  return (
    <React.Fragment>
      <HomeSliderSection />
      <HomeDiv>
        <Categories>
          <CateContentBox>
            <CateTxtContent>
              <CateTitle>Top Categories</CateTitle>
              <CateDesc>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. laborum
                repellendus nostrum possimus impedit libero
              </CateDesc>
              {/* <CateBtn>Buy Now</CateBtn> */}
              <Button mediaSize={"950px"} title={"Buy Now"} />
            </CateTxtContent>
          </CateContentBox>
          <CategoryDiv>
            {categoriesLdg
              ? Array.from({ length: 3 }).map((_, index) => (
                <CateContent key={index}>
                  <Skeleton height={400} />
                </CateContent>
              ))
              : categories?.slice(0, 3).map((items, index) => (
                <CateContent key={index}>
                  <CateImg
                    onClick={() => {
                      scrollToTop();
                      navigate(
                        `/${items?.name?.toLowerCase()}/${items.id}/products`
                      );
                    }}
                    style={{ backgroundImage: `url(${items?.image?.src})` }}
                  >
                    <CateImgTitle>{items.name}</CateImgTitle>
                  </CateImg>
                </CateContent>
              ))}
          </CategoryDiv>
        </Categories>
        <Products id="newarrivals">
          <ProductBox>
            <OurProducts>Our Products</OurProducts>
            <FilterTitles>
              <FilterTitle
                style={{
                  borderBottom: type === "latest" && "2px solid #2faa04",
                }}
                onClick={() => handleproductsType("latest")}
              >
                NEW PRODUCTS
              </FilterTitle>
              <FilterTitle
                style={{ borderBottom: type === "best" && "2px solid #2faa04" }}
                onClick={() => handleproductsType("best")}
              >
                BEST SELLER
              </FilterTitle>
              <FilterTitle
                style={{
                  borderBottom: type === "rated" && "2px solid #2faa04",
                }}
                onClick={() => handleproductsType("rated")}
              >
                TOP RATED
              </FilterTitle>
            </FilterTitles>
            <AllProducts
              data={firstHalf}
              alterProductLoading={alterProductLoading}
            />
            <AllProducts
              data={secondHalf}
              alterProductLoading={alterProductLoading}
            />
          </ProductBox>
        </Products>
        {/* <AdBanner> */}
        {/* <BannerImgLeft src={AdvBanner1} alt="..." />
          <BannerImgRight src={AdvBanner2} alt="..." />
          <AdvBannerCard>
            <AdvBannerTxt>
              <OfferBanner>50% Off</OfferBanner>
              <AdvTitle>Deal Off Times Week</AdvTitle>
              <TotalTime>
                <Times>
                  <Time>47</Time>
                  <TimeTxt>HRS</TimeTxt>
                </Times>
                <Times>
                  <Time>51</Time>
                  <TimeTxt>MIN</TimeTxt>
                </Times>
                <Times>
                  <Time>45</Time>
                  <TimeTxt>SEC</TimeTxt>
                </Times>
              </TotalTime>
              <AdvButton>Shop now</AdvButton>
            </AdvBannerTxt>
          </AdvBannerCard> */}
        {/* </AdBanner> */}
        <Products>
          <ProductBox>
            <OurProducts>Featured Products</OurProducts>
            <AllProducts data={!featuredLoading ? featured?.featured : []} />
          </ProductBox>
        </Products>
        <LatestNews>
          <NewsHead>Latest Blogs</NewsHead>
          <News>
            {blogsLoading
              ? Array.from({ length: 2 }).map((_, index) => (
                <NewsDiv key={index}>
                  <Skeleton className="skeleton-image" />
                  <NewsContent>
                    <NewsTitle>
                      <Skeleton className="skeleton-title" />
                    </NewsTitle>
                    <NewsText>
                      <Skeleton count={3} className="skeleton-text" />
                    </NewsText>
                    <Skeleton className="skeleton-button" />
                  </NewsContent>
                </NewsDiv>
              ))
              : blogs?.slice(0, 2)?.map((items, index) => {
                return (
                  <NewsDiv key={index}>
                    <NewsImg
                      className="img-object"
                      src={items?.additional_data_values?.image_url}
                      alt="..."
                    />
                    <NewsContent>
                      <NewsTitle>{items?.title?.rendered}</NewsTitle>
                      <NewsText>
                        {getText(items?.content?.rendered)
                          .split(" ")
                          ?.slice(0, 15)
                          .join(" ")}
                      </NewsText>
                      {/* <NewsBtn
                          onClick={() => {
                            scrollToTop();
                            navigate(`/blog/${items.id}`);
                          }}
                        >
                          Read More
                        </NewsBtn> button */}
                      <Button
                        mediaSize={"950px"}
                        title={"Read More"}
                        style={{
                          width: "100px",
                          height: "32px",
                          borderRadius: "10px",
                          border: "none",
                          fontSize: "13px",
                        }}
                        onClick={() => {
                          scrollToTop();
                          navigate(`/blog/${items.id}`);
                        }}
                      />
                    </NewsContent>
                  </NewsDiv>
                );
              })}
          </News>
        </LatestNews>
        {settingsLoading ? (
          <div>
            <Skeleton count={1} width={100} /> <Skeleton count={5} />
          </div>
        ) : (
          <About>
            <AboutContentDiv>
              <AboutContent>
                <AboutTitle>{settings?.about?.title}</AboutTitle>
                <AboutText>{settings?.about?.content}</AboutText>
              </AboutContent>
              <AboutContent>
                <AboutTitle style={{ fontWeight: "600", fontSize: "22px" }}>
                  Vision
                </AboutTitle>
                <AboutText>
                  Wiscart was born out out of a shared love for sports and a
                  desire to elevate the athletic experience. Established in
                  2020, we have
                </AboutText>
              </AboutContent>
              <AboutContent>
                <AboutTitle style={{ fontWeight: "600", fontSize: "22px" }}>
                  Mission
                </AboutTitle>
                <AboutText>
                  Wiscart was born out out of a shared love for sports and a
                  desire to elevate the athletic experience. Established in
                  2020, we have
                </AboutText>
              </AboutContent>
            </AboutContentDiv>
            <AboutImg src={settings?.about?.image} alt="..." />
          </About>
        )}
      </HomeDiv>
    </React.Fragment>
  );
};

export default Home;
