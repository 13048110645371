import styled from "styled-components";

export const ProductsListing = styled.div`
  margin: 4rem 4rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid #9d9999;

  @media screen and (max-width: 950px) {
    margin: 3rem 0.5rem 0rem 0.5rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 800px) {
    margin: 3rem 0.5rem 0rem 0.5rem;
    padding-bottom: 0;
    border-bottom: none;
  }
`;
export const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 0;
  }
`;
export const ListTitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  color: green;

  @media screen and (max-width: 800px) {
    font-size: 25px;
  }
`;
export const ListTypeIcons = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const ListTypeIcon = styled.button`
  background-color: transparent;
  border: solid 1px #9d9999;
  width: 50px;
  height: 30px;
  border-radius: 1rem;
  margin-right: 3px;
  cursor: pointer;

  display: grid;
  place-items: center;
`;
export const ListProducts = styled.div`
  display: flex;
`;

export const FilterIconDiv = styled.button`
  @media screen and (min-width: 800px) {
    display: none;
  }

  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  font-weight: 300;
  background-color: transparent;

  padding: 2px 5px;
  cursor: pointer;
  border: 1px solid #9d9999;
`;
export const FilterIconText = styled.p`
  margin-right: 0.5rem;
`;

// PRODUCT LIST CARD --------------

export const ProductsListDiv = styled.div`
  width: 80%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const ProductsListCard = styled.div`
  width: 200px;
  margin: 1rem 0;

  @media screen and (min-width: 800px) {
    #hoverlist {
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }
  }
  &:hover {
    #hoverlist {
      opacity: 1;
    }
  }

  @media screen and (max-width: 800px) {
    margin: 0;
  }
`;

// PRODUCT LIST CARD --------------
export const ProductFilterDiv = styled.div`
  width: 250px;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

// Filter
export const FilterPrice = styled.div`
  margin-bottom: 1.5rem;
`;
export const PriceTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #9d9999;
  margin-bottom: 1rem;
`;
export const NavInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;
export const SearchIcon = styled.img`
  cursor: pointer;
  width: 20px;
  margin-right: 5px;
`;

export const RangeSlider = styled.div`
  width: 250px;
  height: 5px;
  border-radius: 5px;
  background-color: #9d9999;
  position: relative;
`;
export const RangeProgress = styled.div`
  height: 5px;
  left: 0%;
  right: 100%;
  position: absolute;
  border-radius: 5px;
  background-color: #82ba32;
`;

export const RangeInput = styled.div`
  position: relative;
`;
export const MaxMinInput = styled.input`
  position: absolute;
  top: -5px;
  height: 5px;
  width: 100%;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    pointer-events: auto;
    cursor: pointer;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #82ba32;
  }
`;
export const RangeCounts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

export const RangeCount = styled.div`
  display: flex;
`;
export const RangeCountTitle = styled.span`
  font-size: 15px;
  font-weight: 300;
  margin-right: 0.5rem;
`;
export const RangeCountInput = styled.input`
  width: 100%;
  text-align: center;
  outline: none;
  border: solid 1px #9d9999;
  -webkit-appearance: none;
  border-radius: 0.5rem;
  height: 25px;
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const RangeDevider = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin: 0 0.5rem;
`;

export const ProdBrands = styled.div``;
export const ProdBrand = styled.div`
  display: flex;
  line-height: 2rem;
  align-items: center;
`;
export const BrandCheck = styled.input`
  margin-right: 5px;
  cursor: pointer;
`;
export const BrandName = styled.label`
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
`;

// MORE PRODUCTS
export const MoreProducts = styled.div`
  color: #fff;
`;
export const MoreProdBox1 = styled.div`
  display: grid;
  place-items: center;
  line-height: 2rem;

  background-color: #f9c802;
  padding: 4rem;
  margin-bottom: 1.5rem;
`;

export const MoreProdTitle = styled.p`
  font-size: 25px;
`;
export const MoreProdCount = styled.p``;
export const MoreProdBtn = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
`;

// LIST FILTERED ITEMS START //
export const ListFilteredWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 200px;
  flex-wrap: wrap;
  font-size: 13px;
`;
export const FilteredListContainer = styled.div`
  border: 1px solid black;
  padding: 1px 5px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  :hover {
    filter: blur(0.5px);
  }
`;
export const FilteredList = styled.p``;
export const ClearListFilteres = styled.div`
  margin-top: 8px;
  color: black;
  font-size: small;
  cursor: pointer;
`;
// LIST FILTERED ITEMS END //
