import styled from "styled-components";

// export const ProductCardWrapper = styled.div`
//   position: relative;
//   width: 250px;
//   margin: 2rem;

//   @media (max-width: 950px) {
//     width: 170px;
//     margin: 2rem 0.5rem 0 0.5rem;

//     .skeleton-product {
//       width: 160px;
//       height: 200px;
//     }
//   }
//   @media (min-width: 950px) {
//     .skeleton-product {
//       width: 250px;
//       height: 330px;
//     }
//   }
// `;

// export const ProductImg = styled.div`
//   position: relative;
//   height: 300px;
//   width: 250px;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

//   &:hover {
//     .product-buttons {
//       opacity: 1;
//     }
//   }

//   @media (max-width: 950px) {
//     width: 165px;
//     height: 200px;

//     .product-buttons {
//       opacity: 1;
//     }
//   }
// `;

// export const ProductButtons = styled.div`
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   height: 30px;
//   opacity: 0;

//   button:nth-child(1) {
//     background-color: #82ba32;
//     color: white;
//     width: 100%;
//     border-top: none;
//     border-bottom: none;
//     border-right: 0.5px solid #fff;
//     border-left: none;
//     font-size: 12px;
//     cursor: pointer;
//   }

//   button:nth-child(2) {
//     background-color: #82ba32;
//     color: white;
//     width: 100%;
//     border-top: none;
//     border-bottom: none;
//     border-left: 0.5px solid #fff;
//     border-right: none;
//     font-size: 12px;
//     cursor: pointer;
//   }
// `;

// export const ProductDetails = styled.div``;
// export const PriceReview = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
// export const ProductCategory = styled.p`
//   font-size: 12px;
//   font-weight: 300;
// `;

// export const ProductName = styled.p`
//   font-size: 15px;
//   font-weight: 400;
// `;

// export const ProductPrice = styled.p`
//   font-size: 15px;

//   @media (max-width: 950px) {
//     font-size: 13px;
//   }
// `;
// Wishlist null
export const WishListSection = styled.div`
  min-height: 100px;
  margin: 2rem 4rem;
`;
export const WishTitle = styled.p`
  font-size: 21px;
  font-weight: 600;
`;
export const WishListNull = styled.div`
  width: 100%;
  height: 10rem;
  display: grid;
  place-items: center;
  color: #9d9999;
`;

// wishlist table design
export const WishImg = styled.img`
  width: 50px;
  height: 80px;
  object-fit: contain;
`;
export const WishTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  text-transform: capitalize;
`;
export const WishTr = styled.tr``;
export const WishTh = styled.th`
  background-color: #f2f2f2;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;

  font-size: 12px;
  font-weight: 600;
  color: #555252;
`;

export const Wishtd = styled.td`
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;

  font-size: 13px;
  color: #555252;

  span {
    margin-right: 8px;
  }
`;

export const MarkIcon = styled.i`
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  color: black;
`;
export const TrashDiv = styled.div`
  width: 25px;
  height: 25px;
  background-color: whitesmoke;
  border-radius: 50%;
  display: grid;
  place-items: center;
`;

export const TrashIcon = styled.i`
  cursor: pointer;
  font-size: 13px;
  /* font-weight: 700; */
  /* color: black; */
`;

export const WishFigure = styled.td`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const WishFigCaption = styled.td``;
