import React from "react";
import { getText } from "../../apiUrls";
import { useNavigate, useParams } from "react-router-dom";
import {
  BlogCategory,
  BlogDate,
  BlogDescription,
  BlogDetails,
  BlogSection,
  CategoriesDiv,
  CategoryName,
  MoreBlog,
  MoreBlogDesc,
  MoreBlogTitle,
  MoreBlogs,
  MoreBlogsDiv,
  MoreCategoriesDiv,
  SingleBlog,
  SingleBlogImg,
  SingleBlogImgDiv,
  SingleBlogTitle,
} from "./BlogsElements";
import { scrollToTop } from "../Home/Home";
import { useBlogQuery, useCategoriesQuery } from "../../queries/api";
import { useState } from "react";
import { useEffect } from "react";

const Blog = () => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [blog, setBlog] = useState(null);

  // BLOG ID FROM PARAMS
  const { blogId } = useParams();

  // REDIRECT FEATURES
  const handleRedirect = (page) => {
    scrollToTop();
    navigate(page);
  };

  // FETCHING API DATA
  const { data: blogs } = useBlogQuery();
  const { data: categories, isLoading: cateLoading } = useCategoriesQuery();

  // FIND BLOG FROM BLOGS
  useEffect(() => {
    const blog = blogs?.find((blog) => blog.id === +blogId);
    setBlog(blog);
  }, [blogs, blogId]);

  return (
    <BlogSection>
      <SingleBlog>
        <SingleBlogTitle>{blog?.title?.rendered}</SingleBlogTitle>
        <SingleBlogImgDiv>
          <SingleBlogImg src={blog?.additional_data_values?.image_url} />
        </SingleBlogImgDiv>
        <BlogDetails>
          <BlogDate style={{ fontSize: "14px" }}>01/01/1111</BlogDate>
          <BlogCategory>Home</BlogCategory>
        </BlogDetails>
        <BlogDescription>{getText(blog?.content?.rendered)}</BlogDescription>
      </SingleBlog>
      <MoreBlogsDiv>
        <MoreBlogs>
          <h3 style={{ color: "green" }}>More Blogs</h3>
          {blogs?.slice(0, 3)?.map((item, i) => {
            return (
              <MoreBlog>
                <MoreBlogTitle
                  onClick={() => handleRedirect(`/blog/${item.id}`)}
                >
                  {item?.title?.rendered}
                </MoreBlogTitle>
                <MoreBlogDesc>
                  {getText(item?.content?.rendered)
                    .split(" ")
                    ?.splice(0, 4)
                    .join("")}
                </MoreBlogDesc>
              </MoreBlog>
            );
          })}
        </MoreBlogs>
        <MoreCategoriesDiv>
          <h3 style={{ color: "green" }}>Categories</h3>
          {!cateLoading &&
            categories.slice(0, 4)?.map((item, i) => (
              <CategoriesDiv>
                <CategoryName
                  onClick={() => {
                    scrollToTop();
                    navigate(`/${item?.name}/${item.id}/products`);
                  }}
                >
                  {item?.name}
                </CategoryName>
              </CategoriesDiv>
            ))}
        </MoreCategoriesDiv>
      </MoreBlogsDiv>
    </BlogSection>
  );
};

export default Blog;
