import { useQuery } from "@tanstack/react-query";
import {
  backendUrl,
  consumerKey,
  consumerSecret,
  products_api,
  categories_api,
  cart_gid_api,
  cart_uid_api,
  address_api,
  users_api,
  wishlist_api,
  reviews_api,
  news_api,
} from "../apiUrls";

const fetchProducts = async () => {
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const productsResponse = await fetch(`${backendUrl}${products_api}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  const productsData = await productsResponse.json();
  return productsData;
};

const fetchCategories = async () => {
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const categoriesResponse = await fetch(`${backendUrl}${categories_api}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  const categoriesData = await categoriesResponse.json();
  return categoriesData;
};


const fetchCart = async () => {
  const guestId = localStorage.getItem("cartId");
  const uIdString = localStorage.getItem("userId");
  const userId = uIdString === "null" ? null : parseFloat(uIdString);
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const endpoint = !userId
    ? `${cart_gid_api}/${guestId}`
    : `${cart_uid_api}/${userId}`;
  const cartResponse = await fetch(`${backendUrl}${endpoint}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  const cartData = await cartResponse.json();
  return cartData;
};

const fetchAddress = async () => {
  const uIdString = localStorage.getItem("userId");
  const userId = uIdString === "null" ? null : parseFloat(uIdString);

  if (!userId) {
    return [];
  }

  const addressResponse = await fetch(
    `${backendUrl}${address_api}/list/all?user_id=${userId}`
  );
  const addressData = await addressResponse.json();
  if (!addressData) {
    return [];
  }
  return addressData;
};

const fetchUsers = async () => {
  const uIdString = localStorage.getItem("userId");
  const userId = uIdString === "null" ? null : parseFloat(uIdString);
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  if (!userId) {
    return [];
  }

  const usersResponse = await fetch(`${backendUrl}${users_api}/${userId}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  const usersData = await usersResponse.json();
  return usersData;
};

const fetchSettings = async () => {
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const settingsResponse = await fetch(
    `${backendUrl}/wp-json/wc/site/settings`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
  const settingsData = await settingsResponse.json();
  return settingsData;
};

const fetchFeaturedProducts = async () => {
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const FeaturedResponse = await fetch(
    `${backendUrl}/wp-json/wc/products/featured`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
  const FeaturedData = await FeaturedResponse.json();
  return FeaturedData;
};

const fetchOrders = async () => {
  const userId = localStorage.getItem("userId");
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const ordersUrl = `${backendUrl}/wp-json/wc/v3/orders?customer=${userId}`;
  if (!userId || userId === null) {
    return [];
  }

  const ordersResponse = await fetch(ordersUrl, {
    headers: {
      Authorization: authHeader,
    },
  });

  const ordersData = await ordersResponse.json();
  return ordersData;
};

const fetchWishlist = async () => {
  const userId = localStorage.getItem("userId");
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const wishResponse = await fetch(`${backendUrl}${wishlist_api}/${userId}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  const wishData = await wishResponse.json();
  return wishData;
};

const fetchReviews = async () => {
  const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
  const reviewResponse = await fetch(`${backendUrl}${reviews_api}`, {
    headers: {
      Authorization: authHeader,
    },
  });
  const reviewData = await reviewResponse.json();
  return reviewData;
};

const fetchBlogs = async () => {
  const blogsResponse = await fetch(`${backendUrl}${news_api}`);
  const blogsData = await blogsResponse.json();
  return blogsData;
};

export function useBlogQuery() {
  return useQuery(["blogs"], fetchBlogs);
}

export function useReviewsQuery() {
  return useQuery(["reviews"], fetchReviews);
}

export function useWishlistQuery() {
  return useQuery(["wishlist"], fetchWishlist);
}

export function useOrdersQuery() {
  return useQuery(["orders"], fetchOrders);
}

export function useFeaturedQuery() {
  return useQuery(["featured"], fetchFeaturedProducts);
}

export function useSettingsQuery() {
  return useQuery(["settings"], fetchSettings);
}

export function useUsersQuery() {
  return useQuery(["users"], fetchUsers);
}

export function useAddressQuery() {
  return useQuery(["getaddress"], fetchAddress);
}

export function useCartQuery() {
  return useQuery(["cart"], fetchCart);
}

export function useProductsQuery() {
  return useQuery(["products"], fetchProducts);
}

export function useCategoriesQuery() {
  return useQuery(["categories"], fetchCategories);
}
