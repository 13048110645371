import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";

const useWishList = () => {
  const [wishLoadingBtn, setWishLoadingBtn] = useState(false);
  const [wishId, setWishId] = useState("");
  const queryClient = useQueryClient();

  const addToWishList = async (data) => {
    try {
      setWishLoadingBtn(true);
      const response = await axios.post(
        "https://admin.wiscart.in/wp-json/wc/wishlist/add/products",
        data
      );
      //   localStorage.setItem("wishListId", response.data.cart_data[0].ID);
      const myArray = response?.data?.wishlist_items;
      const lastIndex = myArray?.length - 1;
      const lastItem = myArray[lastIndex];
      setWishId(lastItem?.product_id);

      queryClient.invalidateQueries("wishlist");
      setWishLoadingBtn(false);
    } catch (error) {
      console.error(error);
      setWishLoadingBtn(false);
    }
  };

  
  const deleteWishList = async (data) => {
    try {
      setWishLoadingBtn(true);
      const response = await axios.post(
        "https://admin.wiscart.in/wp-json/wc/wishlist/delete/product/product_key",
        data
      );
      queryClient.invalidateQueries("wishlist");
      setWishLoadingBtn(false);
      console.log("deleted", response);
    } catch (error) {
      console.error(error);
      setWishLoadingBtn(false);
    }
  };

  const mutation = useMutation(addToWishList);
  const mutationDelete = useMutation(deleteWishList);

  return {
    addToWishList: mutation.mutate,
    deleteWishList: mutationDelete.mutate,
    wishLoadingBtn: wishLoadingBtn,
    wishId: wishId,
  };
};

export { useWishList };
