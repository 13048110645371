import Spinner from "../../images/spinner.gif";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import countryCodes from "country-codes-list";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import {
  AddAddressDiv,
  AddAddressForm,
  AddInputDiv,
  AddInputDivs,
  AddNewAddress,
  // AddressBtn, // button
  AddressCancekBtn,
  AddressDiv,
  AddressInput,
  // AddressInputSlct,
  // AddressInputText,
  AddressPoint,
  // AddressSaveBtn, // button
  AddressTextInput,
  AddressTitle,
  AddressType,
  // AddressTypeDiv,
  // AddressTypeLabel,
  AddText,
  ButtonsDiv,
  CheckOutSection,
  Count,
  CountIcon,
  DelivAddress,
  ErrorMessage,
  IsImg,
  // OrderConfirmBtn, // button
  OrderConfirmDiv,
  OrderConfirmText,
  OrderDetails,
  OrderDetailsDesc,
  OrderDetailsTitle,
  OrderPayDiv,
  OrderPayText,
  OrderProduct,
  OrderProductCart,
  OrderProductImg,
  OrderSummeryDate,
  OrderSummeryDiv,
  OrderSummeryDivs,
  OwnAddress,
  OwnAddressDiv,
  // OwnAddressText,
  PaymentMethods,
  PayMethod,
  PlusIcon,
  // PriceDetails,
  PriceDetailsDiv,
  PriceDetailses,
  PriceDetailsSection,
  PriceDetailsTitle,
  Pricetext,
  PriceTotal,
  ProductCount,
  RemoveBtn,
} from "./CheckOutElements";
import {
  AddressHead,
  AddressNumber,
  AddressText,
  // Container,
  // CountryCodeSelect,
  SelectAddress,
} from "../MyProfile/MyProfileElements";
import { useAddOrder } from "../../hook/useAddOrder";
import { useAddToCart } from "../../hook/useAddToCart";
import { useAddAddress } from "../../hook/useAddAddress";
import { backendUrl, consumerKey, consumerSecret } from "../../apiUrls";
import axios from "axios";
import {
  useAddressQuery,
  useCartQuery,
  useUsersQuery,
} from "../../queries/api";
import { CartProdPrice, PriceDetailsResponse } from "../Cart/CartElements";
import OrderComplete from "../OrderComplete/OrderComplete";
import { Overlay } from "../Navbar/NavbarElements";
import OrderCompletePage from "../../pages/OrderCompletePage";
import { scrollToTop } from "../Home/Home";
import Button from "../Button";
import PriceDetails from "../Cart/PriceDetails";

// const KEY = process.env.REACT_APP_STRIPE;

export const PriceDetailsRes = ({ data, quantity }) => {
  // FETCHING API DATA
  const { data: cartList, error: cartErr, isLoading: cartLdg } = useCartQuery();

  // CART TOTAL
  const cartTotal = !cartLdg && cartList?.cart_total && cartList?.cart_total[0];
  return (
    <>
      {data ? (
        <PriceDetailsSection>
          <PriceDetailsTitle>Price Details</PriceDetailsTitle>

          <PriceDetailsDiv>
            <PriceDetailses>
              <Pricetext>Price ({quantity} Quantity)</Pricetext>

              <PriceTotal>&#8377; {data?.regular_price * quantity}</PriceTotal>
            </PriceDetailses>
            <PriceDetailses>
              <Pricetext>Delivery Charges</Pricetext>
              <PriceTotal>Free</PriceTotal>
            </PriceDetailses>
            <PriceDetailses>
              <Pricetext>Total Payable</Pricetext>

              <PriceTotal>&#8377; {data?.sale_price * quantity}</PriceTotal>
            </PriceDetailses>
          </PriceDetailsDiv>
        </PriceDetailsSection>
      ) : (
        <PriceDetailsSection>
          <PriceDetailsTitle>Price Details</PriceDetailsTitle>
          {!cartLdg && !cartErr && (
            <PriceDetailsDiv>
              <PriceDetailses>
                <Pricetext>
                  Price ({cartList?.cart_items?.length} items)
                </Pricetext>

                <PriceTotal>&#8377; {cartTotal?.cart_total}</PriceTotal>
              </PriceDetailses>
              <PriceDetailses>
                <Pricetext>Delivery Charges</Pricetext>
                <PriceTotal>Free</PriceTotal>
              </PriceDetailses>
              <PriceDetailses>
                <Pricetext>Total Payable</Pricetext>

                <PriceTotal>&#8377; {cartTotal?.subtotal}</PriceTotal>
              </PriceDetailses>
            </PriceDetailsDiv>
          )}
        </PriceDetailsSection>
      )}
    </>
  );
};

const CheckOut = () => {
  const navigate = useNavigate();

  // USER ID FROM LOCAL STORAGE
  const uIdString = localStorage.getItem("userId");
  const userId = uIdString === "null" ? null : parseFloat(uIdString);

  // PRODUCTID ID FROM PARAMS
  const { productId } = useParams();

  // STATE HOOKS
  const [changeQty, setChangeQty] = useState(1);
  const [isCartUpdate, setIsCartUpdate] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [currentCartId, setCurrentCartId] = useState(null);

  const [clickBuy, setClickBuy] = useState(false);
  const [showAddress, setShowAddress] = useState(true);
  const [showMethods, setShowMethods] = useState(false);
  const [orderSummery, setOrderSummery] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [buyOne, setBuyOne] = useState(null);
  // const [image, setImage] = useState("loading");
  // const [stripeToken, setStripeToken] = useState(null);

  // const [isHomeOrWork, setIsHomeOrWork] = useState("");
  // const [clickCod, setClickCod] = useState(false);
  const [formData, setFormData] = useState({
    user_id: userId,
    address_line_1: "",
    address_line_2: "",
    state: "",
    country: "",
    city: "",
    default_address: 1,
    zipcode: "",
    contact_number: "",
    first_name: "",
    last_name: "",
  });

  // FETCHING API DATA
  const { data: cartList } = useCartQuery();
  const { data: userProfile } = useUsersQuery();
  const { data: userAddress, isLoading: addressLoading } = useAddressQuery();

  // IMPORTED HOOKS FUNCTIONS
  const { updateCart, deleteCart, cartLoadingBtn } = useAddToCart();
  const { addAddress, addressLodingBtn } = useAddAddress();
  const { addOrder, orderLoadingBtn, orderSuccess } = useAddOrder();

  // FUNCTION TO FETCH PRODUCT DETAILS
  const fetchProductDetail = async (id) => {
    try {
      const response = await axios.get(
        `${backendUrl}wp-json/wc/v3/products/${id}`,
        {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (productId) {
        const productData = await fetchProductDetail(productId);
        if (productData) {
          setBuyOne(productData);
          // setImage(productData.images[0]?.src || "image-not-found.jpg");
        }
      }
    };

    fetchData();

    return () => {
      // Cleanup function to cancel any ongoing requests
    };
  }, [productId]);

  // FUNCTION TO HANDLE COUNTRY CODE
  const handleCountryCodeChange = (value) => {
    setFormData({
      ...formData,
      contact_number: value,
    });
  };

  // FUNCTION TO HANDLE INPUT CHANGES
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!addressLodingBtn) {
      setClickBuy(false);
      setRequiredFields([]);
    }
  }, [addressLodingBtn]);

  // FUNCTION TO ADD ADDRESS
  const handleAddAddress = (e) => {
    e.preventDefault();

    const requiredFieldsList = [
      // "user_id",
      "first_name",
      "last_name",
      "zipcode",
      "contact_number",
      "address_line_1",
      "city",
      "state",
    ];

    const emptyFields = requiredFieldsList.filter((field) => {
      const fieldValue = formData[field];
      return typeof fieldValue !== "string" || fieldValue.trim() === "";
    });

    setRequiredFields(emptyFields);
    if (emptyFields.length > 0) return;

    addAddress(formData);
    setClickBuy(false);
  };

  // FUNCITON TO CANCEL ADDRESS FIELDS
  const handleCancel = (e) => {
    setFormData({
      user_id: userId,
      address_line_1: "",
      address_line_2: "",
      state: "",
      country: "",
      city: "",
      default_address: 1,
      zipcode: "",
      contact_number: "",
      first_name: "",
      last_name: "",
    });
    setClickBuy(false);
    setRequiredFields([]);
  };

  // FUNCTION TO PLACE ORDERS
  const handleAddOrder = async () => {
    if (orderLoadingBtn) {
      return null;
    }

    if (!selectedAddress) {
      setClickBuy(true);
      return;
    }

    if (!paymentMethods) {
      setShowMethods(true);
      return;
    }

    const {
      first_name,
      last_name,
      address_line_1,
      city,
      state,
      zipcode,
      country,
      contact_number,
    } = selectedAddress;

    const productList = [];
    const cartData = cartList?.cart_items;
    const cartTotal = cartList?.cart_total?.[0]?.subtotal;
    const buyOnePrice = buyOne?.sale_price * changeQty;

    // IF PRODUCT IS NOT FROM CART
    if (buyOne) {
      productList.push({
        product_id: `${buyOne?.id}`,
        quantity: `${changeQty}`,
        variation_id: buyOne?.variation_id ? buyOne?.variation_id : "0",
      });
    } else if (Array.isArray(cartData) && cartData.length > 0) {
      productList.push(
        ...cartData.map(({ product_id, quantity, variation_id }) => ({
          product_id,
          quantity,
          variation_id,
        }))
      );
    } else {
      productList.push({ product_id: null, quantity: null });
    }

    const orderData = {
      customer_id: userId,
      payment_method: "direct_bank_transfer",
      payment_method_title: "Direct Bank Transfer",
      set_paid: true,
      billing: {
        first_name,
        last_name,
        address_1: address_line_1,
        city,
        state,
        postcode: zipcode,
        country,
        email: userProfile?.email,
        phone: contact_number,
      },
      shipping: {
        first_name,
        last_name,
        address_1: address_line_1,
        city,
        state,
        postcode: zipcode,
        country,
      },
      line_items: productList,
      shipping_lines: [
        {
          method_id: "flat_rate",
          method_title: "Flat Rate",
          total: buyOne ? `${buyOnePrice}` : cartTotal,
        },
      ],
    };

    try {
      addOrder(orderData);
      scrollToTop();
      console.log("success", orderData);
    } catch (error) {
      console.log("Error adding order:", error);
    }
  };

  // STRIPE TOKEN
  // const onToken = (token) => {
  //   setStripeToken(token);
  // };

  // FUNCTION TO SET DEFAULT ADDRESS
  const handleSelectAddress = (addressId) => {
    const selected = userAddress?.result?.find(
      (address) => address.ID === addressId
    );
    setSelectedAddress(selected);
  };

  useEffect(() => {
    if (!addressLoading) {
      const defaultAddress = userAddress?.result?.find(
        (address) => address.default_address === "1"
      );
      if (!selectedAddress && defaultAddress) {
        setSelectedAddress(defaultAddress);
      }
    }
  }, [userAddress, selectedAddress, addressLoading]);

  // FUNCTION TO CHANGE PRODUCT QUANTITY
  const handleChangeQty = (condition, value) => {
    const parseQty =
      condition === "up"
        ? parseInt(value.quantity) + 1
        : parseInt(value.quantity) - 1;
    const cartId = value.ID;
    setIsCartUpdate(true);

    if (cartId !== currentCartId) {
      setChangeQty(parseQty);
      setClickCount(0);
      setCurrentCartId(cartId);
    } else {
      if (condition === "up") {
        if (clickCount === 0) {
          setChangeQty(parseQty);
        } else {
          setChangeQty(changeQty + 1);
        }
      } else if (condition === "down" && changeQty > 1) {
        if (clickCount === 0) {
          setChangeQty(parseQty - 1);
        } else {
          setChangeQty(changeQty - 1);
        }
      }
    }
    setClickCount(clickCount + 1);
  };

  // FUNCTION TO UPDATE CART ITEM
  const handleUpdateCart = (item) => {
    const guestId = localStorage.getItem("cartId");
    const data = {
      product_id: item.product_id,
      quantity: changeQty,
      cart_id: guestId,
    };
    updateCart(data);
  };

  // FUNCTION TO DELETE CART ITEM
  const handleDeleteCart = (item) => {
    const guestId = localStorage.getItem("cartId");
    const data = {
      product_key: item.ID,
      cart_id: guestId,
    };
    deleteCart(data);
  };

  const category = buyOne?.categories[0]?.slug;
  const productName = buyOne?.name?.replace(/\s/g, "-");

  return (
    <CheckOutSection>
      {orderSuccess && <OrderCompletePage />}
      <DelivAddress>
        <AddressDiv>
          <AddressTitle onClick={() => setShowAddress(!showAddress)}>
            DELIVERY ADDRESS
          </AddressTitle>
          {showAddress &&
            !addressLoading &&
            userAddress?.result?.map((item, index) => {
              return (
                <OwnAddressDiv key={index}>
                  <OwnAddress>
                    <AddressPoint>
                      <i class="fas fa-duotone fa-location-dot"></i>
                    </AddressPoint>
                    <SelectAddress>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <AddressHead>{item.first_name}</AddressHead>
                        <AddressHead>{item.last_name}</AddressHead>
                      </div>
                      <AddressNumber>{item.contact_number}</AddressNumber>
                      <AddressText>{`${item.address_line_1}, ${item.city}, ${item.zipcode}`}</AddressText>
                    </SelectAddress>
                  </OwnAddress>
                  {selectedAddress?.ID !== item?.ID && (
                    // <AddressBtn onClick={() => handleSelectAddress(item?.ID)}>
                    //   Deliver Here
                    // </AddressBtn> // button
                    <Button
                      style={{
                        minWidth: "6rem",
                        maxWidth: "6rem",
                        height: "2rem",
                        margin: "1rem 0 1rem 1rem",
                      }}
                      mediaSize={"800px"}
                      onClick={() => handleSelectAddress(item?.ID)}
                      title={"Deliver Here"}
                    />
                  )}
                </OwnAddressDiv>
              );
            })}
          <AddNewAddress onClick={() => setClickBuy(!clickBuy)}>
            <PlusIcon>+</PlusIcon>
            <AddText>Add a new address</AddText>
          </AddNewAddress>
          {clickBuy && (
            <AddAddressDiv>
              <AddAddressForm>
                <AddInputDivs>
                  <AddInputDiv>
                    <AddressInput
                      type="text"
                      onChange={handleChange}
                      value={formData.first_name}
                      placeholder="First Name"
                      name="first_name"
                      id=""
                    />
                    {requiredFields.includes("first_name") && (
                      <ErrorMessage>
                        Please provide your first name
                      </ErrorMessage>
                    )}
                  </AddInputDiv>
                  <AddInputDiv>
                    <AddressInput
                      type="text"
                      onChange={handleChange}
                      value={formData.last_name}
                      placeholder="Last Name"
                      name="last_name"
                      id=""
                    />
                    {requiredFields.includes("last_name") && (
                      <ErrorMessage>Please provide your last name</ErrorMessage>
                    )}
                  </AddInputDiv>
                </AddInputDivs>
                <AddInputDivs>
                  <AddInputDiv>
                    <AddressInput
                      type="number"
                      onChange={handleChange}
                      value={formData.zipcode}
                      placeholder="Pincode"
                      name="zipcode"
                      id=""
                    />
                    {requiredFields.includes("zipcode") && (
                      <ErrorMessage>Please provide your pincode</ErrorMessage>
                    )}
                  </AddInputDiv>
                  {/* <Container style={{ width: "100%" }}>
                    <CountryCodeSelect
                      value={selectedCountryCode}
                      onChange={handleCountryCodeChange}
                    >
                      <option value="">Select</option>
                      {Object.entries(
                        countryCodes.customList(
                          "countryCode",
                          "+{countryCallingCode}"
                        )
                      ).map(([code, name]) => (
                        <option key={code} value={name}>
                          {`${name}`}
                        </option>
                      ))}
                    </CountryCodeSelect>
                    <AddInputDiv>
                      <AddressInput
                        type="number"
                        onChange={handleChange}
                        value={formData.contact_number}
                        placeholder="Mobile Number"
                        name="contact_number"
                        id=""
                      />
                      {requiredFields.includes("contact_number") && (
                        <ErrorMessage>
                          Please provide your mobile number
                        </ErrorMessage>
                      )}
                    </AddInputDiv>
                  </Container> */}
                  <AddInputDiv>
                    <PhoneInput
                      className="flag-border-checkout"
                      value={formData.contact_number}
                      onChange={handleCountryCodeChange}
                      placeholder="Mobile Number"
                      name="contact_number"
                      id=""
                    />
                    {requiredFields.includes("contact_number") && (
                      <ErrorMessage>
                        Please provide your mobile number
                      </ErrorMessage>
                    )}
                  </AddInputDiv>
                </AddInputDivs>
                <AddInputDivs>
                  <AddInputDiv>
                    <AddressTextInput
                      type="text"
                      onChange={handleChange}
                      value={formData.address_line_1}
                      placeholder="Address"
                      name="address_line_1"
                      id=""
                    />
                    {requiredFields.includes("address_line_1") && (
                      <ErrorMessage>Please provide your address</ErrorMessage>
                    )}
                  </AddInputDiv>
                </AddInputDivs>
                <AddInputDivs>
                  <AddInputDiv>
                    <AddressInput
                      type="text"
                      onChange={handleChange}
                      value={formData.city}
                      placeholder="City/Destrict/Town"
                      name="city"
                      id=""
                    />
                    {requiredFields.includes("city") && (
                      <ErrorMessage>Please provide your city</ErrorMessage>
                    )}
                  </AddInputDiv>
                  <AddInputDiv>
                    <AddressInput
                      type="text"
                      onChange={handleChange}
                      value={formData.state}
                      placeholder="State"
                      name="state"
                      id=""
                    />
                    {requiredFields.includes("state") && (
                      <ErrorMessage>Please provide your state</ErrorMessage>
                    )}
                  </AddInputDiv>
                </AddInputDivs>
              </AddAddressForm>
              <AddressType>
                {/* <AddressTypeLabel htmlFor="">Address Type</AddressTypeLabel>{" "}
                <br />
                <AddressTypeDiv>
                  <AddressInputSlct
                    type="radio"
                    value="Home"
                    checked={isHomeOrWork === "Home"}
                    onClick={(e) => setIsHomeOrWork(e.target.value)}
                  />
                  <AddressInputText htmlFor="">Home</AddressInputText>
                  <AddressInputSlct
                    type="radio"
                    value="Work"
                    checked={isHomeOrWork === "Work"}
                    onClick={(e) => setIsHomeOrWork(e.target.value)}
                  />
                  <AddressInputText htmlFor="">Work</AddressInputText>
                </AddressTypeDiv> */}
                <ButtonsDiv>
                  {/* <AddressSaveBtn
                    onClick={!addressLodingBtn ? handleAddAddress : undefined}
                  >
                    {addressLodingBtn ? (
                      <img className="button-loading" src={Spinner} alt="" />
                    ) : (
                      "Save and Deliver Here"
                    )}
                  </AddressSaveBtn> button*/}
                  <Button
                    onClick={!addressLodingBtn ? handleAddAddress : undefined}
                    mediaSize={"800px"}
                    title={
                      addressLodingBtn ? (
                        <img className="button-loading" src={Spinner} alt="" />
                      ) : (
                        "Save and Deliver Here"
                      )
                    }
                    style={{
                      display: "flex",
                      width: "200px",
                      height: "40px",
                      borderRadius: "20px",
                      border: "none",
                      marginRight: "1rem",
                      fontSize: "13px",
                    }}
                  />
                  <AddressCancekBtn type="submit" onClick={handleCancel}>
                    Cancel
                  </AddressCancekBtn>
                </ButtonsDiv>
              </AddressType>
            </AddAddressDiv>
          )}
        </AddressDiv>
        <OrderPayDiv>
          <OrderPayText onClick={() => setOrderSummery(!orderSummery)}>
            ORDER SUMMARY
          </OrderPayText>
          {
            orderSummery && (
              <>
                {
                  cartList?.cart_items?.map((item, index) => {
                    const body = item.product_data.body;
                    const parseQty = parseInt(item.quantity);
                    const productData = JSON.parse(body);
                    const category = productData?.categories[0]?.slug;
                    const productName = productData?.name.replace(/\s/g, "-");
                    const isImg = productData?.images.length > 0;

                    const handleNavigate = () =>
                      navigate(
                        `/product/${category}/${productName}/${productData?.parent_id || productData?.id
                        }`
                      );
                    return (
                      <OrderSummeryDivs key={index}>
                        <OrderSummeryDiv>
                          <OrderProduct>
                            {isImg ? (
                              <OrderProductImg
                                onClick={handleNavigate}
                                src={productData?.images[0]?.src}
                                alt="..."
                              />
                            ) : (
                              <IsImg onClick={handleNavigate} />
                            )}
                            <OrderDetails>
                              <OrderDetailsTitle>
                                {item?.product_name
                                  ?.split("")
                                  .slice(0, 15)
                                  .join("")}
                                ...
                              </OrderDetailsTitle>
                              {productData.attributes.map((attribute, i) => (
                                <CartProdPrice>
                                  {attribute.name} : {attribute.option}
                                </CartProdPrice>
                              ))}
                              {/* <OrderDetailsDesc>
                              {productData.sku}
                            </OrderDetailsDesc> */}
                            </OrderDetails>
                          </OrderProduct>
                          <OrderProductCart>
                            <ProductCount>
                              <CountIcon
                                onClick={() => handleChangeQty("down", item)}
                              >
                                -
                              </CountIcon>
                              <Count>
                                {item.ID === currentCartId
                                  ? changeQty
                                  : item.quantity}
                              </Count>

                              <CountIcon
                                onClick={() => handleChangeQty("up", item)}
                              >
                                +
                              </CountIcon>
                            </ProductCount>
                            {changeQty !== parseQty &&
                              item.ID === currentCartId &&
                              isCartUpdate ? (
                              <RemoveBtn
                                onClick={() =>
                                  !cartLoadingBtn && handleUpdateCart(item)
                                }
                              >
                                {cartLoadingBtn && item.ID === currentCartId
                                  ? "Updating"
                                  : "Update"}
                              </RemoveBtn>
                            ) : (
                              <RemoveBtn
                                onClick={() =>
                                  !cartLoadingBtn && handleDeleteCart(item)
                                }
                              >
                                {cartLoadingBtn && item.ID === currentCartId
                                  ? "Removing"
                                  : "Remove"}
                              </RemoveBtn>
                            )}
                          </OrderProductCart>
                        </OrderSummeryDiv>
                        <OrderSummeryDate>
                          Delivery by sat jan 21
                        </OrderSummeryDate>
                      </OrderSummeryDivs>
                    );
                  })
                }
              </>
            )
          }
          <OrderPayText onClick={() => setShowMethods(!showMethods)}>
            PAYMENT OPTIONS
          </OrderPayText>
          {showMethods && (
            <PaymentMethods>
              <PayMethod>
                <input
                  type="radio"
                  value="stripe"
                  checked={paymentMethods === "stripe"}
                  onClick={(e) => setPaymentMethods(e.target.value)}
                  style={{ marginRight: "1rem" }}
                />
                Net Banking
              </PayMethod>
              <PayMethod>
                <input
                  type="radio"
                  value="cod"
                  checked={paymentMethods === "cod"}
                  onClick={(e) => setPaymentMethods(e.target.value)}
                  style={{ marginRight: "1rem" }}
                />
                COD
              </PayMethod>
            </PaymentMethods>
          )}
        </OrderPayDiv>
      </DelivAddress>
      {/* ---------- */}

      <PriceDetailsResponse>
        <PriceDetails
          // data={buyOne}
          // quantity={changeQty}
          isCheckout={handleAddOrder}
        />
        {/* <PriceDetailsRes data={buyOne} quantity={changeQty} /> */}
      </PriceDetailsResponse>
    </CheckOutSection>
  );
};

export default CheckOut;


// export const PriceDetailsRes = ({ data, quantity }) => {
//   // FETCHING API DATA
//   const { data: cartList, error: cartErr, isLoading: cartLdg } = useCartQuery();

//   // CART TOTAL
//   const cartTotal = !cartLdg && cartList?.cart_total && cartList?.cart_total[0];
//   return (
//     <>
//       {data ? (
//         <PriceDetailsSection>
//           <PriceDetailsTitle>Price Details</PriceDetailsTitle>

//           <PriceDetailsDiv>
//             <PriceDetailses>
//               <Pricetext>Price ({quantity} Quantity)</Pricetext>

//               <PriceTotal>&#8377; {data?.regular_price * quantity}</PriceTotal>
//             </PriceDetailses>
//             <PriceDetailses>
//               <Pricetext>Delivery Charges</Pricetext>
//               <PriceTotal>Free</PriceTotal>
//             </PriceDetailses>
//             <PriceDetailses>
//               <Pricetext>Total Payable</Pricetext>

//               <PriceTotal>&#8377; {data?.sale_price * quantity}</PriceTotal>
//             </PriceDetailses>
//           </PriceDetailsDiv>
//         </PriceDetailsSection>
//       ) : (
//         <PriceDetailsSection>
//           <PriceDetailsTitle>Price Details</PriceDetailsTitle>
//           {!cartLdg && !cartErr && (
//             <PriceDetailsDiv>
//               <PriceDetailses>
//                 <Pricetext>
//                   Price ({cartList?.cart_items?.length} items)
//                 </Pricetext>

//                 <PriceTotal>&#8377; {cartTotal?.cart_total}</PriceTotal>
//               </PriceDetailses>
//               <PriceDetailses>
//                 <Pricetext>Delivery Charges</Pricetext>
//                 <PriceTotal>Free</PriceTotal>
//               </PriceDetailses>
//               <PriceDetailses>
//                 <Pricetext>Total Payable</Pricetext>

//                 <PriceTotal>&#8377; {cartTotal?.subtotal}</PriceTotal>
//               </PriceDetailses>
//             </PriceDetailsDiv>
//           )}
//         </PriceDetailsSection>
//       )}
//     </>
//   );
// };

// const CheckOut = () => {
//   const navigate = useNavigate();

//   // USER ID FROM LOCAL STORAGE
//   const uIdString = localStorage.getItem("userId");
//   const userId = uIdString === "null" ? null : parseFloat(uIdString);

//   // PRODUCTID ID FROM PARAMS
//   const { productId } = useParams();

//   // STATE HOOKS
//   const [changeQty, setChangeQty] = useState(1);
//   const [isCartUpdate, setIsCartUpdate] = useState(false);
//   const [clickCount, setClickCount] = useState(0);
//   const [currentCartId, setCurrentCartId] = useState(null);

//   const [clickBuy, setClickBuy] = useState(false);
//   const [showAddress, setShowAddress] = useState(true);
//   const [showMethods, setShowMethods] = useState(false);
//   const [orderSummery, setOrderSummery] = useState(true);
//   const [selectedAddress, setSelectedAddress] = useState();
//   const [paymentMethods, setPaymentMethods] = useState(null);
//   const [requiredFields, setRequiredFields] = useState([]);
//   const [buyOne, setBuyOne] = useState(null);
//   // const [image, setImage] = useState("loading");
//   // const [stripeToken, setStripeToken] = useState(null);

//   // const [isHomeOrWork, setIsHomeOrWork] = useState("");
//   // const [clickCod, setClickCod] = useState(false);
//   const [formData, setFormData] = useState({
//     user_id: userId,
//     address_line_1: "",
//     address_line_2: "",
//     state: "",
//     country: "",
//     city: "",
//     default_address: 1,
//     zipcode: "",
//     contact_number: "",
//     first_name: "",
//     last_name: "",
//   });

//   // FETCHING API DATA
//   const { data: cartList } = useCartQuery();
//   const { data: userProfile } = useUsersQuery();
//   const { data: userAddress, isLoading: addressLoading } = useAddressQuery();

//   // IMPORTED HOOKS FUNCTIONS
//   const { updateCart, deleteCart, cartLoadingBtn } = useAddToCart();
//   const { addAddress, addressLodingBtn } = useAddAddress();
//   const { addOrder, orderLoadingBtn, orderSuccess } = useAddOrder();

//   // FUNCTION TO FETCH PRODUCT DETAILS
//   const fetchProductDetail = async (id) => {
//     try {
//       const response = await axios.get(
//         `${backendUrl}wp-json/wc/v3/products/${id}`,
//         {
//           auth: {
//             username: consumerKey,
//             password: consumerSecret,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       return null;
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       if (productId) {
//         const productData = await fetchProductDetail(productId);
//         if (productData) {
//           setBuyOne(productData);
//           // setImage(productData.images[0]?.src || "image-not-found.jpg");
//         }
//       }
//     };

//     fetchData();

//     return () => {
//       // Cleanup function to cancel any ongoing requests
//     };
//   }, [productId]);

//   // FUNCTION TO HANDLE COUNTRY CODE
//   const handleCountryCodeChange = (value) => {
//     setFormData({
//       ...formData,
//       contact_number: value,
//     });
//   };

//   // FUNCTION TO HANDLE INPUT CHANGES
//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   useEffect(() => {
//     if (!addressLodingBtn) {
//       setClickBuy(false);
//       setRequiredFields([]);
//     }
//   }, [addressLodingBtn]);

//   // FUNCTION TO ADD ADDRESS
//   const handleAddAddress = (e) => {
//     e.preventDefault();

//     const requiredFieldsList = [
//       // "user_id",
//       "first_name",
//       "last_name",
//       "zipcode",
//       "contact_number",
//       "address_line_1",
//       "city",
//       "state",
//     ];

//     const emptyFields = requiredFieldsList.filter((field) => {
//       const fieldValue = formData[field];
//       return typeof fieldValue !== "string" || fieldValue.trim() === "";
//     });

//     setRequiredFields(emptyFields);
//     if (emptyFields.length > 0) return;

//     addAddress(formData);
//     setClickBuy(false);
//   };

//   // FUNCITON TO CANCEL ADDRESS FIELDS
//   const handleCancel = (e) => {
//     setFormData({
//       user_id: userId,
//       address_line_1: "",
//       address_line_2: "",
//       state: "",
//       country: "",
//       city: "",
//       default_address: 1,
//       zipcode: "",
//       contact_number: "",
//       first_name: "",
//       last_name: "",
//     });
//     setClickBuy(false);
//     setRequiredFields([]);
//   };

//   // FUNCTION TO PLACE ORDERS
//   const handleAddOrder = async () => {
//     if (orderLoadingBtn) {
//       return null;
//     }

//     if (!selectedAddress) {
//       setClickBuy(true);
//       return;
//     }

//     if (!paymentMethods) {
//       setShowMethods(true);
//       return;
//     }

//     const {
//       first_name,
//       last_name,
//       address_line_1,
//       city,
//       state,
//       zipcode,
//       country,
//       contact_number,
//     } = selectedAddress;

//     const productList = [];
//     const cartData = cartList?.cart_items;
//     const cartTotal = cartList?.cart_total?.[0]?.subtotal;
//     const buyOnePrice = buyOne?.sale_price * changeQty;

//     // IF PRODUCT IS NOT FROM CART
//     if (buyOne) {
//       productList.push({
//         product_id: `${buyOne?.id}`,
//         quantity: `${changeQty}`,
//         variation_id: buyOne?.variation_id ? buyOne?.variation_id : "0",
//       });
//     } else if (Array.isArray(cartData) && cartData.length > 0) {
//       productList.push(
//         ...cartData.map(({ product_id, quantity, variation_id }) => ({
//           product_id,
//           quantity,
//           variation_id,
//         }))
//       );
//     } else {
//       productList.push({ product_id: null, quantity: null });
//     }

//     const orderData = {
//       customer_id: userId,
//       payment_method: "direct_bank_transfer",
//       payment_method_title: "Direct Bank Transfer",
//       set_paid: true,
//       billing: {
//         first_name,
//         last_name,
//         address_1: address_line_1,
//         city,
//         state,
//         postcode: zipcode,
//         country,
//         email: userProfile?.email,
//         phone: contact_number,
//       },
//       shipping: {
//         first_name,
//         last_name,
//         address_1: address_line_1,
//         city,
//         state,
//         postcode: zipcode,
//         country,
//       },
//       line_items: productList,
//       shipping_lines: [
//         {
//           method_id: "flat_rate",
//           method_title: "Flat Rate",
//           total: buyOne ? `${buyOnePrice}` : cartTotal,
//         },
//       ],
//     };

//     try {
//       addOrder(orderData);
//       scrollToTop();
//       console.log("success", orderData);
//     } catch (error) {
//       console.log("Error adding order:", error);
//     }
//   };

//   // STRIPE TOKEN
//   // const onToken = (token) => {
//   //   setStripeToken(token);
//   // };

//   // FUNCTION TO SET DEFAULT ADDRESS
//   const handleSelectAddress = (addressId) => {
//     const selected = userAddress?.result?.find(
//       (address) => address.ID === addressId
//     );
//     setSelectedAddress(selected);
//   };

//   useEffect(() => {
//     if (!addressLoading) {
//       const defaultAddress = userAddress?.result?.find(
//         (address) => address.default_address === "1"
//       );
//       if (!selectedAddress && defaultAddress) {
//         setSelectedAddress(defaultAddress);
//       }
//     }
//   }, [userAddress, selectedAddress, addressLoading]);

//   // FUNCTION TO CHANGE PRODUCT QUANTITY
//   const handleChangeQty = (condition, value) => {
//     const parseQty =
//       condition === "up"
//         ? parseInt(value.quantity) + 1
//         : parseInt(value.quantity) - 1;
//     const cartId = value.ID;
//     setIsCartUpdate(true);

//     if (cartId !== currentCartId) {
//       setChangeQty(parseQty);
//       setClickCount(0);
//       setCurrentCartId(cartId);
//     } else {
//       if (condition === "up") {
//         if (clickCount === 0) {
//           setChangeQty(parseQty);
//         } else {
//           setChangeQty(changeQty + 1);
//         }
//       } else if (condition === "down" && changeQty > 1) {
//         if (clickCount === 0) {
//           setChangeQty(parseQty - 1);
//         } else {
//           setChangeQty(changeQty - 1);
//         }
//       }
//     }
//     setClickCount(clickCount + 1);
//   };

//   // FUNCTION TO UPDATE CART ITEM
//   const handleUpdateCart = (item) => {
//     const guestId = localStorage.getItem("cartId");
//     const data = {
//       product_id: item.product_id,
//       quantity: changeQty,
//       cart_id: guestId,
//     };
//     updateCart(data);
//   };

//   // FUNCTION TO DELETE CART ITEM
//   const handleDeleteCart = (item) => {
//     const guestId = localStorage.getItem("cartId");
//     const data = {
//       product_key: item.ID,
//       cart_id: guestId,
//     };
//     deleteCart(data);
//   };

//   const category = buyOne?.categories[0]?.slug;
//   const productName = buyOne?.name?.replace(/\s/g, "-");

//   return (
//     <CheckOutSection>
//       {orderSuccess && <OrderCompletePage />}
//       <DelivAddress>
//         <AddressDiv>
//           <AddressTitle onClick={() => setShowAddress(!showAddress)}>
//             DELIVERY ADDRESS
//           </AddressTitle>
//           {showAddress &&
//             !addressLoading &&
//             userAddress?.result?.map((item, index) => {
//               return (
//                 <OwnAddressDiv key={index}>
//                   <OwnAddress>
//                     <AddressPoint>
//                       <i class="fas fa-duotone fa-location-dot"></i>
//                     </AddressPoint>
//                     <SelectAddress>
//                       <div style={{ display: "flex", gap: "5px" }}>
//                         <AddressHead>{item.first_name}</AddressHead>
//                         <AddressHead>{item.last_name}</AddressHead>
//                       </div>
//                       <AddressNumber>{item.contact_number}</AddressNumber>
//                       <AddressText>{`${item.address_line_1}, ${item.city}, ${item.zipcode}`}</AddressText>
//                     </SelectAddress>
//                   </OwnAddress>
//                   {selectedAddress?.ID !== item?.ID && (
//                     // <AddressBtn onClick={() => handleSelectAddress(item?.ID)}>
//                     //   Deliver Here
//                     // </AddressBtn> // button
//                     <Button
//                       style={{
//                         minWidth: "6rem",
//                         maxWidth: "6rem",
//                         height: "2rem",
//                         margin: "1rem 0 1rem 1rem",
//                       }}
//                       mediaSize={"800px"}
//                       onClick={() => handleSelectAddress(item?.ID)}
//                       title={"Deliver Here"}
//                     />
//                   )}
//                 </OwnAddressDiv>
//               );
//             })}
//           <AddNewAddress onClick={() => setClickBuy(!clickBuy)}>
//             <PlusIcon>+</PlusIcon>
//             <AddText>Add a new address</AddText>
//           </AddNewAddress>
//           {clickBuy && (
//             <AddAddressDiv>
//               <AddAddressForm>
//                 <AddInputDivs>
//                   <AddInputDiv>
//                     <AddressInput
//                       type="text"
//                       onChange={handleChange}
//                       value={formData.first_name}
//                       placeholder="First Name"
//                       name="first_name"
//                       id=""
//                     />
//                     {requiredFields.includes("first_name") && (
//                       <ErrorMessage>
//                         Please provide your first name
//                       </ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                   <AddInputDiv>
//                     <AddressInput
//                       type="text"
//                       onChange={handleChange}
//                       value={formData.last_name}
//                       placeholder="Last Name"
//                       name="last_name"
//                       id=""
//                     />
//                     {requiredFields.includes("last_name") && (
//                       <ErrorMessage>Please provide your last name</ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                 </AddInputDivs>
//                 <AddInputDivs>
//                   <AddInputDiv>
//                     <AddressInput
//                       type="number"
//                       onChange={handleChange}
//                       value={formData.zipcode}
//                       placeholder="Pincode"
//                       name="zipcode"
//                       id=""
//                     />
//                     {requiredFields.includes("zipcode") && (
//                       <ErrorMessage>Please provide your pincode</ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                   {/* <Container style={{ width: "100%" }}>
//                     <CountryCodeSelect
//                       value={selectedCountryCode}
//                       onChange={handleCountryCodeChange}
//                     >
//                       <option value="">Select</option>
//                       {Object.entries(
//                         countryCodes.customList(
//                           "countryCode",
//                           "+{countryCallingCode}"
//                         )
//                       ).map(([code, name]) => (
//                         <option key={code} value={name}>
//                           {`${name}`}
//                         </option>
//                       ))}
//                     </CountryCodeSelect>
//                     <AddInputDiv>
//                       <AddressInput
//                         type="number"
//                         onChange={handleChange}
//                         value={formData.contact_number}
//                         placeholder="Mobile Number"
//                         name="contact_number"
//                         id=""
//                       />
//                       {requiredFields.includes("contact_number") && (
//                         <ErrorMessage>
//                           Please provide your mobile number
//                         </ErrorMessage>
//                       )}
//                     </AddInputDiv>
//                   </Container> */}
//                   <AddInputDiv>
//                     <PhoneInput
//                       className="flag-border-checkout"
//                       value={formData.contact_number}
//                       onChange={handleCountryCodeChange}
//                       placeholder="Mobile Number"
//                       name="contact_number"
//                       id=""
//                     />
//                     {requiredFields.includes("contact_number") && (
//                       <ErrorMessage>
//                         Please provide your mobile number
//                       </ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                 </AddInputDivs>
//                 <AddInputDivs>
//                   <AddInputDiv>
//                     <AddressTextInput
//                       type="text"
//                       onChange={handleChange}
//                       value={formData.address_line_1}
//                       placeholder="Address"
//                       name="address_line_1"
//                       id=""
//                     />
//                     {requiredFields.includes("address_line_1") && (
//                       <ErrorMessage>Please provide your address</ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                 </AddInputDivs>
//                 <AddInputDivs>
//                   <AddInputDiv>
//                     <AddressInput
//                       type="text"
//                       onChange={handleChange}
//                       value={formData.city}
//                       placeholder="City/Destrict/Town"
//                       name="city"
//                       id=""
//                     />
//                     {requiredFields.includes("city") && (
//                       <ErrorMessage>Please provide your city</ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                   <AddInputDiv>
//                     <AddressInput
//                       type="text"
//                       onChange={handleChange}
//                       value={formData.state}
//                       placeholder="State"
//                       name="state"
//                       id=""
//                     />
//                     {requiredFields.includes("state") && (
//                       <ErrorMessage>Please provide your state</ErrorMessage>
//                     )}
//                   </AddInputDiv>
//                 </AddInputDivs>
//               </AddAddressForm>
//               <AddressType>
//                 {/* <AddressTypeLabel htmlFor="">Address Type</AddressTypeLabel>{" "}
//                 <br />
//                 <AddressTypeDiv>
//                   <AddressInputSlct
//                     type="radio"
//                     value="Home"
//                     checked={isHomeOrWork === "Home"}
//                     onClick={(e) => setIsHomeOrWork(e.target.value)}
//                   />
//                   <AddressInputText htmlFor="">Home</AddressInputText>
//                   <AddressInputSlct
//                     type="radio"
//                     value="Work"
//                     checked={isHomeOrWork === "Work"}
//                     onClick={(e) => setIsHomeOrWork(e.target.value)}
//                   />
//                   <AddressInputText htmlFor="">Work</AddressInputText>
//                 </AddressTypeDiv> */}
//                 <ButtonsDiv>
//                   {/* <AddressSaveBtn
//                     onClick={!addressLodingBtn ? handleAddAddress : undefined}
//                   >
//                     {addressLodingBtn ? (
//                       <img className="button-loading" src={Spinner} alt="" />
//                     ) : (
//                       "Save and Deliver Here"
//                     )}
//                   </AddressSaveBtn> button*/}
//                   <Button
//                     onClick={!addressLodingBtn ? handleAddAddress : undefined}
//                     mediaSize={"800px"}
//                     title={
//                       addressLodingBtn ? (
//                         <img className="button-loading" src={Spinner} alt="" />
//                       ) : (
//                         "Save and Deliver Here"
//                       )
//                     }
//                     style={{
//                       display: "flex",
//                       width: "200px",
//                       height: "40px",
//                       borderRadius: "20px",
//                       border: "none",
//                       marginRight: "1rem",
//                       fontSize: "13px",
//                     }}
//                   />
//                   <AddressCancekBtn type="submit" onClick={handleCancel}>
//                     Cancel
//                   </AddressCancekBtn>
//                 </ButtonsDiv>
//               </AddressType>
//             </AddAddressDiv>
//           )}
//         </AddressDiv>
//         <OrderPayDiv>
//           <OrderPayText onClick={() => setOrderSummery(!orderSummery)}>
//             ORDER SUMMARY
//           </OrderPayText>
//           {
//             // orderSummery
//             true && (
//               <>
//                 {buyOne ? (
//                   <OrderSummeryDivs>
//                     <OrderSummeryDiv>
//                       <OrderProduct>
//                         {buyOne?.images[0]?.src ? (
//                           <OrderProductImg
//                             onClick={() =>
//                               navigate(
//                                 `/product/${category}/${productName}/${buyOne?.parent_id || buyOne?.id
//                                 }`
//                               )
//                             }
//                             src={buyOne?.images[0]?.src}
//                             alt="..."
//                           />
//                         ) : (
//                           <IsImg
//                             onClick={() =>
//                               navigate(
//                                 `/product/${category}/${productName}/${buyOne?.parent_id || buyOne?.id
//                                 }`
//                               )
//                             }
//                           />
//                         )}
//                         <OrderDetails>
//                           <OrderDetailsTitle>
//                             {buyOne?.name?.split("").slice(0, 15).join("")}...
//                           </OrderDetailsTitle>
//                           {buyOne?.attributes?.map((attribute, i) => (
//                             <CartProdPrice key={i}>
//                               {attribute.name} : {attribute.option}
//                             </CartProdPrice>
//                           ))}
//                           {/* <OrderDetailsDesc>{buyOne.sku}</OrderDetailsDesc> */}
//                         </OrderDetails>
//                       </OrderProduct>
//                       <OrderProductCart>
//                         <ProductCount>
//                           <CountIcon
//                             onClick={() => {
//                               if (changeQty >= 2) {
//                                 setChangeQty(changeQty - 1);
//                               }
//                             }}
//                           >
//                             -
//                           </CountIcon>
//                           <Count>{changeQty}</Count>
//                           <CountIcon
//                             onClick={() => setChangeQty(changeQty + 1)}
//                           >
//                             +
//                           </CountIcon>
//                         </ProductCount>

//                         <RemoveBtn onClick={() => navigate("/")}>
//                           Remove
//                         </RemoveBtn>
//                       </OrderProductCart>
//                     </OrderSummeryDiv>
//                     <OrderSummeryDate>Delivery by sat jan 21</OrderSummeryDate>
//                   </OrderSummeryDivs>
//                 ) : (
//                   cartList?.cart_items?.map((item, index) => {
//                     const body = item.product_data.body;
//                     const parseQty = parseInt(item.quantity);
//                     const productData = JSON.parse(body);
//                     const category = productData?.categories[0]?.slug;
//                     const productName = productData?.name.replace(/\s/g, "-");
//                     const isImg = productData?.images.length > 0;

//                     const handleNavigate = () =>
//                       navigate(
//                         `/product/${category}/${productName}/${productData?.parent_id || productData?.id
//                         }`
//                       );
//                     return (
//                       <OrderSummeryDivs key={index}>
//                         <OrderSummeryDiv>
//                           <OrderProduct>
//                             {isImg ? (
//                               <OrderProductImg
//                                 onClick={handleNavigate}
//                                 src={productData?.images[0]?.src}
//                                 alt="..."
//                               />
//                             ) : (
//                               <IsImg onClick={handleNavigate} />
//                             )}
//                             <OrderDetails>
//                               <OrderDetailsTitle>
//                                 {item?.product_name
//                                   ?.split("")
//                                   .slice(0, 15)
//                                   .join("")}
//                                 ...
//                               </OrderDetailsTitle>
//                               {productData.attributes.map((attribute, i) => (
//                                 <CartProdPrice>
//                                   {attribute.name} : {attribute.option}
//                                 </CartProdPrice>
//                               ))}
//                               {/* <OrderDetailsDesc>
//                               {productData.sku}
//                             </OrderDetailsDesc> */}
//                             </OrderDetails>
//                           </OrderProduct>
//                           <OrderProductCart>
//                             <ProductCount>
//                               <CountIcon
//                                 onClick={() => handleChangeQty("down", item)}
//                               >
//                                 -
//                               </CountIcon>
//                               <Count>
//                                 {item.ID === currentCartId
//                                   ? changeQty
//                                   : item.quantity}
//                               </Count>

//                               <CountIcon
//                                 onClick={() => handleChangeQty("up", item)}
//                               >
//                                 +
//                               </CountIcon>
//                             </ProductCount>
//                             {changeQty !== parseQty &&
//                               item.ID === currentCartId &&
//                               isCartUpdate ? (
//                               <RemoveBtn
//                                 onClick={() =>
//                                   !cartLoadingBtn && handleUpdateCart(item)
//                                 }
//                               >
//                                 {cartLoadingBtn && item.ID === currentCartId
//                                   ? "Updating"
//                                   : "Update"}
//                               </RemoveBtn>
//                             ) : (
//                               <RemoveBtn
//                                 onClick={() =>
//                                   !cartLoadingBtn && handleDeleteCart(item)
//                                 }
//                               >
//                                 {cartLoadingBtn && item.ID === currentCartId
//                                   ? "Removing"
//                                   : "Remove"}
//                               </RemoveBtn>
//                             )}
//                           </OrderProductCart>
//                         </OrderSummeryDiv>
//                         <OrderSummeryDate>
//                           Delivery by sat jan 21
//                         </OrderSummeryDate>
//                       </OrderSummeryDivs>
//                     );
//                   })
//                 )}

//                 {/* <OrderConfirmDiv>
//                   <OrderConfirmText>
//                     Order confirmation email will be send to{" "}
//                     {userProfile?.email}
//                   </OrderConfirmText>

//                   <Button
//                     title={"Continue"}
//                     disabled={orderLoadingBtn}
//                     mediaSize={"800px"}
//                     onClick={handleAddOrder}
//                     style={{
//                       width: "130px",
//                       height: "35px",
//                       borderRadius: "20px",
//                       border: "none",
//                       fontSize: "13px",
//                     }}
//                     mediaStyles={{
//                       width: "110px",
//                       height: "33px",
//                     }}
//                   />
//                 </OrderConfirmDiv> */}
//               </>
//             )
//           }
//           <OrderPayText onClick={() => setShowMethods(!showMethods)}>
//             PAYMENT OPTIONS
//           </OrderPayText>
//           {showMethods && (
//             <PaymentMethods>
//               <PayMethod>
//                 <input
//                   type="radio"
//                   value="stripe"
//                   checked={paymentMethods === "stripe"}
//                   onClick={(e) => setPaymentMethods(e.target.value)}
//                   style={{ marginRight: "1rem" }}
//                 />
//                 Net Banking
//               </PayMethod>
//               <PayMethod>
//                 <input
//                   type="radio"
//                   value="cod"
//                   checked={paymentMethods === "cod"}
//                   onClick={(e) => setPaymentMethods(e.target.value)}
//                   style={{ marginRight: "1rem" }}
//                 />
//                 COD
//               </PayMethod>
//             </PaymentMethods>
//           )}
//         </OrderPayDiv>
//       </DelivAddress>
//       {/* ---------- */}

//       <PriceDetailsResponse>
//         <PriceDetails
//           // data={buyOne}
//           // quantity={changeQty}
//           isCheckout={handleAddOrder}
//         />
//         {/* <PriceDetailsRes data={buyOne} quantity={changeQty} /> */}
//       </PriceDetailsResponse>
//     </CheckOutSection>
//   );
// };

// export default CheckOut;

// // {/* <div style={{ display: "flex" }}>
// //   <div style={{ marginBottom: "8px", width: "100%" }}>
// //     <input type="text" placeholder="First Name" style={{ width: "100%" }} />
// //     {requiredFields.includes("first_name") && (
// //       <ErrorMessage>Please provide your first name</ErrorMessage>
// //     )}
// //   </div>
// //   <div style={{ marginBottom: "8px", width: "100%" }}>
// //     <input type="text" placeholder="Last Name" style={{ width: "100%" }} />
// //     {requiredFields.includes("last_name") && (
// //       <ErrorMessage>Please provide your last name</ErrorMessage>
// //     )}
// //   </div>
// // </div>; */}
