import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  // AttributeArrow,
  CartCount,
  DeliIcon,
  DeliText,
  Delivery,
  // FavCount,
  Icon,
  IconDiv,
  MainNav,
  // MegaHead,
  // MegaMenu,
  // MegaMenuDiv,
  // MegaSub,
  // MegaSubs,
  // MegaText,
  MenuIco,
  // MobIcon,
  MobNumber,
  Mobile,
  MobileNav,
  MoreBtn,
  Nav,
  NavCart,
  // NavFav,
  NavLink,
  NavLinks,
  Overlay,
  PopUpMenu,
  PopUpSearch,
  SearchIcon,
  // ShopLogo,
  ShopLogoMob,
  ShopLogoWeb,
  Test,
  // ShopName,
  // ShopNameTop,
  TopNav,
  UserDetails,
  UserText,
  WebViewNav,
} from "../Navbar/NavbarElements";
import SearchBar from "../Navbar/SearchBar";
import SearchLogo from "../../images/search.png";
import DeliveryLogo from "../../images/delivery.png";
import WishlistLogo from "../../images/wishlist.png";
import shopLogo from "../../images//logos/logo512.png";
// import Compar from "../../images/compar.png";
import MenuBar from "../Navbar/MenuBar";
import { scrollToTop } from "../Home/Home";

import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import {
  useCartQuery,
  useCategoriesQuery,
  useSettingsQuery,
  useUsersQuery,
  useWishlistQuery,
} from "../../queries/api";

import { SocialIcon } from "../Footer/FooterElement";
import { categories } from "../../apiUrls";

const MegaMen = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`;

const MegaMenuDi = styled.div`
  margin: 0.5rem 1rem;
`;

const MegaHea = styled.div`
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  /* color: #82ba32; */
  /* text-decoration: underline; */
  margin-bottom: 0.5rem;
`;

const MegaSubss = styled.div`
  display: grid;
  padding-left: 1px;
  /* gap: 2px; */
  gap: 10px;
`;

const MegaSu = styled.div`
  cursor: pointer;
`;

const MegaTex = styled.div`
  width: 100%;
  font-weight: 400;
  /* font-size: 13px; */
  font-size: 15px;
  color: #000000;

  &:hover {
    color: #9d9999;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [isMegaHome, setIsMegaHome] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isSearc, setIsSearc] = useState(false);

  // FETCHING API DATA
  const { data: settings, isLoading: settingLdg } = useSettingsQuery();
  const { data: categories, isLoading: categoriesLdg } = useCategoriesQuery();

  const { data: cartList } = useCartQuery();
  const { data: userProfile } = useUsersQuery();

  // SET CART ID TO LOCAL STORAGE
  useEffect(() => {
    if (cartList?.status) {
      localStorage.setItem("cartId", cartList?.cart_data[0]?.ID);
    }
  }, [cartList]);

  // GET USER ID FROM LOCAL STORAGE
  const uIdString = localStorage.getItem("userId");
  const uId = uIdString === "null" ? null : parseFloat(uIdString);

  // FUNCTION TO HANDLE TOGGLE MENU
  const handleToggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    scrollToTop();
  };

  // NAVBAR SCROLL STYLES
  useEffect(() => {
    const handleScroll = () => {
      const header2 = document.querySelector(".header-2");
      setIsSticky(window.scrollY > 43);
      header2.classList.remove("nav-toggle");
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // FILTER CATEGORIES TITLE
  const topLevelCategories =
    !categoriesLdg &&
    categories.filter(
      (category) => category.parent === 0 && category.name !== "Uncategorized"
    );

  // REDIRECT FEATURES
  const handleRedirect = (page) => {
    scrollToTop();
    navigate(page);
    setIsMegaHome(false);
    setIsSearc(false);
  };

  // PATH NAME FROM URL
  const pathname = window.location.pathname;

  useEffect(() => {
    setIsSearc(false);
  }, [pathname]);

  return (
    <>
      <Nav isSticky={isSticky}>
        {/* {(isMegaHome || isSearc) && <Overlay />} */}
        <TopNav pathname={pathname}>
          <MobileNav>
            <MenuIco onClick={handleToggleMenu}>
              <i class="fa-solid fa-bars"></i>
            </MenuIco>
            <MenuBar menuOpen={menuOpen} onCloseMenu={handleToggleMenu} />
            <ShopLogoMob
              onClick={() => {
                navigate("/");
                scrollToTop();
              }}
              src={shopLogo}
              alt="shopLogo"
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => {
                  setIsSearc(!isSearc);
                }}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
              >
                <i className="fa-solid fa-magnifying-glass shop"></i>
              </div>

              <Link
                // to={isCartNull ? "/" : "/cart"}
                to={"/cart"}
                onClick={() => scrollToTop()}
                style={{ textDecoration: "none", color: "black" }}
              >
                <NavCart>
                  <i className="fas fa-light fa-cart-shopping shop"></i>
                  <CartCount>{cartList?.cart_items?.length || 0}</CartCount>
                </NavCart>
              </Link>
            </div>

            {/* {isSearc && (
              <PopUpMenu
                style={{
                  height: "70px",
                  marginTop: "7rem",
                }}
              >
                <SearchBar />
              </PopUpMenu>
            )} */}
            {isSearc && (
              <PopUpSearch>
                <SearchBar />
              </PopUpSearch>
            )}
          </MobileNav>
          <WebViewNav>
            {settingLdg ? (
              <div>
                <Skeleton width={200} height={20} />
              </div>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Mobile style={{ borderRight: "1.5px solid black" }}>
                  <i className="fas fa-regular fa-phone"></i>
                  <MobNumber>{settings["contact-info"].phone}</MobNumber>
                </Mobile>
                <Mobile>
                  <i className="fas fa-regular fa-envelope"></i>
                  <MobNumber>{settings["contact-info"].email}</MobNumber>
                </Mobile>
              </div>
            )}

            <UserDetails>
              <Delivery>
                <DeliIcon src={DeliveryLogo} />
                <DeliText>Delivery</DeliText>
              </Delivery>

              <Link
                // to={uId || !isWishNull ? "/wishlist" : "/login"}
                to={uId ? "/wishlist" : "/login"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <IconDiv>
                  <Icon src={WishlistLogo}></Icon>
                  <UserText>Whislist</UserText>
                </IconDiv>
              </Link>
              {/* <IconDiv>
              <Icon src={Compar} />
              <UserText>Compare</UserText>
            </IconDiv> */}
              {/* {uId ? (
                <SocialIcon
                  onClick={() => {
                    localStorage.removeItem("userId");
                    window.location.reload();
                  }}
                  className="fa-solid fa-right-from-bracket"
                ></SocialIcon>
              ) : null} */}
            </UserDetails>
          </WebViewNav>
        </TopNav>

        <MainNav className={`header-2 ${isSticky ? "sticky" : ""}`}>
          <ShopLogoWeb
            onClick={() => {
              navigate("/");
              scrollToTop();
            }}
            src={shopLogo}
            alt="shopLogo"
          />
          <NavLinks
            style={{
              height: "100%",
              position: "absolute",
              top: "90%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <NavLink onClick={() => handleRedirect("/")}>HOME</NavLink>
            <NavLink
              onMouseOver={() => {
                setIsMegaHome(true);
                setIsSearc(false);
              }}
              onMouseOut={() => setIsMegaHome(false)}
            >
              SHOP <i class="fa-solid fa-caret-down"></i>
            </NavLink>

            {/* <NavLink href="#newarrivals">NEW ARRIVAL</NavLink> */}
            <NavLink onClick={() => handleRedirect("/blogs")}>BLOG</NavLink>
            <NavLink onClick={() => handleRedirect("/contact")}>
              CONTACT US
            </NavLink>
          </NavLinks>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              onClick={() => {
                setIsSearc(!isSearc);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
                color: "#9d9999",
                gap: "5px",
                cursor: "pointer",
                marginRight: "15px",
              }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
              <p>Search</p>
            </div>

            {/* User Profile */}
            {uId ? (
              <>
                <Link
                  to={`/profile/${userProfile?.username}/${uId}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "15px",
                      color: "#9d9999",
                      gap: "5px",
                    }}
                  >
                    <i className="fa-regular fa-user"></i>
                    <p>{userProfile?.username}</p>
                  </div>
                </Link>
                <SocialIcon
                  onClick={() => {
                    localStorage.removeItem("userId");
                    localStorage.removeItem("cartId");
                    localStorage.removeItem("token");
                    window.location.reload();
                  }}
                  className="fa-solid fa-right-from-bracket"
                ></SocialIcon>
              </>
            ) : (
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#9d9999",
                    gap: "5px",
                  }}
                >
                  <i className="fa-regular fa-user"></i>
                  <p>Login</p>
                </div>
              </Link>
            )}

            {/* Cart */}
            <Link
              // to={isCartNull ? "" : "/cart"}
              to={"/cart"}
              style={{ textDecoration: "none", color: "black" }}
            >
              <NavCart>
                <i className="fas fa-light fa-cart-shopping shop"></i>
                <CartCount>{cartList?.cart_items?.length || 0}</CartCount>
              </NavCart>
            </Link>
          </div>

          {isSearc && (
            <PopUpSearch>
              <SearchBar />
            </PopUpSearch>
          )}

          {!categoriesLdg && isMegaHome && (
            <PopUpMenu
              onMouseOver={() => setIsMegaHome(true)}
              onMouseOut={() => setIsMegaHome(false)}
              style={{
                // top: 75px,
                // borderTop: "1px solid #d3d0d0",
                // width: "100%",
                paddingBottom: categories.length > 10 ? "2rem" : "0",
                top: "92px",
                maxHeight: "75vh",

                transform: "translate(-50%)",
                left: "50%",
              }}
            >
              <MegaMen>
                {topLevelCategories?.slice(0, 10).map((category, i) => {
                  const categoryName = category?.name?.toLowerCase();
                  return (
                    <MegaMenuDi
                      style={
                        topLevelCategories.length > 15
                          ? {
                            borderBottom: "1px solid #9d9999",
                            paddingBottom: "8px",
                          }
                          : null
                      }
                      key={i}
                    >
                      <MegaHea
                        onClick={() => {
                          navigate(`/${categoryName}/${category?.id}/products`);
                          setIsMegaHome(false);
                          scrollToTop();
                        }}
                      >
                        {category?.name}
                      </MegaHea>
                      <MegaSubss>
                        {categories
                          ?.filter(
                            (subcategory) => subcategory.parent === category.id
                          )
                          .map((subcategory, index) => {
                            const subCateName =
                              subcategory?.name?.toLowerCase();
                            return (
                              <MegaSu key={index}>
                                <MegaTex
                                  onClick={() => {
                                    navigate(
                                      `/${subCateName}/${subcategory.parent}/products`
                                    );
                                    setIsMegaHome(false);
                                    scrollToTop();
                                  }}
                                >
                                  {subcategory.name}
                                </MegaTex>
                              </MegaSu>
                            );
                          })}
                      </MegaSubss>
                    </MegaMenuDi>
                  );
                })}
              </MegaMen>
              {topLevelCategories.length > 19 && (
                <MoreBtn
                  onClick={() => {
                    navigate("/categories");
                    scrollToTop();
                  }}
                >
                  View All
                </MoreBtn>
              )}
            </PopUpMenu>
          )}
        </MainNav>
      </Nav>
    </>
  );
};

export default Navbar;
