import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccountBtnDiv,
  AccountIconDiv,
  AccountMenu,
  // AttributeArrow,
  HeaderText,
  MenuBottomDiv,
  MenuHeader,
  MenuIco,
  MenuItem,
  MenuItems,
  MenuItemsDiv,
  // MenuSelectItems,
  NavBarContainer,
} from "./NavbarElements";

import ProductFilter from "../ProductList/ProductFilter";
import { useCategoriesQuery, useUsersQuery } from "../../queries/api";

const MenuBar = ({ menuOpen, onCloseMenu, isFilter }) => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [clickProducts, setClickProducts] = useState(false);
  const [cateValue, setCateValue] = useState();

  // GET USER ID FROM LOCAL STORAGE
  const uIdString = localStorage.getItem("userId");
  // const uIdString = localStorage.getItem("token");
  const isUser = uIdString === "null" ? null : parseFloat(uIdString);

  // FETCHING API DATA
  const { data: userProfile } = useUsersQuery();
  const { data: categories, isLoading: categoriesLdg } = useCategoriesQuery();
  // FILTER CATEGORIES TITLE
  const topLevelCategories =
    !categoriesLdg &&
    categories.filter(
      (category) => category.parent === 0 && category.name !== "Uncategorized"
    );

  // MENU ITEMS
  const menuItems = [
    { label: "Home", path: "/" },
    { label: "Shop", path: "/" },
    { label: "Blog", path: "/blogs" },
    { label: "Contact Us", path: "/contact" },
    { label: "Wishlist", path: isUser ? "/wishlist" : "/login" },
  ];

  // FUNCTION TO HANDLE CATEGORY
  const handleCategory = (value) => {
    if (cateValue) {
      setCateValue();
    } else {
      setCateValue(value.id);
    }
  };

  return (
    <NavBarContainer open={menuOpen}>
      {isFilter ? (
        <MenuItemsDiv>
          <MenuHeader>
            {clickProducts ? (
              <MenuIco
                onClick={() => {
                  setClickProducts(false);
                  setCateValue();
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </MenuIco>
            ) : (
              <MenuIco onClick={onCloseMenu}>
                <i className="fa-solid fa-xmark"></i>
              </MenuIco>
            )}
            <HeaderText>Filters</HeaderText>
          </MenuHeader>
          <ProductFilter isFilter={isFilter} />
        </MenuItemsDiv>
      ) : (
        <MenuItemsDiv>
          <MenuHeader>
            {clickProducts ? (
              <MenuIco
                onClick={() => {
                  setClickProducts(false);
                  setCateValue();
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </MenuIco>
            ) : (
              <MenuIco onClick={onCloseMenu}>
                <i className="fa-solid fa-xmark"></i>
              </MenuIco>
            )}
            <HeaderText>
              {clickProducts ? "Categories" : "Main Menu"}
            </HeaderText>
          </MenuHeader>
          {clickProducts ? (
            <>
              {topLevelCategories?.slice(0, 6).map((category) => (
                <menuItems key={category.id}>
                  <MenuItem onClick={() => handleCategory(category)}>
                    {category.name}
                    {cateValue === category.id ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </MenuItem>

                  {categories
                    .filter((subcategory) => subcategory.parent === category.id)
                    .map((subcategory) => {
                      if (cateValue === category.id) {
                        return (
                          <MenuItem
                            onClick={() => {
                              navigate(
                                `/${subcategory.name}/${subcategory.parent}/products`
                              );
                              onCloseMenu();
                            }}
                            style={{ paddingLeft: ".5rem" }}
                            key={subcategory.id}
                          >
                            {subcategory.name}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                </menuItems>
              ))}
              {topLevelCategories.length > 6 && (
                <MenuItem onClick={() => navigate("/categories")}>
                  List All <i class="fa-solid fa-angle-down"></i>
                </MenuItem>
              )}
            </>
          ) : (
            <MenuItems>
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      if (item.label === "Shop") {
                        setClickProducts(true);
                      } else {
                        onCloseMenu();
                        navigate(item.path);
                      }
                    }, 0);
                  }}
                >
                  {item.label}
                  {item.label === "Shop" && (
                    <i class="fa-solid fa-angle-right"></i>
                  )}
                </MenuItem>
              ))}
            </MenuItems>
          )}
        </MenuItemsDiv>
      )}

      <MenuBottomDiv>
        {isUser && isUser !== "null" ? (
          <AccountMenu
            onClick={() => {
              onCloseMenu();
              setTimeout(() => {
                navigate(`/profile/${userProfile?.username}/${isUser}`);
              }, 0);
            }}
          >
            <AccountIconDiv>
              <i className="fa-regular fa-user"></i>
              Hi, {userProfile?.username}
            </AccountIconDiv>
            <AccountBtnDiv>
              <i class="fa-solid fa-arrow-right shop"></i>
            </AccountBtnDiv>
          </AccountMenu>
        ) : (
          <AccountMenu>
            <AccountIconDiv>
              <i className="fa-regular fa-user"></i>
              Account
            </AccountIconDiv>
            <AccountBtnDiv>
              <a href="/login">Sign in</a>
            </AccountBtnDiv>
          </AccountMenu>
        )}
      </MenuBottomDiv>
    </NavBarContainer>
  );
};

export default MenuBar;
