import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../images/spinner.gif";

import {
  AccountForm,
  AccountInput,
  Eyeslash,
  LoginBtn,
  LoginDiv,
  LoginDiv1,
  LoginDiv2,
  LoginDivs,
  LoginForm,
  LoginFormDiv,
  LoginHeadTxt,
  LoginInput,
  LoginInputLabel,
  LoginLabel,
  LoginSection,
  PassInput,
  RemoveIcon,
  RemoveItem,
  RemoveItemaDiv,
  Slide,
  SlideControls,
  SlideControlsSection,
  SliderTab,
} from "./LoginElements";
import { consumerKey, consumerSecret } from "../../apiUrls";
import { useQueryClient } from "@tanstack/react-query";
import SignUp from "../Signup/SignUp";

const Login = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // STATE HOOKS
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passType, setPassType] = useState("password");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  // const [logined, setLogined] = useState(false);

  // FUNCTION TO HANDLE LOGIN
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoadingLogin(true);

      const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;

      const { data } = await axios.post(
        `https://admin.wiscart.in/wp-json/wc/user/login/?login=${username}&password=${password}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        }
      );

      if (data.status) {
        localStorage.setItem("userId", data.ID);
        localStorage.setItem("token", data.token);
        const guestId = localStorage.getItem("cartId");
        if (guestId) {
          await mergeCart(data.ID, guestId);
        }
        queryClient.invalidateQueries("users");
        // navigate("/");
        window.history.back();
      } else {
        const errorMessage =
          data.error === 0
            ? "Username or password is incorrect"
            : "User does not exist";
        alert(errorMessage);
      }
    } catch (error) {
      console.error("Error logging in:", error);
    } finally {
      setIsLoadingLogin(false); // Reset loading state to false when done
    }
  };

  // FUNCTION TO MERGE CART DATA
  const mergeCart = async (userId, guestId) => {
    try {
      const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;

      const { data } = await axios.get(
        `https://admin.wiscart.in/wp-json/wc/cart/list/userid/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        }
      );

      const userCartId = data?.cart_data[0]?.ID;

      await axios
        .post(
          `https://admin.wiscart.in/wp-json/wc/cart/merge/products?guest_cart_id=${guestId}&user_cart_id=${userCartId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: authHeader,
            },
          }
        )
        .then((data) => {
          localStorage.setItem("cartId", userCartId);
          queryClient.invalidateQueries("cart");
          // setLogined(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [isLogin, setIsLogin] = useState(true);

  const handleSignupClick = () => {
    setIsLogin(false);
  };

  const handleLoginClick = () => {
    setIsLogin(true);
  };

  const handleSignupLinkClick = (e) => {
    e.preventDefault();
    setIsLogin(false);
  };

  const handleLoginLinkClick = (e) => {
    e.preventDefault();
    setIsLogin(true);
  };

  const toggleLogin = () => {
    setIsLogin(!isLogin);
  };

  return (
    <LoginSection>
      <LoginDivs>
        <LoginDiv1>
          <h4>Welcome to wise cart</h4>
          <p>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commenly used to demonstrate
          </p>
          <RemoveIcon
            onClick={() => window.history.back()}
            className="fas fa-regular fa-xmark"
          ></RemoveIcon>
        </LoginDiv1>
        <LoginDiv2>
          <SlideControlsSection>
            <SlideControls>
              <input
                type="radio"
                name="slide"
                id="login"
                checked={isLogin}
                onChange={handleLoginClick}
                style={{ display: "none" }}
              />
              <input
                type="radio"
                name="slide"
                id="signup"
                checked={!isLogin}
                onChange={handleSignupClick}
                style={{ display: "none" }}
              />
              <Slide
                htmlFor="login"
                signup={isLogin}
                onClick={handleLoginLinkClick}
              >
                Login
              </Slide>
              <Slide
                htmlFor="signup"
                signup={!isLogin}
                onClick={handleSignupLinkClick}
              >
                Signup
              </Slide>
              <SliderTab signup={!isLogin} />
            </SlideControls>
          </SlideControlsSection>
          {isLogin ? (
            <AccountForm onSubmit={handleSubmit}>
              <label htmlFor="">Username</label>
              <AccountInput
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label>Password</label>
              <Eyeslash>
                <PassInput
                  type={passType}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passType === "password" ? (
                  <i
                    onClick={() => setPassType("text")}
                    style={{ cursor: "pointer", fontSize: "11px" }}
                    className="fas fa-light fa-eye-slash"
                  ></i>
                ) : (
                  <i
                    onClick={() => setPassType("password")}
                    style={{ cursor: "pointer", fontSize: "12px" }}
                    className="fas fa-light fa-eye"
                  ></i>
                )}
              </Eyeslash>
              <LoginBtn type="submit">
                {isLoadingLogin ? (
                  <img className="button-loading" src={Spinner} alt="" />
                ) : (
                  "Login"
                )}
              </LoginBtn>
              <a
                href="/accounts/password/reset"
                target="_blank"
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                Forgot Password?
              </a>
            </AccountForm>
          ) : (
            <SignUp isSigned={isLogin} toggleLogin={toggleLogin} />
          )}
        </LoginDiv2>
      </LoginDivs>
    </LoginSection>
  );
};

export default Login;

// {/* <LoginDiv>
// <RemoveItemaDiv>
//   <RemoveItem onClick={() => window.history.back()}>
//     <i className="fas fa-regular fa-xmark"></i>
//   </RemoveItem>
// </RemoveItemaDiv>
//   <LoginFormDiv>
//     <LoginHeadTxt>
//       <LoginLabel>
//         {isLogin ? "Login to Your Account" : "Create Your Account"}
//       </LoginLabel>
//       {/* ////////////// */}
// <SlideControls>
//   <input
//     type="radio"
//     name="slide"
//     id="login"
//     checked={isLogin}
//     onChange={handleLoginClick}
//     style={{ display: "none" }}
//   />
//   <input
//     type="radio"
//     name="slide"
//     id="signup"
//     checked={!isLogin}
//     onChange={handleSignupClick}
//     style={{ display: "none" }}
//   />
//   <Slide htmlFor="login" signup={isLogin} onClick={handleLoginLinkClick}>
//     Login
//   </Slide>
//   <Slide
//     htmlFor="signup"
//     signup={!isLogin}
//     onClick={handleSignupLinkClick}
//   >
//     Signup
//   </Slide>
//   <SliderTab signup={!isLogin} />
// </SlideControls>
//       {/* ////////////// */}
//     </LoginHeadTxt>
//     {isLogin ? (
// <LoginForm onSubmit={handleSubmit}>
//   <LoginInputLabel>Username</LoginInputLabel>
// <LoginInput
//   type="text"
//   name="username"
//   value={username}
//   onChange={(e) => setUsername(e.target.value)}
// />
// <LoginInputLabel>Password</LoginInputLabel>

// <Eyeslash>
//   <PassInput
//     type={passType}
//     name="password"
//     value={password}
//     onChange={(e) => setPassword(e.target.value)}
//   />
//   {passType === "password" ? (
//     <i
//       onClick={() => setPassType("text")}
//       style={{ cursor: "pointer", fontSize: "11px" }}
//       className="fas fa-light fa-eye-slash"
//     ></i>
//   ) : (
//     <i
//       onClick={() => setPassType("password")}
//       style={{ cursor: "pointer", fontSize: "12px" }}
//       className="fas fa-light fa-eye"
//     ></i>
//   )}
// </Eyeslash>
// <LoginBtn type="submit">
//   {isLoadingLogin ? (
//     <img className="button-loading" src={Spinner} alt="" />
//   ) : (
//     "Login"
//   )}
// </LoginBtn>
// </LoginForm>
//     ) : (
//       <SignUp isSigned={isLogin} toggleLogin={toggleLogin} />
//     )}
//   </LoginFormDiv>
// </LoginDiv>; */}
