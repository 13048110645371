import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  renderPage: {},
};

const renderSlice = createSlice({
  name: "render",
  initialState: INITIAL_STATE,
  reducers: {
    getRender: (state, action) => {
      state.renderPage = action.payload;
    },
  },
});

export const { getRender } = renderSlice.actions;
export default renderSlice.reducer;
