import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  // productsList: [],
  // FeaturedList: [],
  // productsReviews: [],
  // orders: [],
  // product: {},
  // searchProductName: "",
  // categories: [],
  filterAttribute: [],
  // newArrivals: [],
};

const productSlice = createSlice({
  name: "product",
  initialState: INITIAL_STATE,
  reducers: {
    // getProducts: (state, action) => {
    //   state.productsList = action.payload;
    // },
    // getFeatured: (state, action) => {
    //   state.FeaturedList = action.payload;
    // },
    // selectedProduct: (state, action) => {
    //   state.product = action.payload;
    // },
    // productName: (state, action) => {
    //   state.searchProductName = action.payload;
    // },
    // getReviews: (state, action) => {
    //   state.productsReviews = action.payload;
    // },
    // getOrders: (state, action) => {
    //   state.orders = action.payload;
    // },
    // getCategories: (state, action) => {
    //   state.categories = action.payload;
    // },
    getFilterAttribute: (state, action) => {
      state.filterAttribute = action.payload;
    },
    // getNewArrivals: (state, action) => {
    //   state.newArrivals = action.payload;
    // },
  },
});

export const {
  // getProducts,
  // getFeatured,
  // getReviews,
  // selectedProduct,
  // productName,
  // getOrders,
  // getCategories,
  getFilterAttribute,
  // getNewArrivals,
} = productSlice.actions;
export default productSlice.reducer;
