import React, { useState, useEffect } from "react";
import { ProfileDiv, ProfileOption, ProfileOptions } from "./MyProfileElements";
import { useNavigate } from "react-router-dom";
import MyProfileOptions from "./MyProfileOptions";
import ProfileSection from "./ProfileSection";
import OrdersSection from "./OrdersSection";
import ChangePasswordSection from "./ChangePasswordSection";
import AddressSection from "./AddressSection";

const MyProfile = () => {
  const navigate = useNavigate();

  // // STATE HOOKS
  const [isOption, setIsOption] = useState("myprofile");

  // GET USER ID FROM LOCAL STORAGE
  const uId = parseFloat(localStorage.getItem("userId") || "null");

  // REDIRECT TO HOME IF NO USER
  useEffect(() => {
    if (!uId) navigate("/");
  }, [uId, navigate]);

  const handleStateChange = (value) => {
    setIsOption(value);
  };

  return (
    <ProfileDiv>
      <ProfileOptions>
        <MyProfileOptions onStateChange={handleStateChange} />
      </ProfileOptions>
      <ProfileOption>
        {isOption === "myprofile" && <ProfileSection />}
        {isOption === "myorders" && <OrdersSection />}
        {isOption === "changepass" && <ChangePasswordSection />}
        {isOption === "address" && <AddressSection />}
      </ProfileOption>
    </ProfileDiv>
  );
};

export default MyProfile;
