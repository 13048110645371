import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../images/spinner.gif";

import ReactImageMagnify from "react-image-magnify";

import {
  EachProduct,
  InputValue,
  ItemAttributeColor,
  ItemAttributeMaterial,
  ItemAttributeOptions,
  ItemAttributes,
  ItemAttributeSize,
  ItemAttributeTitle,
  ItemVariations,
  // Loading,
  // LoadingBar,
  MoreDetailsTitle,
  MoreDetailsTitles,
  MyReview,
  // MyReviewRateIcon,
  // NInput,
  NLabel,
  ProdButton,
  ProdButtons,
  ProdDetailsDoc,
  ProdDetailsImages,
  ProdDetailsRate,
  ProdDetailsShare,
  ProdDetailsTitle,
  ProdDocDetailsText,
  ProdDocDetailsTitle,
  ProdFeature,
  ProdFeatures,
  ProdMainImg,
  ProdMoreDetails,
  ProdRateIcon,
  ProdRateIcons,
  ProdRateTitle,
  ProdRatingTitle,
  ProdReview,
  // ProdReviewIcon,
  ProdReviewInput,
  // ProdReviewRateIcon,
  ProdReviewRateIcons,
  ProdReviews,
  ProdShareTitle,
  ProductDetails,
  // ProductNull,
  ReviewButton,
  // ReviewerTitle,
  ReviewForm,
  ReviewOption,
  ReviewOptions,
  ReviewOptionText,
  ReviewOptionTitle,
  ReviewRow,
  ReviewStars,
  RInput,
  SampleImages,
  SampleImg,
  ShareBtn,
  SInput,
  SLabel,
  Stars,
  SWidget,
} from "./ProductElements";

import striptags from "striptags";
import ShareOnSocial from "react-share-on-social";
import favicon from "../../assets/images/icon.png";
import "react-loading-skeleton/dist/skeleton.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  backendUrl,
  consumerKey,
  consumerSecret,
  getText,
  products_api,
  reviews_api,
} from "../../apiUrls";
import Skeleton from "react-loading-skeleton";
import { useAddToCart } from "../../hook/useAddToCart";
import { FaHeart } from "react-icons/fa";
import { useWishList } from "../../hook/useWishList";
import {
  useCartQuery,
  useReviewsQuery,
  useUsersQuery,
  useWishlistQuery,
} from "../../queries/api";
import LoginPage from "../../pages/LoginPage";
import { AddressCancekBtn } from "../CheckOut/CheckOutElements";
import { scrollToTop } from "../Home/Home";

// COMPONENT FOR SHARE PRODUCT DETAILS
const ShareToSocail = ({ data }) => {
  const location = useLocation();

  return (
    <ShareOnSocial
      textToShare={`Check out this new ${data.name}, I just found from SPICE KART!`}
      link={`https://spicekart.wisbato.com${location.pathname}`}
      linkTitle="SPICE KART"
      linkMetaDesc={`${data.name}, ${striptags(data.description)}`}
      linkFavicon={favicon}
      noReferer
    >
      <ShareBtn>
        <i className="fas fa-regular fa-share"></i>
      </ShareBtn>
    </ShareOnSocial>
  );
};

// COMPONENT FOR GIVE RATINGS
export const StarRating = ({ inputRating, onRatingChange }) => {
  const reviewStars = 5;

  // FUNCTION TO HANDLE RATINGS
  const handleStarClick = (rating) => {
    onRatingChange(rating);
  };

  const stars = Array.from({ length: reviewStars }, (_, index) => index + 1);

  return (
    <SWidget>
      {stars?.map((i) => (
        <React.Fragment key={i}>
          <SInput
            className="click_star"
            type="radio"
            onClick={() => handleStarClick(i)}
            name="rate"
            id={`rate-${inputRating && i}`}
          />
          <SLabel
            htmlFor={`rate-${inputRating && i}`}
            className={`fas fa-star click_star ${i <= inputRating ? "checked" : ""
              }`}
            onClick={() => handleStarClick(i)}
          ></SLabel>
        </React.Fragment>
      ))}
    </SWidget>
  );
};

const Product = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const colorNameList = require("color-name-list");

  // GET USER ID FROM LOCAL STORAGE
  const uIdString = localStorage.getItem("userId");
  const userId = uIdString === "null" ? null : parseFloat(uIdString);

  // PRODUCT ID FROM PARAMS
  const { productId } = useParams();
  const productIdP = parseInt(productId);

  // STATE HOOKS
  const [selectedAttributes, setSelectedAttributes] = useState({});

  // const [isLoadingImg, setIsLoadingImg] = useState(false);
  // const [error, setError] = useState(null);

  const [image, setImage] = useState("");
  // const [isCartAdding, setCartAdding] = useState(false);
  const [isReviews, setIsReviews] = useState(false);
  const [isMoreInform, setIsMoreInform] = useState(false);
  const [isDetails, setIsDetails] = useState(true);
  // const [inputSummery, setInputSummery] = useState("");
  const [inputReview, setInputReview] = useState("");
  const [parentRating, setParentRating] = useState(0);
  const [reviewing, setReviewing] = useState(false);

  // FETCHING API DATA
  const { data: cartList, isLoading: cartListLdg } = useCartQuery();
  const { data: userProfile } = useUsersQuery();
  const { data: productsReviews, isLoading: reviewsIsLdg } = useReviewsQuery();
  const { data: wishList, isLoading: wishLoading } = useWishlistQuery();

  // item.product_id == productId

  // IMPORTED HOOKS FUNCTIONS
  const { addToWishList, deleteWishList, wishLoadingBtn, wishId } =
    useWishList();
  const { addToCart, cartLoadingBtn } = useAddToCart();

  // FUNCTION TO HANDLE RATINGS
  const handleRatingChange = (newRating) => {
    setParentRating(newRating);
  };

  // FILTER REVIEWS BY PRODUCT ID
  const productReview =
    !reviewsIsLdg &&
    productsReviews?.filter((review) => review?.product_id === productIdP);

  // FUNCTION TO HANDLE DISPLAY DETAILS
  const handleDetails = (value) => {
    setIsReviews(false);
    setIsMoreInform(false);
    setIsDetails(false);

    if (value === "Reviews") {
      setIsReviews(!isReviews);
    } else if (value === "More Information") {
      setIsMoreInform(!isMoreInform);
    } else if (value === "Details") {
      setIsDetails(!isDetails);
    }
  };

  // FETCH PRODUCT BY PRODUCT ID
  const fetchProduct = async () => {
    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const productResponse = await fetch(
      `${backendUrl}${products_api}/${productId}`,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
    const productData = await productResponse.json();
    return productData;
  };

  // FETCH PRODUCT
  const { data: product, isLoading: productIsLoading } = useQuery(
    ["product", productId],
    fetchProduct,
    {
      onSuccess: (data) => {
        setImage("");
      },
    }
  );

  // FUNCTION TO CALCULATE AVERAGE RATINGS
  const calculateAverageRating = () => {
    const totalRatings = productReview?.length;
    const sumRatings = productReview?.reduce(
      (acc, { rating }) => acc + rating,
      0
    );
    const averageRating = sumRatings / totalRatings;

    return Math.round(averageRating);
  };

  // CALL AVERAGE CALCULATING FUNCTION
  const averageRating = !reviewsIsLdg && calculateAverageRating();
  const renderStars = () => {
    const fullStars = [];
    const emptyStars = [];

    for (let i = 0; i < 5; i++) {
      if (i < averageRating) {
        fullStars.push(
          <ProdRateIcon key={i} className="fa-solid fa-star"></ProdRateIcon>
        );
      } else {
        emptyStars.push(
          <ProdRateIcon
            style={{ color: "#9d9999" }}
            key={i}
            className="fa-regular fa-star"
          ></ProdRateIcon>
        );
      }
    }
    return (
      <div>
        {fullStars}
        {emptyStars}
      </div>
    );
  };
  const fetchGId = cartList?.status && cartList?.cart_data[0]?.ID;

  // FUNCTION TO HANDLE ADD CART ITEM
  const handleAddToCart = () => {
    const fetchGId = cartList.status && cartList?.cart_data[0]?.ID;
    const guestId = localStorage.getItem("cartId");
    const userId = localStorage.getItem("userId");
    console.log("fetchGId: ", fetchGId, "guestId: ", guestId, "userId: ", userId)
    const data = {
      product_id: variableProduct?.id || product?.id,
      quantity: 1,
      cart_id: fetchGId || guestId,
      user_id: userId,
      // variation_id: variableProduct.id,
    };
    addToCart(data);
  };

  // FUNCTION TO POST REVIEW
  const postReview = async () => {
    // if (!inputReview) {
    //   setReviewNullMsg(true);
    // } else if (!parentRating) {
    //   setNullRating(true);
    // }

    try {
      const userId = localStorage.getItem("userId");
      if (!userId || userId === "null") {
        navigate("/login");
        return;
      }

      setReviewing(true);

      const { username, email } = userProfile;
      const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
      // const response = await fetch(
      await fetch(`${backendUrl}/${reviews_api}/?product_id=${productId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
        body: JSON.stringify({
          reviewer: username,
          review: inputReview,
          reviewer_email: email,
          rating: parentRating,
        }),
      });

      // const reviewData = await response.json();
      // await response.json();

      queryClient.invalidateQueries("reviews");
      setReviewing(false);
      setIsReviews(true);
      setInputReview("");
      setParentRating(0);
    } catch (error) {
      setReviewing(false);
    }
  };

  // FUNCTION TO CANCEL REVIEW
  const handleCancel = () => {
    setReviewing(false);
    setIsReviews(true);
    setInputReview("");
    setParentRating(0);
  };

  // FIND WISHLIST ITEMS
  const isFav = wishList?.wishlist_items?.find((item) => {
    return item.product_id == productId;
  });

  // FUNCTION TO ADD WISHLIST ITEM
  const handleAddToWishList = (item) => {
    const fetchWishId =
      wishList?.wishlist_id !== false && wishList?.wishlist_data[0]?.ID;
    const userId = localStorage.getItem("userId");

    if (!userId || userId === null) {
      navigate("/login");
      return;
    }

    if (isFav) {
      const data = {
        product_key: isFav.ID,
        wishlist_id: fetchWishId,
      };
      deleteWishList(data);
    } else {
      const data = {
        product_id: item.id,
        user_id: userId,
        wishlist_id: fetchWishId,
      };
      addToWishList(data);
    }
  };

  const category = product?.categories[0]?.slug;
  const productName = product?.name?.replace(/\s/g, "-");

  // GET INPUT COLORS FROM PRODUCT ATTRIBUTES
  const inputColors = product?.attributes?.find((item) =>
    item.name.includes("Color")
  )?.options;
  // FUNCTION TO GET COLOR HEX BY NAME
  const getColorHexByName = (colorName) => {
    const colorInfo = colorNameList?.find(
      (color) => color.name.toLowerCase() === colorName?.toLowerCase()
    );
    return colorInfo ? colorInfo?.hex : null;
  };

  // FUNCTION TO HANDLE ATTRIBUTE OPTION CLICK
  const handleAttributeOptionDefault = (attributeName, option) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: option,
    }));
  };

  // CALL ATTRIBUTE CLICK OPTION WHEN CHANGE PRODUCT
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    product?.default_attributes?.forEach((item) => {
      const name = capitalizeFirstLetter(item?.name);
      const option = capitalizeFirstLetter(item?.option);
      handleAttributeOptionDefault(name, option);
    });
  }, [product]);

  const handleAttributeOptionClick = (attributeName, option) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: option,
    }));
  };

  // FUNCTION TO CONVERT DATA TO URL PARAMS
  function convertToURLParams(data) {
    return Object.keys(data)
      ?.map(
        (key) =>
          `pa_${key.toLowerCase().replace(/\s+/g, "-")}=${encodeURIComponent(
            data[key]
          )}`
      )
      .join("&");
  }

  // FUNCTION TO FETCH VARIATION
  const fetchVariation = async () => {
    if (!selectedAttributes) {
      return;
    }

    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const urlParams = convertToURLParams(selectedAttributes).toLowerCase();
    const variationUrl = `https://admin.wiscart.in/wp-json/wc/products/variation?${urlParams}&variable=${productId}`;
    const variationResponse = await fetch(variationUrl, {
      headers: {
        Authorization: authHeader,
      },
    });

    const variationData = await variationResponse.json();
    return variationData;
  };

  // GET VARIATION
  const { data: variation, isLoading: variationLdg } = useQuery(
    ["variation", selectedAttributes],
    fetchVariation
  );

  const [isCart, setIsCart] = useState(null);

  // USEEFFECT TO FIND CART ITEM
  useEffect(() => {
    if (variationLdg || cartListLdg) {
      return;
    }

    const isCart = cartList?.cart_items?.some((item) => {
      const productId = parseInt(item?.product_id);
      return productId === product?.id || productId === variation;
    });

    setIsCart(isCart);
  }, [variation, product, variationLdg, handleAddToCart]);

  // FUNCTION TO FETCH VARIATION PRODUCT
  const fetchVariationProduct = async (variationId) => {
    if (!variationId) {
      return null;
    }

    const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
    const variationProductUrl = `${backendUrl}/wp-json/wc/v3/products/${productId}/variations/${variationId}`;

    const productResponse = await fetch(variationProductUrl, {
      headers: {
        Authorization: authHeader,
      },
    });

    const productData = await productResponse.json();
    return productData;
  };

  // GET VARIATION PRODUCT
  const { data: variableProduct, isLoading: variableProductIsLoading } =
    useQuery(["variableProduct", variation], () =>
      fetchVariationProduct(variation)
    );

  // USEEFFECTS TO FETCH DATA
  useEffect(() => {
    if (selectedAttributes) {
      queryClient.invalidateQueries(["variation", selectedAttributes]);
    }
  }, [selectedAttributes, queryClient]);

  useEffect(() => {
    if (variation) {
      queryClient.invalidateQueries(["variableProduct", variation]);
    }
  }, [variation, queryClient]);

  useEffect(() => {
    if (variableProduct?.image.src) {
      setImage(variableProduct?.image?.src);
    } else if (product?.images[0]?.src) {
      setImage(product?.images[0]?.src);
    }
  }, [variableProduct, product]);

  // PARSE HTML ELEMENTED CONTENT
  const parseContent = () => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(product?.description, "text/html");
    const pTag = xmlDoc.querySelector("p");
    const ulTag = xmlDoc.querySelector("ul");

    // EXTRACT THE TEXT CONTENT FROM <p> AND <li> ELEMENTS
    const description = pTag ? pTag.textContent : "";
    const listItems = ulTag
      ? Array.from(ulTag.querySelectorAll("li"))?.map((li) => li.textContent)
      : [];

    return { description, listItems };
  };

  const { description, listItems } = parseContent();

  return (
    <>
      {/* PRODUCT DETAILS */}
      {productIsLoading || variationLdg || variableProductIsLoading ? (
        <ProductDetails>
          <ProdDetailsTitle>
            <Skeleton width={300} height={30} />
          </ProdDetailsTitle>
          <EachProduct>
            <ProdDetailsImages>
              <Skeleton className="skeleton-product" />
              <SampleImages style={{ display: "flex", gap: "5px" }}>
                {[...Array(2)].map((_, i) => (
                  <Skeleton key={i} width={100} height={100} />
                ))}
              </SampleImages>
            </ProdDetailsImages>

            <ProdDetailsDoc style={{ margin: "0" }}>
              <ProdDocDetailsTitle>
                <Skeleton width={200} height={25} />
              </ProdDocDetailsTitle>
              <ProdDocDetailsText>
                {[...Array(4)].map((_, i) => (
                  <Skeleton
                    key={i}
                    count={1}
                    className="skeleton-product-text"
                  />
                ))}
              </ProdDocDetailsText>
              <ProdFeatures>
                {[...Array(7)].map((_, i) => (
                  <ProdFeature key={i}>
                    <Skeleton width={150} />
                  </ProdFeature>
                ))}
              </ProdFeatures>

              <div style={{ display: "flex", gap: "10px" }}>
                <Skeleton width={100} height={35} />
                <Skeleton width={100} height={35} />
              </div>
            </ProdDetailsDoc>
          </EachProduct>
        </ProductDetails>
      ) : (
        <ProductDetails>
          <ProdDetailsTitle>Product Details</ProdDetailsTitle>
          <EachProduct>
            <ProdDetailsImages>
              {image !== undefined ? (
                <ProdMainImg>
                  <div className="image-magnifier-container">
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "...",
                          isFluidWidth: true,
                          src: image,
                        },
                        largeImage: {
                          alt: "...",
                          src: image,
                          width: 1500,
                          height: 1500,
                        },
                      }}
                    />
                  </div>
                </ProdMainImg>
              ) : (
                <ProdMainImg>
                  <Skeleton height={500} />
                </ProdMainImg>
              )}
              <SampleImages>
                {!variation || variableProductIsLoading ? null : (
                  <SampleImg
                    onClick={() => setImage(variableProduct?.image?.src)}
                    src={variableProduct?.image?.src}
                    alt="..."
                    style={{
                      boxShadow:
                        image === variableProduct?.image?.src
                          ? "0 3px 5px rgba(34, 139, 34, 0.5)"
                          : "none",
                    }}
                  />
                )}
                {product?.images
                  ?.filter((item, index, self) => {
                    // Use a Set to track unique image sources
                    const srcSet = new Set(self?.map((img) => img.src));
                    return (
                      srcSet.has(item?.src) &&
                      index === self.findIndex((img) => img.src === item?.src)
                    );
                  })
                  ?.map((items, i) => (
                    <SampleImg
                      onClick={() => setImage(items.src)}
                      key={items.id}
                      src={items.src}
                      alt="..."
                      style={{
                        boxShadow:
                          image === items.src
                            ? "0 2px 4px rgba(34, 139, 34, 0.5)"
                            : "none",
                      }}
                    />
                  ))}
              </SampleImages>
            </ProdDetailsImages>

            <ProdDetailsDoc>
              <ProdDocDetailsTitle>
                <p>{product?.name}</p>
                <p>
                  &#8377;
                  {product?.sale_price ||
                    product?.price ||
                    product?.regular_price}
                </p>
              </ProdDocDetailsTitle>
              {/* <ProductAttributes product={product} /> */}
              <ItemVariations>
                {product?.attributes?.map((item, i) => {
                  return (
                    <ItemAttributes key={i}>
                      <ItemAttributeTitle>{item?.name}</ItemAttributeTitle>
                      <ItemAttributeOptions>
                        {item?.options?.map((option, j) => {
                          const isSelected =
                            selectedAttributes[item?.name] === option;

                          if (item?.name?.includes("Color")) {
                            const colorOption = inputColors[j];
                            const colorHex = getColorHexByName(colorOption);

                            return (
                              <ItemAttributeColor
                                key={j}
                                style={{
                                  backgroundColor: colorHex,
                                  border: isSelected
                                    ? "2px solid black"
                                    : "1px solid #9d9999",
                                }}
                                onClick={() =>
                                  handleAttributeOptionClick(item?.name, option)
                                }
                              />
                            );
                          } else if (item?.name?.includes("Size")) {
                            return (
                              <ItemAttributeSize
                                key={j}
                                onClick={() =>
                                  handleAttributeOptionClick(item?.name, option)
                                }
                                style={{
                                  border: isSelected
                                    ? "2px solid black"
                                    : "1px solid #9d9999",
                                }}
                              >
                                {option}
                              </ItemAttributeSize>
                            );
                          } else if (item?.name?.includes("Material")) {
                            return (
                              <ItemAttributeMaterial
                                key={j}
                                onClick={() =>
                                  handleAttributeOptionClick(item?.name, option)
                                }
                                style={{
                                  border: isSelected
                                    ? "2px solid black"
                                    : "1px solid #9d9999",
                                }}
                              >
                                {option}
                              </ItemAttributeMaterial>
                            );
                          }
                          return null;
                        })}
                      </ItemAttributeOptions>
                      <ItemAttributeTitle style={{ marginTop: "10px" }}>
                        Selected {item?.name} :
                        {selectedAttributes[item?.name] || "None"}
                      </ItemAttributeTitle>
                    </ItemAttributes>
                  );
                })}
              </ItemVariations>
              <ProdDetailsRate>
                <ProdRateTitle>Rating</ProdRateTitle>
                <ProdRateIcons>{renderStars()}</ProdRateIcons>
              </ProdDetailsRate>
              <ProdDetailsShare>
                <ProdShareTitle>Share</ProdShareTitle>

                <ShareToSocail data={product} />
              </ProdDetailsShare>
              <ProdButtons>
                {/* <ProdButton
                  onClick={() => {
                    const user = localStorage.getItem("userId");
                    if (user) {
                      navigate(
                        `/checkout/${category}/${productName}/${
                          variableProduct?.id || product?.id
                        }/buy`
                      );
                      scrollToTop();
                    } else {
                      navigate("/login");
                    }
                  }}
                >
                Buy Now
              </ProdButton> */}
                <div>
                  {/* temporary div */}
                  {/* <p
                        style={{
                          fontSize: "12px",
                          marginBottom: "10px",
                          color: "red",
                        }}
                      >
                        {Object.keys(selectedAttributes).map((key) => (
                          <>{selectedAttributes[key]} </>
                        ))}
                        is currently Out of stock
                      </p> */}
                  {product?.type === "variable" && !variation && (
                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          marginBottom: "10px",
                          color: "red",
                        }}
                      >
                        Out of stock
                      </p>
                    </div>
                  )}
                  {isCart ? (
                    <ProdButton
                      onClick={() => {
                        navigate("/cart");
                      }}
                    >
                      Go To Cart
                    </ProdButton>
                  ) : (
                    <>
                      {product?.type === "variable" && !variation ? (
                        <ProdButton
                          style={{
                            backgroundColor: "gray",
                            cursor: "not-allowed",
                          }}
                        >
                          Add to cart
                        </ProdButton>
                      ) : (
                        <ProdButton
                          onClick={
                            !cartLoadingBtn ? handleAddToCart : undefined
                          }
                        >
                          {cartLoadingBtn ? (
                            <img
                              className="button-loading"
                              src={Spinner}
                              alt=""
                            />
                          ) : (
                            "Add to cart"
                          )}
                        </ProdButton>
                      )}
                    </>
                  )}
                </div>
              </ProdButtons>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {wishLoadingBtn || wishLoading ? (
                  <img style={{ width: "20px" }} src={Spinner} alt="" />
                ) : (
                  <FaHeart
                    onClick={() => {
                      if (!wishLoadingBtn && userId) {
                        handleAddToWishList(product);
                      } else {
                        navigate("/login");
                      }
                    }}
                    style={{
                      color: isFav ? "red" : "gray",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                )}
                <p style={{ fontWeight: "300" }}>
                  {isFav ? "Added to Favorite" : "Add to Favorite"}
                </p>
              </div>
            </ProdDetailsDoc>
          </EachProduct>
        </ProductDetails>
      )}
      <ProdMoreDetails>
        <MoreDetailsTitles>
          <MoreDetailsTitle
            style={{ color: isDetails && "#82ba32" }}
            onClick={() => handleDetails("Details")}
          >
            Details
          </MoreDetailsTitle>
          {/* <MoreDetailsTitle
            style={{ color: isMoreInform && "#82ba32" }}
            onClick={() => handleDetails("More Information")}
          >
            More Information
          </MoreDetailsTitle> */}
          <MoreDetailsTitle
            style={{ color: isReviews && "#82ba32" }}
            onClick={() => handleDetails("Reviews")}
          >
            Reviews
          </MoreDetailsTitle>
        </MoreDetailsTitles>
        {isDetails && (
          <div className="product-details">
            <ProdDocDetailsText>{description}</ProdDocDetailsText>
            <ProdFeatures>
              {listItems?.map((item, index) => (
                <ProdFeature key={index}>{item}</ProdFeature>
              ))}
            </ProdFeatures>
          </div>
        )}
        {/* Reviews */}
        {isReviews && (
          <>
            {reviewsIsLdg ? (
              <ProdReviews>
                <ProdReview style={{ border: "none" }}>
                  <Skeleton width={300} />
                  <Skeleton width={200} />
                  <Skeleton width={250} />
                </ProdReview>
              </ProdReviews>
            ) : (
              <ProdReviews>
                {productReview?.map((item, index) => {
                  const starsCount = item?.rating;
                  const stars = [];

                  for (let i = 1; i <= 5; i++) {
                    const className = i <= starsCount ? "y" : "n";
                    stars.push(
                      <Stars
                        key={i}
                        className={`${className} fas fa-star`}
                      ></Stars>
                    );
                  }
                  return (
                    <ProdReview key={index}>
                      <ReviewOptions>
                        <ReviewOption>
                          <ReviewStars>
                            <ReviewOptionTitle>
                              {item.reviewer}
                            </ReviewOptionTitle>
                            <ProdReviewRateIcons>{stars}</ProdReviewRateIcons>
                          </ReviewStars>
                          <ReviewOptionText>
                            {getText(item.review)}
                          </ReviewOptionText>
                        </ReviewOption>
                        {item.date_created.split("T")[0]}
                      </ReviewOptions>
                    </ProdReview>
                  );
                })}
              </ProdReviews>
            )}
          </>
        )}
        {/* MYREVIEW */}
        <MyReview>
          <ProdReviewInput>
            <ProdRatingTitle>Your Rating</ProdRatingTitle>
            <StarRating
              inputRating={parentRating}
              onRatingChange={handleRatingChange}
            />
          </ProdReviewInput>

          <ReviewForm>
            <ReviewRow>
              {/* <InputValue>
                <NLabel htmlFor="">Summery</NLabel>
                <br />
                <NInput
                  value={inputSummery}
                  onChange={(e) => setInputSummery(e.target.value)}
                  type="text"
                />
              </InputValue> */}
            </ReviewRow>
            <ReviewRow>
              <InputValue>
                <NLabel htmlFor="">Review</NLabel>
                <br />
                <RInput
                  value={inputReview}
                  onChange={(e) => setInputReview(e.target.value)}
                  type="text"
                />
              </InputValue>
            </ReviewRow>
            <div style={{ display: "flex" }}>
              <ReviewButton onClick={!reviewing && postReview}>
                {reviewing ? (
                  <img className="button-loading" src={Spinner} alt="" />
                ) : (
                  "Post Review"
                )}
              </ReviewButton>
              {inputReview || parentRating > 0 ? (
                <AddressCancekBtn onClick={handleCancel}>
                  Cancel
                </AddressCancekBtn>
              ) : null}
            </div>
          </ReviewForm>
        </MyReview>
      </ProdMoreDetails>
    </>
  );
};

export default Product;
