import React from "react";

const ErrorPage = () => {
  return (
    <div className="error-page-section">
      <div className="error-page-div">
        <img src={process.env.PUBLIC_URL + "/logo256.png"} alt="..." />
        <p>You're offline</p>
      </div>
    </div>
  );
};

export default ErrorPage;
