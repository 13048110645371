import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { backendUrl, consumerKey, consumerSecret, customer } from "../apiUrls";
import { useState } from "react";

const useUserDetails = () => {
  const [userLoadingBtn, setUserLoadingBtn] = useState(false);
  const [passLoadingBtn, setPassLoadingBtn] = useState(false);
  const queryClient = useQueryClient();
  const uId = localStorage.getItem("userId");

  // UPDATE USER DETAILS
  const updateUser = async (data) => {
    try {
      setUserLoadingBtn(true);
      const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;

      const response = await axios.put(
        `${backendUrl}${customer}/${uId}`,
        data,
        {
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      queryClient.invalidateQueries("users");
      setUserLoadingBtn(false);
    } catch (error) {
      console.error(error);
      setUserLoadingBtn(false);
    }
  };

  const mutation = useMutation(updateUser);

  // CHANGE PASSWORD
  const changePassword = async (data) => {
    const { user_id, current_password, password } = data;
    console.log("data", user_id, current_password, password);
    // `https://admin.wiscart.in/wp-json/wc/user/password/update`,
    try {
      setPassLoadingBtn(true);
      const response = await axios.post(
        `https://admin.wiscart.in/wp-json/wc/user/password/update?user_id=${user_id}&password=${password}&current_password=${current_password}`,
        {}
      );
      console.log("password response", response);
      console.log("Password changed successfully.");
      queryClient.invalidateQueries("users");
      setPassLoadingBtn(false);
      // Additional logic if needed
    } catch (error) {
      console.error("Error changing password:", error);
      setPassLoadingBtn(false);
      // Additional error handling if needed
    }
  };

  return {
    updateUser: mutation.mutate,
    changePassword,
    userLoadingBtn,
    passLoadingBtn,
  };
};

export { useUserDetails };
