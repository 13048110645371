import "./App.css";
import AboutUsPage from "./pages/AboutUsPage";
import CartPage from "./pages/CartPage";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";
import ProductPage from "./pages/ProductPage";
import CheckOutPage from "./pages/CheckOutPage";
import ContactPage from "./pages/ContactPage";
import LoginPage from "./pages/LoginPage";
import OrderCompletePage from "./pages/OrderCompletePage";

import MyProfilePage from "./pages/MyProfilePage";
import WishListPage from "./pages/WishListPage";

import BlogsPage from "./pages/BlogsPage";
import BlogPage from "./pages/BlogPage";
import CategoriesPage from "./pages/CategoriesPage";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import {
  useAddressQuery,
  useBlogQuery,
  useCartQuery,
  useCategoriesQuery,
  useFeaturedQuery,
  useOrdersQuery,
  useProductsQuery,
  useReviewsQuery,
  useSettingsQuery,
  useUsersQuery,
  useWishlistQuery,
} from "./queries/api";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ConfirmEmailPage from "./pages/ConfirmEmailPage";

function App() {
  const { isError: blogError } = useBlogQuery();
  const { isError: reviewError } = useReviewsQuery();
  const { isError: wishError } = useWishlistQuery();
  const { isError: orderError } = useOrdersQuery();
  const { isError: featuredError } = useFeaturedQuery();
  const { isError: settingsError } = useSettingsQuery();
  const { isError: userError } = useUsersQuery();
  const { isError: addressError } = useAddressQuery();
  const { isError: productsError } = useProductsQuery();
  const { isError: categoryError } = useCategoriesQuery();
  const { isError: cartError } = useCartQuery();

  if (
    blogError ||
    reviewError ||
    wishError ||
    orderError ||
    featuredError ||
    settingsError ||
    userError ||
    addressError ||
    productsError ||
    categoryError ||
    cartError
  ) {
    return <ErrorPage />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/wishlist" element={<WishListPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/checkout/buy" element={<CheckOutPage />} />
        <Route path="/ordercompleted" element={<OrderCompletePage />} />
        <Route path="/profile/:name/:id" element={<MyProfilePage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blog/:blogId" element={<BlogPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route
          path="/:category/:categoryId/products"
          element={<ProductsPage />}
        />
        <Route
          path="/product/:category/:name/:productId"
          element={<ProductPage />}
        />
        <Route path="/accounts/password/reset" element={<ConfirmEmailPage />} />
        <Route
          path="/accounts/password/reset/confirm"
          element={<ForgotPasswordPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
