import styled from "styled-components";

export const AboutSection = styled.div`
  margin: 2rem 4rem;

  @media screen and (max-width: 950px) {
    margin: 2rem 0.5rem;
  }
`;

export const AboutUsTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin: 2rem 0;
  color: green;

  @media screen and (max-width: 950px) {
    font-size: 25px;
    margin: 1.5rem 0;
  }
`;
export const AboutUsContent = styled.div``;
export const AboutUsImg = styled.img`
  width: 500px;
  height: 300px;
  /* float: left; */
  padding: 0 1rem 0 0;
object-fit: contain;
  @media screen and (max-width: 950px) {
    width: 100%;
    height: 100%;
    padding: 0 0 1rem 0;
  }
`;
export const AboutUsText = styled.p`
  font-size: 15px;
  font-weight: 300;
`;
