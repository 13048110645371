import { configureStore } from "@reduxjs/toolkit";
// import cartReducer from "./reducers/handleCart";
// import wishReducer from "./reducers/handleWishlist";
import productReducer from "./reducers/productsReducer";
// import profileReducer from "./reducers/handleProfile";
import renderReducer from "./reducers/handleRender";

const store = configureStore({
  reducer: {
    // cart: cartReducer,
    // wishlist: wishReducer,
    product: productReducer,
    // profile: profileReducer,
    render: renderReducer,
  },
});

export default store;
