import styled from "styled-components";

export const CategoriesDiv = styled.div`
  display: flex;
  align-items: start;
  margin: 2rem;
  gap: 20px;

  @media screen and (max-width: 800px) {
    margin: 1rem;
  }
`;
export const CategoryButtonDiv = styled.div`
  /* display: flex; */
  display: grid;
  justify-content: start;
  align-items: center;

  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  /* ::-webkit-scrollbar {
    display: none;
  } */

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const SubCategoriesDiv = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 10px;

  @media screen and (max-width: 800px) {
    gap: 5px;
  }
`;

export const CategorySection = styled.div`
  /* flex-basis: calc(33.33% - 50px); */
  max-width: 200px;
  height: auto;

  @media screen and (max-width: 800px) {
    max-width: 100px;
    height: auto;
  }
`;

export const CategoryTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;
export const CategoryImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;
