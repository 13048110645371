import React from "react";
import styled from "styled-components";

const ButtonStyle = styled.button`
  transition: all 0.3s ease-in;
  /* background-color: #82ba32; */
  background-color: #2faa04;
  cursor: pointer;
  color: #fff;

  border: ${(props) => props.customStyles?.border || "1px solid #9d9999"};
  border-radius: ${(props) => props.customStyles?.borderRadius || "1rem"};
  font-size: ${(props) => props.customStyles?.fontSize || "12px"};
  width: ${(props) => props.customStyles?.width || "6rem"};
  height: ${(props) => props.customStyles?.height || "1.7rem"};
  float: ${(props) => props.customStyles?.float || ""};
  margin-top: ${(props) => props.customStyles?.marginTop || ""};
  margin-left: ${(props) => props.customStyles?.marginTeft || ""};
  margin-right: ${(props) => props.customStyles?.marginRight || ""};
  min-width: ${(props) => props.customStyles?.minWidth || ""};
  max-width: ${(props) => props.customStyles?.maxWidth || ""};
  margin: ${(props) => props.customStyles?.margin || ""};

  /* display: flex; */
  flex: ${(props) => props.customStyles?.flex || "unset"};
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: ${(props) => props.mediaSize || "950px"}) {
    font-size: ${(props) => props.mediaStyles?.fontSize || ""};
    height: ${(props) => props.mediaStyles?.height || ""};
    width: ${(props) => props.mediaStyles?.width || ""};

    &:hover {
      /* background-color: #82ba32; */
      background-color: #2faa04;
    }
  }
`;

const Button = ({
  title,
  style,
  onClick,
  type,
  mediaStyles,
  mediaSize,
  disabled,
}) => {
  return (
    <ButtonStyle
      disabled={disabled}
      mediaStyles={mediaStyles}
      type={type}
      onClick={onClick}
      customStyles={style}
      mediaSize={mediaSize}
    >
      {title}
    </ButtonStyle>
  );
};

export default Button;
