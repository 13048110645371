import styled from "styled-components";

// PRODUCT DETAILS
export const ProductDetails = styled.div`
  margin: 2rem 4rem;

  .skeleton-product {
    width: 500px;
    height: 500px;
  }
  @media screen and (max-width: 950px) {
    margin: 2rem 0.5rem;

    .skeleton-product {
      width: 350px;
      height: 350px;
    }
  }
`;
export const ProdDetailsTitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  color: green;

  margin: 2rem 0;

  @media screen and (max-width: 800px) {
    font-size: 25px;
  }
`;
export const EachProduct = styled.div`
  display: flex;
  justify-content: start;

  @media screen and (max-width: 950px) {
    display: grid;
  }
`;
export const ProdDetailsImages = styled.div`
  width: 100%;
`;
export const ProdMainImg = styled.div`
  display: grid;
  place-items: center;
  border: solid 1px #9d9999;
  margin-bottom: 0.5rem;
  position: relative;

  img {
    object-fit: contain;
    width: 100%;
    padding: 5px;
  }
`;
export const ProdDetailsImg = styled.img``;
export const SampleImages = styled.div`
  /* display: flex;
  gap: 10px; */
`;

export const SampleImg = styled.img`
  margin: 12px;
  width: 100px;
  height: 100px;
  border: solid 1px #9d9999;

  margin: 0rem 10px 0rem 0;
  object-fit: contain;
  /* padding: 5px; */
`;

export const ProdDetailsDoc = styled.div`
  width: 100%;
  margin: 0 2rem;

  @media screen and (max-width: 950px) {
    margin: 0.5rem;
  }
`;
export const ProdDocDetailsTitle = styled.p`
  font-weight: 400;
  font-size: 25px;
  color: green;
  padding-bottom: 8px;
  border-bottom: 1px solid #d3d0d0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  p {
    align-items: center;
  }

  @media screen and (max-width: 800px) {
    font-size: 22px;
  }
`;
export const ProdDocDetailsText = styled.p`
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 1rem;
  max-width: 80%;
  margin-top: 20px;
`;
export const ProdFeatures = styled.ul`
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 1rem;

  display: grid;
  align-items: center;
  list-style: none;
`;
export const ProdFeature = styled.li`
  &::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    background-color: #9d9999;
    float: left;
    margin: 0.5rem 0.5rem 0;
    border-radius: 50%;
  }
`;

export const ProdDetailsRate = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid #d3d0d0;
`;
export const ProdRateTitle = styled.p`
  margin-right: 1rem;
`;
export const ProdRateIcons = styled.div`
  display: flex;
`;
// export const ProdRateIcon = styled.img`
//   width: 15px;
//   height: 17px;
// `;
export const ProdRateIcon = styled.i`
  color: orange;
  font-size: 14px;
`;

export const ProdDetailsShare = styled.div`
  display: flex;

  padding: 1.5rem 0;
  border-bottom: 1px solid #d3d0d0;
`;
export const ProdShareTitle = styled.p`
  margin-right: 1rem;
`;
export const ProdShareBtn = styled.button`
  border: solid 1px #9d9999;
  width: 2.5rem;
  border-radius: 0.5rem;
  transition: 0.2s ease-in;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #9d9999;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;
export const ProdShareIcon = styled.img`
  cursor: pointer;
  border: 1px solid #252525;
  margin-right: 3px;
  width: 30px;
  padding: 5px;
`;

export const ProdButtons = styled.div`
  display: flex;
  padding: 1.5rem 0;
`;

export const ProdButton = styled.button`
  color: #fff;
  width: 100px;
  height: 35px;
  border-radius: 1rem;
  background-color: #82ba32;
  border: 1px solid #9d9999;
  font-size: 12px;
  transition: all 0.3s ease-in;
  margin-right: 1rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    width: 150px;
    height: 45px;
    &:hover {
      background-color: #82ba32;
    }
  }
`;

// MORE INFORMATION

export const ProdMoreDetails = styled.div`
  margin: 2rem 4rem;

  @media screen and (max-width: 950px) {
    margin: 2rem 0.5rem;
  }
`;
export const MoreDetailsTitles = styled.div`
  display: flex;
  border-bottom: 1px solid #9d9999;
`;
export const MoreDetailsTitle = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;

  margin-right: 1rem;
  transition: all 0.1s ease-in;
  cursor: pointer;
  &:hover {
    color: #82ba32;
    border-bottom: 1px solid #82ba32;
  }
`;

// REVIEWS

export const ProdReviews = styled.div``;
export const ProdReview = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid #9d9999;
  /* padding-bottom: 1rem; */
`;
export const ReviewerTitle = styled.p`
  font-size: 15px;
  margin-bottom: 0.4rem;

  @media screen and (max-width: 800px) {
    font-size: 13px;
  }
`;
export const ReviewOptions = styled.div`
  font-size: 13px;
  font-weight: 300;
  line-height: 2rem;

  @media screen and (max-width: 800px) {
    line-height: 1rem;
  }
`;
export const ReviewOption = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
  }
`;
export const ReviewStars = styled.p`
  display: flex;
  align-items: center;
`;
export const ReviewOptionTitle = styled.p`
  /* width: 80px; */
  min-width: 100px;
  margin-right: 1rem;
`;
export const ProdReviewRateIcons = styled.div`
  display: flex;
  margin-right: 1.5rem;
`;
export const ProdReviewRateIcon = styled.img`
  cursor: pointer;
  width: 15px;
  height: 17px;
`;
// export const ProdReviewIcon = styled.i`
export const Stars = styled.i`
  cursor: pointer;
  width: 15px;
  height: 17px;
`;
export const ReviewOptionText = styled.p`
  line-height: 1rem;
`;
export const MyReviewRateIcon = styled.img`
  cursor: pointer;
  margin-right: 0.2rem;
  height: 18px;
`;

// MY REVIEW
export const MyReview = styled.div`
  /* display: flex; */
  display: grid;
  margin-top: 2rem;
  border-bottom: 1px solid #9d9999;
  padding-bottom: 2rem;

  @media screen and (max-width: 800px) {
    display: grid;
  }
`;

// REVIEWER STAR INPUTS START

export const SWidget = styled.div`
  display: flex;
  align-items: center;
`;

export const SInput = styled.input`
  display: none;
`;

export const SLabel = styled.label`
  font-size: 25px;
  color: #444;
  padding: 0 10px 20px 0;
  transition: all 0.2s ease;

  @media (max-width: 580px) {
    font-size: 20px;
  }
`;

// REVIEWER STAR INPUTS END

export const ProdReviewInput = styled.div`
  margin-top: 2rem;
  width: 400px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ProdRatingTitle = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 1rem;
`;
export const ReviewForm = styled.div``;
export const InputValue = styled.div``;
export const ReviewRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
export const ReviewButton = styled.button`
  color: #fff;
  width: 130px;
  height: 35px;
  border-radius: 1rem;
  background-color: #82ba32;
  border: 1px solid #9d9999;
  font-size: 12px;
  transition: all 0.3s ease-in;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;
export const NLabel = styled.label`
  font-size: 15px;
  font-weight: 300;
  margin-left: 0.5rem;
`;
export const NInput = styled.input`
  margin-right: 15px;
  /* width: 300px; */
  width: 615px;
  height: 30px;
  outline: none;
  padding-left: 0.3rem;
  font-weight: 300;
  border: 1px solid #9d9999;

  @media screen and (max-width: 800px) {
    /* width: 170px; */
    width: 356px;
  }
`;
export const RInput = styled.textarea`
  width: 615px;
  height: 120px;
  outline: none;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  font-weight: 300;
  border: 1px solid #9d9999;

  @media screen and (max-width: 800px) {
    width: 356px;
  }
`;

export const ShareBtn = styled.button`
  background-color: transparent;
  border: 1px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 15px;
  cursor: pointer;
`;

export const ProductNull = styled.div`
  background-color: #f4f4f4;
  height: 300px;
  display: grid;
  place-items: center;
  margin: 1rem 2rem;
  @media screen and (max-width: 950px) {
    margin: 1rem 0.5rem;
  }
`;
export const OrderSummeryNull = styled.div`
  background-color: #f4f4f4;
  height: 120px;
  display: grid;
  place-items: center;
  margin: 1rem 2rem;
  @media screen and (max-width: 950px) {
    margin: 1rem 0.5rem;
  }
`;

// PRODUCT ATTRIBUTES

export const ItemVariations = styled.div`
  /* styles for item-variations */
`;

export const ItemAttributes = styled.div`
  border-bottom: 1px solid #d3d0d0;
  padding: 1.5rem 0;
`;

export const ItemAttributeTitle = styled.p`
  font-size: small;
  margin-bottom: 8px;
`;

export const ItemAttributeOptions = styled.div`
  display: flex;
  gap: 8px;
`;

export const ItemAttributeColor = styled.button`
  height: 40px;
  width: 40px;
  border: 1px solid #9d9999;
  border-radius: 10px;
  cursor: pointer;
  /* background-color: #cea2fd; */

  &:hover {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  }
`;

export const ItemAttributeSize = styled.button`
  height: 40px;
  width: 40px;
  border: 1px solid #9d9999;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  display: grid;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  }
`;

export const ItemAttributeMaterial = styled.button`
  height: 40px;
  /* width: 40px; */
  padding: 0 5px;
  border: 1px solid #9d9999;
  border-radius: 10px;
  cursor: pointer;
  font-size: 10px;
  background-color: white;
  display: grid;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  }
`;
