import styled, { keyframes } from "styled-components";

///////////////////////////
const slideInFromBottom = keyframes`
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`;

export const LoginSection = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  z-index: 1001;
  animation: ${slideInFromBottom} 0.4s ease-in-out forwards;
`;

export const LoginDivs = styled.div`
  width: 380px;
`;

export const LoginDiv1 = styled.div`
  background-color: green;
  color: white;
  padding: 3rem;
  text-align: center;

  h4 {
    margin-bottom: 6px;
    font-weight: 400;
  }

  p {
    font-size: 11px;
    font-weight: 300;
  }
`;
export const LoginDiv2 = styled.div`
  background-color: white;
  padding: 2rem 0;
`;
export const SlideControlsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RemoveIcon = styled.i`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

export const AccountForm = styled.form`
  display: grid;
  padding: 2rem 4rem;

  label {
    font-size: 14px;
    margin-bottom: 2px;
  }
`;

export const AccountInput = styled.input`
  height: 35px;
  outline: none;
  margin-bottom: 16px;
  padding: 5px;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;
`;

export const LoginBtn = styled.button`
  display: grid;
  place-items: center;

  width: 100%;
  height: 35px;
  background-color: #2faa04;
  border: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;

export const Eyeslash = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;
  outline: none;
  margin-bottom: 16px;
  padding: 5px;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;
`;

export const PassInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;
export const SearchIcon = styled.img`
  cursor: pointer;
  width: 25px;
`;
///////////////////////////

// export const LoginDiv = styled.div`
//   display: grid;
//   place-items: center;

//   position: absolute;
//   left: 50%;
// `;

// Define the keyframes animation
// const slideInFromBottom = keyframes`
//   0% {
//     bottom: 0%;
//     /* left: 0%; */
//   }
//   100% {
//     bottom: 100%;
//     left: 50%;
//   }
// `;

// export const LoginDiv = styled.div`
//   display: grid;
//   place-items: center;

//   position: fixed;
//   left: 50%;

//   z-index: 1001;

//   animation: ${slideInFromBottom} 0.4s ease-in-out forwards;
// `;

export const LoginFormDiv = styled.div`
  position: absolute;
  top: 6rem;
  width: 400px;
  min-height: 380px;
  background-color: #fff;
  padding: 2rem 0;

  /* box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); */
  display: grid;
  place-items: center;

  @media screen and (max-width: 950px) {
    width: 350px;
    min-height: 350px;
    top: 8rem;
  }
`;

// export const LoginForm = styled.form`
//   display: grid;
//   justify-content: center;
// `;

export const LoginHeadTxt = styled.div`
  display: grid;
  justify-content: center;
`;
export const LoginLabel = styled.p`
  font-size: 16px;
  margin-bottom: 12px;
  /* margin-bottom: 32px; */
`;
// export const LoginInputLabel = styled.label`
//   color: #9d9999;
//   font-size: 12px;
// `;
// export const LoginInput = styled.input`
//   height: 35px;
//   border: solid 1px #9d9999;
//   outline: none;
//   margin-bottom: 16px;
//   padding: 5px;
// `;

// export const LoginBtn = styled.button`
//   width: 100%;
//   height: 35px;
//   background-color: #2faa04;
//   border: none;
//   color: #fff;
//   cursor: pointer;

//   &:hover {
//     background-color: green;
//   }

//   @media screen and (max-width: 950px) {
//     &:hover {
//       background-color: #82ba32;
//     }
//   }
// `;

// eye
// export const Eyeslash = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   height: 35px;
//   border: solid 1px #9d9999;
//   outline: none;
//   margin-bottom: 16px;
//   padding: 5px;
// `;

// export const PassInput = styled.input`
//   width: 100%;
//   height: 100%;
//   border: none;
//   outline: none;
// `;
// export const SearchIcon = styled.img`
//   cursor: pointer;
//   width: 25px;
// `;

// remove button

export const RemoveItemaDiv = styled.div`
  position: absolute;
  top: 4rem;
  width: 400px;
  background-color: #fff;
  display: flex;
  justify-content: end;
  padding: 5px;
  z-index: 1001;

  @media screen and (max-width: 950px) {
    margin-top: 0.5rem;
    width: 350px;
    top: 5.5rem;
  }
`;
export const RemoveItem = styled.div`
  width: 25px;
  height: 25px;
  font-weight: 300;
  border: 1px solid #fff;
  color: #9d9999;

  &:hover {
    font-weight: 500;
    border: 1px solid #9d9999;
    color: black;
  }
  display: grid;
  place-items: center;
`;

// slide control

export const SlideControls = styled.div`
  position: relative;
  width: 50%;
  height: 30px;
  display: flex;
  justify-content: center;
  border: 1px solid lightgrey;
  overflow: hidden;
`;

export const Slide = styled.label`
  height: 100%;
  width: 100%;
  color: ${(props) => (props.signup ? "#fff" : "#000")};
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  display: grid;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
`;

export const SliderTab = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  left: ${(props) => (props.signup ? "50%" : "0")};
  z-index: 0;
  /* background: -webkit-linear-gradient(left, #82ba32, #66a62e, #4b8a28, #2f6e23); */
  background-color: green;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
`;
