import styled from "styled-components";

export const OrderCompleteDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  place-items: center;
  z-index: 999;
`;
export const OrderCompleted = styled.div`
  width: 450px;
  height: 450px;
  background-color: #fff;

  text-align: center;

  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  /* transition: all 1s ease-in; */

  @media screen and (max-width: 950px) {
    width: 300px;
  }
`;

export const CompleteHead = styled.p`
  margin: 3.5rem 0 2rem 0;
  font-size: 20px;
  font-weight: 500;
`;
export const CompleteIconDiv = styled.div`
  display: grid;
  justify-content: center;
  margin: 1rem;
`;
export const CompleteIcon = styled.p`
  background-color: green;
  color: #fff;
  width: 60px;
  border: 1px solid;
  border-radius: 50%;
  font-size: 40px;
`;
export const OrderThankText = styled.p`
  font-size: 15px;
`;
export const OrderId = styled.p`
  margin-bottom: 10px;
  font-size: 15px;
`;
export const OrderText = styled.p`
  margin-bottom: 10px;
  color: #9d9999;
  font-size: 15px;
  padding: 16px;
`;
export const OrderCompleteBtn = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 20px;
  background-color: #82ba32;
  border: none;
  color: #fff;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;
