import React from "react";
import Spinner from "../../images/spinner.gif";
import {
  AccountForm,
  AccountInput,
  LoginBtn,
  LoginDiv1,
  LoginDiv2,
  LoginDivs,
  LoginSection,
  RemoveIcon,
} from "./LoginElements";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ForgotPassword = () => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passType, setPassType] = useState("password");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  return (
    <LoginSection style={{ border: "1px solid black" }}>
      <LoginDivs>
        <LoginDiv1>
          <h4>Welcome to wise cart</h4>
          <p>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commenly used to demonstrate
          </p>
        
        </LoginDiv1>
        <LoginDiv2>
          <AccountForm>
            <label htmlFor="">New Password</label>
            <AccountInput
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="">Confirm New Password</label>
            <AccountInput
              type="password"
              name="newPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <LoginBtn type="submit">
              {isLoadingLogin ? (
                <img className="button-loading" src={Spinner} alt="" />
              ) : (
                "Submit"
              )}
            </LoginBtn>
          </AccountForm>
        </LoginDiv2>
      </LoginDivs>
    </LoginSection>
  );
};

export default ForgotPassword;
