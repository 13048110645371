import React from "react";
import styled from "styled-components";

const CategoryBtn = styled.button`
  display: grid;
  place-items: start;
  align-items: center;
  padding-left: 10px;

  color: ${(props) => (props.isSelected ? "#2faa04" : "black")};
  background-color: #efefef;

  border: none;
  width: 200px;
  height: 50px;
  border-bottom: 1px solid #9d9999;
  font-size: 15px;
  position: relative;
  cursor: pointer;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #2faa04;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
`;

const RightTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 12px solid #efefef;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
`;

const CategoryButton = ({ category, onClick, isSelected }) => {
  return (
    <CategoryBtn onClick={onClick} isSelected={isSelected}>
      {category.name}
      {isSelected && <RightTriangle></RightTriangle>}
    </CategoryBtn>
  );
};

export default CategoryButton;
