import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { FaHeart } from "react-icons/fa";
import { scrollToTop } from "../Home/Home";
import { useAddToCart } from "../../hook/useAddToCart";
import { useWishList } from "../../hook/useWishList";
import { QueryClient } from "@tanstack/react-query";

import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import Spinner from "../../images/spinner.gif";
import RateBlank from "../../images/rating-blank.png";
import RateYello from "../../images/rating-yellow.png";
import {
  ActualPrice,
  FavDiv,
  PriceReview,
  ProductButtons,
  ProductCardWrapper,
  ProductCategory,
  ProductDetails,
  ProductImg,
  ProductList,
  ProductName,
  ProductPrice,
  ProductRate,
  StarIcon,
} from "../Home/HomeElements";
import {
  ProductDetail,
  ProductItem,
  ProductListing,
} from "./AllProductsElements";
import { ProdRateIcon } from "../Product/ProductElements";
import {
  useCartQuery,
  useFeaturedQuery,
  useProductsQuery,
  useReviewsQuery,
  useWishlistQuery,
} from "../../queries/api";
import { useSelector } from "react-redux";
import { PriceTitle } from "../ProductList/ProductsListElements";
import { FaHeart } from "react-icons/fa";

const AllProducts = ({ data, responsive, shop, alterProductLoading }) => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  // STATE FOR GETTING WISHLIST AND ADD TO CART
  const [handledId, setHandledId] = useState(null);

  // FETCHING API DATA
  const { data: productsReviews } = useReviewsQuery();
  const { data: cartList, isLoading: cartLoading } = useCartQuery();
  const { data: wishList, isLoading: wishLoading } = useWishlistQuery();
  const { isLoading: productLoading } = useProductsQuery();
  const { isLoading: featuredLoading } = useFeaturedQuery();
  const products = data || [];

  // GET FILTER ATTRIBUTE FROM REDUX
  const { renderPage } = useSelector((state) => state.render);
  const attributeProductLoading = renderPage?.attributeProductsLoading?.loading;

  // IMPORTED HOOKS FUNCTIONS
  const { addToCart, cartLoadingBtn } = useAddToCart();
  const { addToWishList, wishLoadingBtn, wishId, deleteWishList } =
    useWishList();

  // const wishListKey = !wishLoading && wishList?.wishlist_data;

  // CALCULATE AVERAGE RATINGS
  const calculateAverageRating = (productId) => {
    const productRatings = productsReviews?.filter(
      (rating) => rating.product_id === productId
    );
    const totalRatings = productRatings?.length;
    const sumRatings = productRatings?.reduce(
      (acc, { rating }) => acc + rating,
      0
    );
    const averageRating = sumRatings / totalRatings;
    return Math.round(averageRating);
  };

  // CAROUSEL RESPONSIVE STYLE
  const productRes = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  // HANDLE ADD TO CART ITEM
  const handleAddToCart = (item) => {
    setHandledId(item);
    if (cartLoadingBtn || cartLoading) {
      return;
    }

    // const fetchGId = cartList?.cart_id && cartList?.cart_data[0]?.ID;
    const fetchGId = cartList?.status && cartList?.cart_data[0]?.ID;
    const guestId = localStorage.getItem("cartId");
    const userId = localStorage.getItem("userId");

    const data = {
      product_id: item.id,
      quantity: 1,
      cart_id: fetchGId || guestId,
      user_id: userId,
      variation_id: 0,
    };
    addToCart(data);
  };

  // HANDLE ADD WISHLIST ITEM
  const handleAddToWishList = (item) => {
    setHandledId(item);
    const fetchWishId =
      wishList.wishlist_id !== false && wishList?.wishlist_data[0]?.ID;
    const userId = localStorage.getItem("userId");
    if (!userId || userId === null) {
      navigate("/login");
    }
    const data = {
      product_id: item.id,
      user_id: userId,
      wishlist_id: fetchWishId,
    };
    addToWishList(data);
    queryClient.invalidateQueries("wishlist");

    // setHandledId();
  };

  // HANDLE DELETE WISHLIST ITEM
  const handleDeleteWishList = (isFav, item) => {
    setHandledId(item);
    const fetchWishId =
      wishList.wishlist_id !== false && wishList?.wishlist_data[0]?.ID;
    const data = {
      product_key: isFav.ID,
      wishlist_id: fetchWishId,
    };

    deleteWishList(data);
    queryClient.invalidateQueries("wishlist");
    // setHandledId();
  };

  return (
    <>
      {shop ? (
        <ProductListing>
          {productLoading || attributeProductLoading ? (
            <>
              {Array.from({ length: 4 }).map((_, index) => (
                <ProductItem key={index}>
                  <Skeleton className="skeleton-product" />
                </ProductItem>
              ))}
            </>
          ) : (
            <>
              {products.length === 0 ? (
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <PriceTitle style={{ color: "#9d9999" }}>
                    No products match your selected filters.
                  </PriceTitle>
                </div>
              ) : (
                <>
                  {products?.map((items, index) => {
                    const averageRating = calculateAverageRating(items.id);
                    const fullStars = [];
                    const emptyStars = [];

                    for (let i = 0; i < 5; i++) {
                      if (i < averageRating) {
                        fullStars.push(
                          <StarIcon key={i} src={RateYello} alt="filled star" />
                        );
                      } else {
                        emptyStars.push(
                          <StarIcon key={i} src={RateBlank} alt="empty star" />
                        );
                      }
                    }
                    const guestId = localStorage.getItem("cartId");
                    const userId = localStorage.getItem("userId");
                    const isCart =
                      (userId || guestId || !cartLoading) &&
                      cartList?.cart_items?.find((item) => {
                        const parseProductId = parseInt(item.product_id);
                        return parseProductId === items.id;
                      });
                    // const isFav =
                    //   !wishLoading &&
                    //   wishList?.wishlist_items?.find(
                    //     (item) =>
                    //       item?.product_id == items?.id || items?.id == wishId
                    //   );
                    const category = items?.categories[0]?.slug;
                    const productName = items?.name
                      .replace(/\s/g, "-")
                      ?.toLowerCase();

                    const isFav =
                      !wishLoading &&
                      wishList?.wishlist_items?.find((item) => {
                        const parseProductId = parseInt(item.product_id);
                        return parseProductId === items?.id;
                      });

                    return (
                      <ProductItem key={index}>
                        <ProductImg>
                          <img
                            className="img-object"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/product/${category}/${productName}/${items?.id}`
                              );
                              scrollToTop();
                            }}
                            src={items?.images[0]?.src}
                            alt=""
                          />

                          <ProductButtons className="product-buttons">
                            {isCart ? (
                              <button
                                onClick={() => {
                                  navigate("/cart");
                                  scrollToTop();
                                }}
                              >
                                Go to Cart
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  // handleAddToCart(items);
                                  scrollToTop();
                                  navigate(
                                    `/product/${category}/${productName}/${items?.id}`
                                  );
                                }}
                              >
                                {cartLoadingBtn &&
                                items?.id === handledId?.id ? (
                                  <img
                                    className="button-loading"
                                    src={Spinner}
                                    alt=""
                                  />
                                ) : (
                                  "Add to cart"
                                )}
                              </button>
                            )}

                            {/* <button
                              onClick={() => {
                                scrollToTop();
                                navigate(
                                  `/product/${category}/${productName}/${items?.id}`
                                );
                              }}
                            >
                              Buy now
                            </button> */}
                            <button>
                              {/* {wishLoadingBtn || wishLoading ? ( */}
                              {wishLoadingBtn && items?.id === handledId?.id ? (
                                <img
                                  style={{ width: "20px" }}
                                  src={Spinner}
                                  alt=""
                                />
                              ) : (
                                <FaHeart
                                  onClick={() => {
                                    if (!wishLoadingBtn && userId) {
                                      if (isFav) {
                                        handleDeleteWishList(isFav, items);
                                      } else {
                                        handleAddToWishList(items);
                                      }
                                    } else {
                                      navigate("/login");
                                    }
                                  }}
                                  style={{
                                    color: isFav ? "red" : "whitesmoke",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                              )}
                            </button>
                          </ProductButtons>
                        </ProductImg>

                        <ProductDetail>
                          <ProductCategory>
                            {items?.categories[0]?.slug}
                          </ProductCategory>
                          <ProductName title={items.name}>
                            {items.name.split("").slice(0, 16).join("")}...
                          </ProductName>
                          <PriceReview>
                            <ProductPrice>
                              $
                              {items.type === "variable"
                                ? items.variable_min_price
                                : items.sale_price}
                              <ActualPrice>{items.regular_price}</ActualPrice>
                            </ProductPrice>
                            <ProductRate>
                              {fullStars}
                              {emptyStars}
                            </ProductRate>
                          </PriceReview>
                        </ProductDetail>
                      </ProductItem>
                    );
                  })}
                </>
              )}
            </>
          )}
        </ProductListing>
      ) : (
        <ProductList>
          {productLoading || featuredLoading || alterProductLoading ? (
            <Carousel responsive={responsive || productRes}>
              {Array.from({ length: 4 }).map((_, index) => (
                <ProductCardWrapper key={index}>
                  <Skeleton className="skeleton-product" />
                </ProductCardWrapper>
              ))}
            </Carousel>
          ) : (
            <Carousel responsive={responsive || productRes}>
              {products?.map((items, index) => {
                const averageRating = calculateAverageRating(items.id);
                const fullStars = [];
                const emptyStars = [];

                for (let i = 0; i < 5; i++) {
                  if (i < averageRating) {
                    fullStars.push(
                      // <StarIcon key={i} src={RateYello} alt="filled star" />
                      <ProdRateIcon
                        style={{ fontSize: "13px" }}
                        key={i}
                        className="fa-solid fa-star"
                      ></ProdRateIcon>
                    );
                  } else {
                    emptyStars.push(
                      // <StarIcon key={i} src={RateBlank} alt="empty star" />
                      <ProdRateIcon
                        style={{ color: "#9d9999", fontSize: "13px" }}
                        key={i}
                        className="fa-regular fa-star"
                      ></ProdRateIcon>
                    );
                  }
                }
                const guestId = localStorage.getItem("cartId");
                const userId = localStorage.getItem("userId");
                const isCart =
                  (userId || guestId || !cartLoading) &&
                  cartList?.cart_items?.find((item) => {
                    const parseProductId = parseInt(item.product_id);
                    return parseProductId === items.id;
                  });
                const isFav =
                  !wishLoading &&
                  wishList?.wishlist_items?.find((item) => {
                    const parseProductId = parseInt(item.product_id);
                    const parseWishId = parseInt(wishId);
                    return (
                      parseProductId === items?.id || items?.id === parseWishId
                    );
                  });
                const category = items?.categories[0]?.slug;
                const productName = items?.name
                  .replace(/\s/g, "-")
                  ?.toLowerCase();
                return (
                  <ProductCardWrapper key={index}>
                    <ProductImg>
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/product/${category}/${productName}/${items?.id}`
                          );
                          scrollToTop();
                        }}
                        src={items?.images[0]?.src}
                        alt=""
                      />

                      <ProductButtons className="product-buttons">
                        {isCart ? (
                          <button
                            onClick={() => {
                              navigate("/cart");
                              scrollToTop();
                            }}
                          >
                            Go to Cart
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              // handleAddToCart(items);
                              scrollToTop();
                              navigate(
                                `/product/${category}/${productName}/${items?.id}`
                              );
                            }}
                          >
                            {cartLoadingBtn && items?.id === handledId?.id ? (
                              <img
                                className="button-loading"
                                src={Spinner}
                                alt=""
                              />
                            ) : (
                              "Add to cart"
                            )}
                          </button>
                        )}

                        <button>
                          {/* {wishLoadingBtn || wishLoading ? ( */}
                          {wishLoadingBtn && items?.id === handledId?.id ? (
                            <img
                              style={{ width: "20px" }}
                              src={Spinner}
                              alt=""
                            />
                          ) : (
                            <FaHeart
                              onClick={() => {
                                if (!wishLoadingBtn && userId) {
                                  if (isFav) {
                                    handleDeleteWishList(isFav, items);
                                  } else {
                                    handleAddToWishList(items);
                                  }
                                } else {
                                  navigate("/login");
                                }
                              }}
                              style={{
                                color: isFav ? "red" : "whitesmoke",
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </button>
                      </ProductButtons>
                    </ProductImg>

                    <ProductDetails>
                      <ProductCategory>
                        {items?.categories[0]?.slug}
                      </ProductCategory>
                      <ProductName title={items.name}>
                        {items.name.split("").slice(0, 16).join("")}...
                      </ProductName>
                      <PriceReview>
                        <ProductPrice>
                          $
                          {items.type === "variable"
                            ? items.variable_min_price
                            : items.sale_price}
                          <ActualPrice>{items.regular_price}</ActualPrice>
                        </ProductPrice>
                        <ProductRate>
                          {fullStars}
                          {emptyStars}
                        </ProductRate>
                      </PriceReview>
                    </ProductDetails>
                  </ProductCardWrapper>
                );
              })}
            </Carousel>
          )}
        </ProductList>
      )}
    </>
  );
};

export default AllProducts;
