import React from "react";
import OrderComplete from "../components/OrderComplete/OrderComplete";
import { Overlay } from "../components/Navbar/NavbarElements";

const OrderCompletePage = () => {
  return (
    <div>
      <Overlay style={{ top: "0" }} />
      <OrderComplete />
    </div>
  );
};

export default OrderCompletePage;
