import React from "react";
import Footer from "../components/Footer/Footer";
import { InnerBanner } from "../components/Home/Home";
import MyProfile from "../components/MyProfile/MyProfile";
import Navbar from "../components/Navbar/Navbar";

const MyProfilePage = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner />
      <MyProfile />
      <Footer />
    </div>
  );
};

export default MyProfilePage;
