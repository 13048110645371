import styled from "styled-components";

// my profile secotion start
export const ProfileSection = styled.div`
  background-color: gainsboro;
  display: grid;
  place-items: center;
  gap: 10px;
  width: 100%;

  padding: 1rem 0;
`;
export const ProfilePic = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #9d9999;
  object-fit: fill;
`;
export const OptionsBtnSection = styled.div`
  display: grid;
  width: 100%;
`;
export const OptionsButton = styled.button`
  height: 55px;
  border: none;
  border-bottom: 1px solid #9d9999;
  text-transform: uppercase;
  text-align: start;
  padding: 0 1rem;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
`;
// my profile secotion end

// export const ProfileDiv = styled.div`
//   display: flex;
//   justify-content: start;
//   margin: 2rem 4rem;
//   gap: 2rem;
//   border-bottom: 1px solid #9d9999;
//   padding-bottom: 8rem;

//   @media screen and (max-width: 950px) {
//     display: grid;
//     padding: 0 1rem;
//     margin: 0;
//     margin-top: 2rem;
//     border-bottom: none;
//   }
// `;
export const ProfileDiv = styled.div`
  display: flex;
  justify-content: start;
  margin: 2rem 4rem;
  gap: 2rem;
  border-bottom: 1px solid #9d9999;
  padding-bottom: 8rem;

  @media screen and (max-width: 950px) {
    display: block;
    padding: 0 1rem;
    margin: 0;
    margin-top: 2rem;
    border-bottom: none;
  }
`;
export const ProfileOptions = styled.div`
  flex: 1;
`;

export const ProfileOption = styled.div`
  flex: 4;
  height: 280px;

  @media screen and (max-width: 950px) {
    margin-left: 0;
    margin-top: 1rem;
    height: auto;
  }
`;

export const SaveMe = styled.button`
  background-color: #82ba32;
  color: #fff;
  border: 1px solid #9d9999;
  border-radius: 1rem;
  transition: all 0.3s ease-in;
  padding: 0.6rem 1rem;
  font-size: 13px;
  margin-top: 1rem;
  margin-right: 1rem;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    margin-top: 0;
    &:hover {
      background-color: #82ba32;
    }
  }
`;
// MY ORDERS

export const OrderCards = styled.div`
  display: grid;
  gap: 10px;
  overflow-y: auto;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const OrderCard = styled.div`
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding: 8px 16px;
  width: 85%;
  height: fit-content;
  border-radius: 2px;
  box-shadow: 0px 5px 10px -5px #d0d0d0;
`;

export const OrderImg = styled.img`
  /* width: 35px; */
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const OrderContentDiv = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;

  p {
    font-size: 14px;
    font-weight: 600;
  }
`;
export const OrderPrice = styled.div`
  p {
    font-size: 13px;
  }
`;

export const OrderStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  i {
    color: #50782b;
    font-size: 12px;
  }
  p {
    font-size: 13px;
  }
`;

// export const ProfileCartProducts = styled.div`
//   overflow-y: scroll;
//   overflow-x: hidden;
//   height: 290px;
//   min-width: 350px;
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   @media screen and (max-width: 950px) {
//     height: 230px;
//   }
// `;
// export const ProfileProduct = styled.div`
//   display: flex;
//   justify-content: space-between;
//   border-bottom: 1px solid #9d9999;
//   padding: 8px;
//   height: 100px;

//   @media screen and (max-width: 950px) {
//     justify-content: flex-start;
//     gap: 10px;
//     padding: 4px;
//   }
// `;
// export const OrderedImg = styled.img`
//   width: 80px;
//   height: 80px;
//   margin: 0 1rem 0 0;
//   object-fit: contain;

//   @media screen and (max-width: 950px) {
//     width: 60px;
//     height: 60px;
//     margin: 0 5px 0 0;
//   }
// `;
// export const OrderDiv = styled.div`
//   display: flex;
//   @media screen and (max-width: 950px) {
//     display: grid;
//   }
// `;
// export const ProfileProductText = styled.div``;
// export const ProfileProductTitle = styled.div`
//   font-weight: 600;
//   @media screen and (max-width: 950px) {
//     font-size: 13px;
//   }
// `;
// export const ProfileProductDesc = styled.p`
//   font-weight: 300;
//   font-size: 13px;
//   width: 300px;

//   @media screen and (max-width: 950px) {
//     width: 100px;
//     padding-right: 2px;
//     font-size: 11px;
//   }
// `;
// export const ProfileProductPrice = styled.p`
//   width: 200px;

//   @media screen and (max-width: 950px) {
//     width: 60px;
//     font-size: 11px;
//   }
// `;
// export const ProfileProductStatus = styled.div``;
// export const Status = styled.div`
//   display: flex;
//   align-items: center;
//   width: 300px;

//   @media screen and (max-width: 950px) {
//     width: 100%;
//   }
// `;
// export const StatusPoint = styled.i`
//   /* color: green; */
//   font-size: 10px;

//   @media screen and (max-width: 950px) {
//     font-size: 8px;
//   }
// `;
// export const StatusDate = styled.div`
//   font-size: 14px;
//   margin-left: 8px;

//   @media screen and (max-width: 950px) {
//     margin-left: 2px;
//     font-size: 10px;
//   }
// `;
// export const StatusMessage = styled.div`
//   font-size: 14px;
//   font-weight: 300;
//   margin-left: 1.5rem;
//   margin-top: 0.5rem;

//   @media screen and (max-width: 950px) {
//     font-size: 10px;
//     margin-top: 0;
//     margin-left: 11px;
//   }
// `;

// MY PROFILE
export const MyProfileDiv = styled.div``;
export const MyProfileHead = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const InputsDiv = styled.div`
  display: flex;

  @media screen and (max-width: 950px) {
    display: grid;
  }
`;
export const Container = styled.div``;

export const CountryCodeSelect = styled.select`
  width: 80px;
  height: 2.5rem;
  padding-left: 8px;
  border: 1px solid #9d9999;
  font-size: 15px;
  font-weight: 300;
`;

export const ProfileInputPhone = styled.input`
  width: 220px;
  height: 2.5rem;
  padding-left: 8px;
  margin-right: 32px;
  border: 1px solid #9d9999;
  font-size: 15px;
  font-weight: 300;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 8px;
    margin-right: 0;
  }
`;

export const ProfileInput = styled.input`
  width: 300px;
  height: 2.5rem;
  padding-left: 8px;
  margin-right: 32px;
  /* border: 1px solid #9d9999; */
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 8px;
    margin-right: 0;
  }
`;

export const GenderDiv = styled.div`
  margin-top: 25px;
  color: #9d9999;
  font-size: 15px;
`;
export const MyProfileGender = styled.p`
  margin-bottom: 10px;
`;
export const GenderInput = styled.input`
  margin-left: 16px;
`;
export const GenderInputLabel = styled.label`
  margin-left: 8px;
`;

// MY PROFILE LOGGEDIN
export const MyProfileDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 13px;
`;
export const MyProfileName = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-right: 0.5rem;
  text-transform: uppercase;
`;
export const UserEditIcon = styled.i`
  margin-left: 1rem;
  font-size: 15px;
  cursor: pointer;
`;
export const MyProfileDetail = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin-right: 1rem;
`;

// CHANGE PASSWORD
export const ChangePassword = styled.div`
  label {
    font-size: 13px;
  }
`;
export const ChangePassInput = styled.input`
  width: 300px;
  /* height: 45px; */
  height: 40px;
  padding-left: 8px;
  margin-right: 32px;
  /* border: 1px solid #9d9999; */
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 32px;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }
`;
export const ChangePassButton = styled.button`
  background-color: #82ba32;
  color: #fff;
  border: 1px solid #9d9999;
  border-radius: 1.5rem;
  transition: all 0.3s ease-in;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;

// ADDRESS
export const AddressDiv = styled.div``;
export const ButtonDiv = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #9d9999;
`;
export const AddressHeadText = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;
export const SelectAddresses = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 210px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const SelectAddressDiv = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #9d9999;
  display: flex;
  justify-content: space-between;
`;
export const SelectAddress = styled.div``;
export const DefaultButton = styled.button`
  background-color: #82ba32;
  color: #fff;
  border: 1px solid #9d9999;
  border-radius: 1.5rem;
  transition: all 0.3s ease-in;
  font-weight: 300;
  font-size: 12px;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #82ba32;
    }
  }
`;
export const SelectAddressIcons = styled.div`
  width: 45px;
  display: flex;
  justify-content: space-around;
`;
export const AddressHead = styled.p`
  font-weight: 400;
  font-size: 15px;
`;
export const AddressNumber = styled.p`
  font-size: 14px;
  font-weight: 300;
`;
export const AddressText = styled.p`
  font-size: 14px;
  font-weight: 300;
`;

export const AddressButton = styled.button`
  background-color: #2faa04;
  color: #fff;
  border: 1px solid #9d9999;
  transition: all 0.3s ease-in;
  padding: 5px 16px;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    &:hover {
      background-color: #2faa04;
    }
  }
`;

// ADDRESS FORM

// address

export const AddAddressDiv = styled.div``;
export const AddAddressForm = styled.div``;
export const AddressNameInput = styled.input`
  width: 576px;
  height: 2.5rem;
  margin: 20px 16px 0 0;
  padding: 0 0 0 0.5rem;
  outline: none;
  border: 1px solid #9d9999;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 2.5rem;
    margin: 20px 0 0 0;
    padding: 0 0 0 0.5rem;
    outline: none;
    border: 1px solid #9d9999;
  }
`;
export const AddressLineInput = styled.textarea`
  width: 576px;
  height: 65px;
  margin: 20px 16px 0 0;
  padding: 10px 0 0 0.5rem;
  outline: none;
  /* border: 1px solid #9d9999; */
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 2.5rem;
    margin: 20px 0 0 0;
    padding: 10px 0 0 0.5rem;
    outline: none;
    border: 1px solid #9d9999;
  }
`;
export const AddressInput = styled.input`
  width: 280px;
  height: 2.5rem;
  margin: 20px 16px 0 0;
  padding: 0 0 0 0.5rem;
  outline: none;
  /* border: 1px solid #9d9999; */
  border: 1px solid #d0d0d0;
  box-shadow: 0px 5px 10px -5px #d0d0d0;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 2.5rem;
    margin: 20px 0 0 0;
    padding: 0 0 0 0.5rem;
    outline: none;
    border: 1px solid #9d9999;
  }
`;
export const AddressTextInput = styled.input`
  width: 576px;
  height: 80px;
  margin: 20px 16px 0 0;
  padding: 0.5rem 0 0 0.5rem;
  outline: none;
  border: 1px solid #9d9999;

  @media screen and (max-width: 950px) {
    width: 100%;
    height: 80px;
    margin: 20px 0 0 0;
    padding: 0 0 0 0.5rem;
    outline: none;
    border: 1px solid #9d9999;
  }
`;

export const AddressType = styled.div`
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  color: #9d9999;
  margin: 16px 0;
`;
export const AddressTypeLabel = styled.label``;
export const AddressInputText = styled.label`
  margin: 1rem 1rem 0 0;
`;
export const AddressTypeDiv = styled.div``;
export const AddressInputSlct = styled.input`
  margin: 1rem 0.5rem;
  width: 14px;
  height: 14px;
`;

export const ButtonsDiv = styled.div`
  margin: 0.5rem 0 2rem 0;
`;
export const AddressSaveBtn = styled.button`
  background-color: #82ba32;
  color: #fff;
  border: 1px solid #9d9999;
  border-radius: 1rem;
  transition: all 0.3s ease-in;
  padding: 0.6rem 1rem;
  font-size: 13px;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    background-color: green;
  }

  @media screen and (max-width: 950px) {
    margin-top: 0;
    &:hover {
      background-color: #82ba32;
    }
  }
`;

export const AddressCancekBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
// address

// OptionButtons
export const OptionButtons = styled.p`
  /* color: black; */
  border: 1px solid #9d9999;
  transition: all 0.1s ease-in;
  width: 240px;
  height: 43px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-weight: 300;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
`;
