import React from "react";
import Spinner from "../../images/spinner.gif";
import {
  AccountForm,
  AccountInput,
  LoginBtn,
  LoginDiv1,
  LoginDiv2,
  LoginDivs,
  LoginSection,
  RemoveIcon,
} from "./LoginElements";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ConfirmEmail = () => {
  const navigate = useNavigate();

  // STATE HOOKS
  const [email, setEmail] = useState("");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  return (
    <LoginSection style={{ border: "1px solid black" }}>
      <LoginDivs>
        <LoginDiv1>
          <h4>Welcome to wise cart</h4>
          <p>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commenly used to demonstrate
          </p>
          {/* <RemoveIcon
            onClick={() => window.history.back()}
            className="fas fa-regular fa-xmark"
          ></RemoveIcon> */}
        </LoginDiv1>
        <LoginDiv2>
          <AccountForm>
            <label htmlFor="">Email</label>
            <AccountInput
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <LoginBtn type="submit">
              {isLoadingLogin ? (
                <img className="button-loading" src={Spinner} alt="" />
              ) : (
                "Send Login Link"
              )}
            </LoginBtn>
          </AccountForm>
        </LoginDiv2>
      </LoginDivs>
    </LoginSection>
  );
};

export default ConfirmEmail;
