import React from "react";
import {
  AboutSection,
  AboutUsContent,
  AboutUsImg,
  AboutUsText,
  AboutUsTitle,
} from "./AboutUsElements";
// import About1 from "../../images/about_img.png";
import { useSettingsQuery } from "../../queries/api";
import Skeleton from "react-loading-skeleton";

const AboutUs = () => {

  const { data: settings, isLoading: settingsLoading } = useSettingsQuery();
  
  if (settingsLoading) {
    return (
      <AboutSection>
        <AboutUsTitle>
          <Skeleton />
        </AboutUsTitle>
        <AboutUsContent>
          <Skeleton />
          <AboutUsText>
            <Skeleton />
          </AboutUsText>
        </AboutUsContent>
      </AboutSection>
    );
  }
  return (
    <AboutSection>
      <AboutUsTitle>{settings?.about?.title}</AboutUsTitle>
      <AboutUsContent>
        <AboutUsImg src={settings?.about?.image} alt="..." />
        <AboutUsText>{settings?.about?.content}</AboutUsText>
      </AboutUsContent>
    </AboutSection>
  );
};

export default AboutUs;
