import styled from "styled-components";

export const ProductListing = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 40px;

  margin: 0 2rem 0 2rem;

  @media (max-width: 768px) {
    gap: 5px;
    justify-content: center;
    margin: 2rem 0 0 0;
  }
`;

export const ProductItem = styled.div`
  flex-basis: calc(33.33% - 50px);
  width: 250px;
  border: 1px solid #9d9999;
  padding: 1rem;

  display: grid;
  justify-content: center;
  align-items: center;

  .skeleton-product {
    width: 250px;
    height: 330px;
  }
  @media (max-width: 768px) {
    flex-basis: calc(50% - 8px);
    padding: 2px;

    .skeleton-product {
      width: 165px;
      height: 200px;
    }
  }
`;
export const ProductDetail = styled.div`
  text-align: start;
  width: 250px;

  @media (max-width: 950px) {
    width: 165px;
  }
`;
