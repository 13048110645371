export const login_api = "wp-json/jwt-auth/v1/token";
export const signup_api = "wp-json/wc/v3/customers";
export const customer = "wp-json/wc/v3/customers";

export const products_api = "wp-json/wc/v3/products";
export const users_api = "wp-json/wc/v3/customers";

export const cart_uid_api = "wp-json/wc/cart/list/userid";
export const cart_gid_api = "wp-json/wc/cart/list/cartid";

export const wishlist_api = "/wp-json/wc/wishlist/list/userid";

export const orders_api = "wp-json/wc/v3/orders";

export const address_api = "wp-json/wc/address";

export const reviews_api = "wp-json/wc/v3/products/reviews";
export const news_api = "wp-json/wp/v2/posts?categories=1&page=1&per_page=10";
export const categories_api = "wp-json/wc/v3/products/categories";
export const attributes_api = "wp-json/wc/attributes/list";
export const banner_api = "wp-json/wp/v2/banner";

export const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
export const consumerSecret = process.env.REACT_APP_CONSUMER_SECRETE;
export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getText = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent;
};

export const categories = [
  {
    id: 1,
    name: "Category 1",
    slug: "category-1",
    parent: 0,
    description: "Description for Category 1",
  },
  {
    id: 11,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 1,
    description: "Description for Subcategory 1 of Category 1",
  },
  {
    id: 12,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 1,
    description: "Description for Subcategory 2 of Category 1",
  },
  {
    id: 13,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 1,
    description: "Description for Subcategory 3 of Category 1",
  },
  {
    id: 14,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 1,
    description: "Description for Subcategory 4 of Category 1",
  },
  {
    id: 2,
    name: "Category 2",
    slug: "category-2",
    parent: 0,
    description: "Description for Category 2",
  },
  {
    id: 21,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 2,
    description: "Description for Subcategory 1 of Category 2",
  },
  {
    id: 22,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 2,
    description: "Description for Subcategory 2 of Category 2",
  },
  {
    id: 23,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 2,
    description: "Description for Subcategory 3 of Category 2",
  },
  {
    id: 24,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 2,
    description: "Description for Subcategory 4 of Category 2",
  },
  {
    id: 3,
    name: "Category 3",
    slug: "category-3",
    parent: 0,
    description: "Description for Category 3",
  },
  {
    id: 31,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 3,
    description: "Description for Subcategory 1 of Category 3",
  },
  {
    id: 32,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 3,
    description: "Description for Subcategory 2 of Category 3",
  },
  {
    id: 33,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 3,
    description: "Description for Subcategory 3 of Category 3",
  },
  {
    id: 34,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 3,
    description: "Description for Subcategory 4 of Category 3",
  },
  {
    id: 4,
    name: "Category 4",
    slug: "category-4",
    parent: 0,
    description: "Description for Category 4",
  },
  {
    id: 41,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 4,
    description: "Description for Subcategory 1 of Category 4",
  },
  {
    id: 42,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 4,
    description: "Description for Subcategory 2 of Category 4",
  },
  {
    id: 43,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 4,
    description: "Description for Subcategory 3 of Category 4",
  },
  {
    id: 44,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 4,
    description: "Description for Subcategory 4 of Category 4",
  },
  {
    id: 5,
    name: "Category 5",
    slug: "category-5",
    parent: 0,
    description: "Description for Category 5",
  },
  {
    id: 51,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 5,
    description: "Description for Subcategory 1 of Category 5",
  },
  {
    id: 52,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 5,
    description: "Description for Subcategory 2 of Category 5",
  },
  {
    id: 53,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 5,
    description: "Description for Subcategory 3 of Category 5",
  },
  {
    id: 54,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 5,
    description: "Description for Subcategory 4 of Category 5",
  },
  {
    id: 6,
    name: "Category 6",
    slug: "category-6",
    parent: 0,
    description: "Description for Category 6",
  },
  {
    id: 61,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 6,
    description: "Description for Subcategory 1 of Category 6",
  },
  {
    id: 62,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 6,
    description: "Description for Subcategory 2 of Category 6",
  },
  {
    id: 63,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 6,
    description: "Description for Subcategory 3 of Category 6",
  },
  {
    id: 64,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 6,
    description: "Description for Subcategory 4 of Category 6",
  },
  {
    id: 7,
    name: "Category 7",
    slug: "category-7",
    parent: 0,
    description: "Description for Category 7",
  },
  {
    id: 71,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 7,
    description: "Description for Subcategory 1 of Category 7",
  },
  {
    id: 72,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 7,
    description: "Description for Subcategory 2 of Category 7",
  },
  {
    id: 73,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 7,
    description: "Description for Subcategory 3 of Category 7",
  },
  {
    id: 74,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 7,
    description: "Description for Subcategory 4 of Category 7",
  },
  {
    id: 8,
    name: "Category 8",
    slug: "category-8",
    parent: 0,
    description: "Description for Category 8",
  },
  {
    id: 81,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 8,
    description: "Description for Subcategory 1 of Category 8",
  },
  {
    id: 82,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 8,
    description: "Description for Subcategory 2 of Category 8",
  },
  {
    id: 83,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 8,
    description: "Description for Subcategory 3 of Category 8",
  },
  {
    id: 84,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 8,
    description: "Description for Subcategory 4 of Category 8",
  },
  {
    id: 9,
    name: "Category 9",
    slug: "category-9",
    parent: 0,
    description: "Description for Category 9",
  },
  {
    id: 91,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 9,
    description: "Description for Subcategory 1 of Category 9",
  },
  {
    id: 92,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 9,
    description: "Description for Subcategory 2 of Category 9",
  },
  {
    id: 93,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 9,
    description: "Description for Subcategory 3 of Category 9",
  },
  {
    id: 94,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 9,
    description: "Description for Subcategory 4 of Category 9",
  },
  {
    id: 10,
    name: "Category 10",
    slug: "category-10",
    parent: 0,
    description: "Description for Category 10",
  },
  {
    id: 101,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 10,
    description: "Description for Subcategory 1 of Category 10",
  },
  {
    id: 102,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 10,
    description: "Description for Subcategory 2 of Category 10",
  },
  {
    id: 103,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 10,
    description: "Description for Subcategory 3 of Category 10",
  },
  {
    id: 104,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 10,
    description: "Description for Subcategory 4 of Category 10",
  },
  {
    id: 51,
    name: "Category 11",
    slug: "category-11",
    parent: 0,
    description: "Description for Category 11",
  },
  {
    id: 111,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 51,
    description: "Description for Subcategory 1 of Category 11",
  },
  {
    id: 112,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 51,
    description: "Description for Subcategory 2 of Category 11",
  },
  {
    id: 113,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 51,
    description: "Description for Subcategory 3 of Category 11",
  },
  {
    id: 114,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 51,
    description: "Description for Subcategory 4 of Category 11",
  },
  {
    id: 52,
    name: "Category 12",
    slug: "category-12",
    parent: 0,
    description: "Description for Category 12",
  },
  {
    id: 121,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 52,
    description: "Description for Subcategory 1 of Category 12",
  },
  {
    id: 122,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 52,
    description: "Description for Subcategory 2 of Category 12",
  },
  {
    id: 123,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 52,
    description: "Description for Subcategory 3 of Category 12",
  },
  {
    id: 124,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 52,
    description: "Description for Subcategory 4 of Category 12",
  },
  {
    id: 53,
    name: "Category 13",
    slug: "category-13",
    parent: 0,
    description: "Description for Category 13",
  },
  {
    id: 131,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 53,
    description: "Description for Subcategory 1 of Category 13",
  },
  {
    id: 132,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 53,
    description: "Description for Subcategory 2 of Category 13",
  },
  {
    id: 133,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 53,
    description: "Description for Subcategory 3 of Category 13",
  },
  {
    id: 134,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 53,
    description: "Description for Subcategory 4 of Category 13",
  },
  {
    id: 54,
    name: "Category 14",
    slug: "category-14",
    parent: 0,
    description: "Description for Category 14",
  },
  {
    id: 141,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 54,
    description: "Description for Subcategory 1 of Category 14",
  },
  {
    id: 142,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 54,
    description: "Description for Subcategory 2 of Category 14",
  },
  {
    id: 143,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 54,
    description: "Description for Subcategory 3 of Category 14",
  },
  {
    id: 144,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 54,
    description: "Description for Subcategory 4 of Category 14",
  },
  {
    id: 55,
    name: "Category 15",
    slug: "category-15",
    parent: 0,
    description: "Description for Category 15",
  },
  {
    id: 151,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 55,
    description: "Description for Subcategory 1 of Category 15",
  },
  {
    id: 152,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 55,
    description: "Description for Subcategory 2 of Category 15",
  },
  {
    id: 153,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 55,
    description: "Description for Subcategory 3 of Category 15",
  },
  {
    id: 154,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 55,
    description: "Description for Subcategory 4 of Category 15",
  },
  {
    id: 101,
    name: "Category 16",
    slug: "category-16",
    parent: 0,
    description: "Description for Category 16",
  },
  {
    id: 161,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 101,
    description: "Description for Subcategory 1 of Category 16",
  },
  {
    id: 162,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 101,
    description: "Description for Subcategory 2 of Category 16",
  },
  {
    id: 163,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 101,
    description: "Description for Subcategory 3 of Category 16",
  },
  {
    id: 164,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 101,
    description: "Description for Subcategory 4 of Category 16",
  },
  {
    id: 102,
    name: "Category 17",
    slug: "category-17",
    parent: 0,
    description: "Description for Category 17",
  },
  {
    id: 171,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 102,
    description: "Description for Subcategory 1 of Category 17",
  },
  {
    id: 172,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 102,
    description: "Description for Subcategory 2 of Category 17",
  },
  {
    id: 173,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 102,
    description: "Description for Subcategory 3 of Category 17",
  },
  {
    id: 174,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 102,
    description: "Description for Subcategory 4 of Category 17",
  },
  {
    id: 103,
    name: "Category 18",
    slug: "category-18",
    parent: 0,
    description: "Description for Category 18",
  },
  {
    id: 181,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 103,
    description: "Description for Subcategory 1 of Category 18",
  },
  {
    id: 182,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 103,
    description: "Description for Subcategory 2 of Category 18",
  },
  {
    id: 183,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 103,
    description: "Description for Subcategory 3 of Category 18",
  },
  {
    id: 184,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 103,
    description: "Description for Subcategory 4 of Category 18",
  },
  {
    id: 104,
    name: "Category 19",
    slug: "category-19",
    parent: 0,
    description: "Description for Category 19",
  },
  {
    id: 191,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 104,
    description: "Description for Subcategory 1 of Category 19",
  },
  {
    id: 192,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 104,
    description: "Description for Subcategory 2 of Category 19",
  },
  {
    id: 193,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 104,
    description: "Description for Subcategory 3 of Category 19",
  },
  {
    id: 194,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 104,
    description: "Description for Subcategory 4 of Category 19",
  },
  {
    id: 105,
    name: "Category 20",
    slug: "category-20",
    parent: 0,
    description: "Description for Category 20",
  },
  {
    id: 201,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 105,
    description: "Description for Subcategory 1 of Category 20",
  },
  {
    id: 202,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 105,
    description: "Description for Subcategory 2 of Category 20",
  },
  {
    id: 203,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 105,
    description: "Description for Subcategory 3 of Category 20",
  },
  {
    id: 204,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 105,
    description: "Description for Subcategory 4 of Category 20",
  },
  {
    id: 201,
    name: "Category 21",
    slug: "category-21",
    parent: 0,
    description: "Description for Category 21",
  },
  {
    id: 211,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 201,
    description: "Description for Subcategory 1 of Category 21",
  },
  {
    id: 212,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 201,
    description: "Description for Subcategory 2 of Category 21",
  },
  {
    id: 213,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 201,
    description: "Description for Subcategory 3 of Category 21",
  },
  {
    id: 214,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 201,
    description: "Description for Subcategory 4 of Category 21",
  },
  {
    id: 202,
    name: "Category 22",
    slug: "category-22",
    parent: 0,
    description: "Description for Category 22",
  },
  {
    id: 221,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 202,
    description: "Description for Subcategory 1 of Category 22",
  },
  {
    id: 222,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 202,
    description: "Description for Subcategory 2 of Category 22",
  },
  {
    id: 223,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 202,
    description: "Description for Subcategory 3 of Category 22",
  },
  {
    id: 224,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 202,
    description: "Description for Subcategory 4 of Category 22",
  },
  {
    id: 203,
    name: "Category 23",
    slug: "category-23",
    parent: 0,
    description: "Description for Category 23",
  },
  {
    id: 231,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 203,
    description: "Description for Subcategory 1 of Category 23",
  },
  {
    id: 232,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 203,
    description: "Description for Subcategory 2 of Category 23",
  },
  {
    id: 233,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 203,
    description: "Description for Subcategory 3 of Category 23",
  },
  {
    id: 234,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 203,
    description: "Description for Subcategory 4 of Category 23",
  },
  {
    id: 204,
    name: "Category 24",
    slug: "category-24",
    parent: 0,
    description: "Description for Category 24",
  },
  {
    id: 241,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 204,
    description: "Description for Subcategory 1 of Category 24",
  },
  {
    id: 242,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 204,
    description: "Description for Subcategory 2 of Category 24",
  },
  {
    id: 243,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 204,
    description: "Description for Subcategory 3 of Category 24",
  },
  {
    id: 244,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 204,
    description: "Description for Subcategory 4 of Category 24",
  },
  {
    id: 205,
    name: "Category 25",
    slug: "category-25",
    parent: 0,
    description: "Description for Category 25",
  },
  {
    id: 251,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 205,
    description: "Description for Subcategory 1 of Category 25",
  },
  {
    id: 252,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 205,
    description: "Description for Subcategory 2 of Category 25",
  },
  {
    id: 253,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 205,
    description: "Description for Subcategory 3 of Category 25",
  },
  {
    id: 254,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 205,
    description: "Description for Subcategory 4 of Category 25",
  },
  {
    id: 206,
    name: "Category 26",
    slug: "category-26",
    parent: 0,
    description: "Description for Category 26",
  },
  {
    id: 261,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 206,
    description: "Description for Subcategory 1 of Category 26",
  },
  {
    id: 262,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 206,
    description: "Description for Subcategory 2 of Category 26",
  },
  {
    id: 263,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 206,
    description: "Description for Subcategory 3 of Category 26",
  },
  {
    id: 264,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 206,
    description: "Description for Subcategory 4 of Category 26",
  },
  {
    id: 207,
    name: "Category 27",
    slug: "category-27",
    parent: 0,
    description: "Description for Category 27",
  },
  {
    id: 271,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 207,
    description: "Description for Subcategory 1 of Category 27",
  },
  {
    id: 272,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 207,
    description: "Description for Subcategory 2 of Category 27",
  },
  {
    id: 273,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 207,
    description: "Description for Subcategory 3 of Category 27",
  },
  {
    id: 274,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 207,
    description: "Description for Subcategory 4 of Category 27",
  },
  {
    id: 208,
    name: "Category 28",
    slug: "category-28",
    parent: 0,
    description: "Description for Category 28",
  },
  {
    id: 281,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 208,
    description: "Description for Subcategory 1 of Category 28",
  },
  {
    id: 282,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 208,
    description: "Description for Subcategory 2 of Category 28",
  },
  {
    id: 283,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 208,
    description: "Description for Subcategory 3 of Category 28",
  },
  {
    id: 284,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 208,
    description: "Description for Subcategory 4 of Category 28",
  },
  {
    id: 209,
    name: "Category 29",
    slug: "category-29",
    parent: 0,
    description: "Description for Category 29",
  },
  {
    id: 291,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 209,
    description: "Description for Subcategory 1 of Category 29",
  },
  {
    id: 292,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 209,
    description: "Description for Subcategory 2 of Category 29",
  },
  {
    id: 293,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 209,
    description: "Description for Subcategory 3 of Category 29",
  },
  {
    id: 294,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 209,
    description: "Description for Subcategory 4 of Category 29",
  },
  {
    id: 210,
    name: "Category 30",
    slug: "category-30",
    parent: 0,
    description: "Description for Category 30",
  },
  {
    id: 301,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 210,
    description: "Description for Subcategory 1 of Category 30",
  },
  {
    id: 302,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 210,
    description: "Description for Subcategory 2 of Category 30",
  },
  {
    id: 303,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 210,
    description: "Description for Subcategory 3 of Category 30",
  },
  {
    id: 304,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 210,
    description: "Description for Subcategory 4 of Category 30",
  },
  {
    id: 301,
    name: "Category 31",
    slug: "category-31",
    parent: 0,
    description: "Description for Category 31",
  },
  {
    id: 311,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 301,
    description: "Description for Subcategory 1 of Category 31",
  },
  {
    id: 312,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 301,
    description: "Description for Subcategory 2 of Category 31",
  },
  {
    id: 313,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 301,
    description: "Description for Subcategory 3 of Category 31",
  },
  {
    id: 314,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 301,
    description: "Description for Subcategory 4 of Category 31",
  },
  {
    id: 302,
    name: "Category 32",
    slug: "category-32",
    parent: 0,
    description: "Description for Category 32",
  },
  {
    id: 321,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 302,
    description: "Description for Subcategory 1 of Category 32",
  },
  {
    id: 322,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 302,
    description: "Description for Subcategory 2 of Category 32",
  },
  {
    id: 323,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 302,
    description: "Description for Subcategory 3 of Category 32",
  },
  {
    id: 324,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 302,
    description: "Description for Subcategory 4 of Category 32",
  },
  {
    id: 303,
    name: "Category 33",
    slug: "category-33",
    parent: 0,
    description: "Description for Category 33",
  },
  {
    id: 331,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 303,
    description: "Description for Subcategory 1 of Category 33",
  },
  {
    id: 332,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 303,
    description: "Description for Subcategory 2 of Category 33",
  },
  {
    id: 333,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 303,
    description: "Description for Subcategory 3 of Category 33",
  },
  {
    id: 334,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 303,
    description: "Description for Subcategory 4 of Category 33",
  },
  {
    id: 304,
    name: "Category 34",
    slug: "category-34",
    parent: 0,
    description: "Description for Category 34",
  },
  {
    id: 341,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 304,
    description: "Description for Subcategory 1 of Category 34",
  },
  {
    id: 342,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 304,
    description: "Description for Subcategory 2 of Category 34",
  },
  {
    id: 343,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 304,
    description: "Description for Subcategory 3 of Category 34",
  },
  {
    id: 344,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 304,
    description: "Description for Subcategory 4 of Category 34",
  },
  {
    id: 305,
    name: "Category 35",
    slug: "category-35",
    parent: 0,
    description: "Description for Category 35",
  },
  {
    id: 351,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 305,
    description: "Description for Subcategory 1 of Category 35",
  },
  {
    id: 352,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 305,
    description: "Description for Subcategory 2 of Category 35",
  },
  {
    id: 353,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 305,
    description: "Description for Subcategory 3 of Category 35",
  },
  {
    id: 354,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 305,
    description: "Description for Subcategory 4 of Category 35",
  },
  {
    id: 306,
    name: "Category 36",
    slug: "category-36",
    parent: 0,
    description: "Description for Category 36",
  },
  {
    id: 361,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 306,
    description: "Description for Subcategory 1 of Category 36",
  },
  {
    id: 362,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 306,
    description: "Description for Subcategory 2 of Category 36",
  },
  {
    id: 363,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 306,
    description: "Description for Subcategory 3 of Category 36",
  },
  {
    id: 364,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 306,
    description: "Description for Subcategory 4 of Category 36",
  },
  {
    id: 307,
    name: "Category 37",
    slug: "category-37",
    parent: 0,
    description: "Description for Category 37",
  },
  {
    id: 371,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 307,
    description: "Description for Subcategory 1 of Category 37",
  },
  {
    id: 372,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 307,
    description: "Description for Subcategory 2 of Category 37",
  },
  {
    id: 373,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 307,
    description: "Description for Subcategory 3 of Category 37",
  },
  {
    id: 374,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 307,
    description: "Description for Subcategory 4 of Category 37",
  },
  {
    id: 308,
    name: "Category 38",
    slug: "category-38",
    parent: 0,
    description: "Description for Category 38",
  },
  {
    id: 381,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 308,
    description: "Description for Subcategory 1 of Category 38",
  },
  {
    id: 382,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 308,
    description: "Description for Subcategory 2 of Category 38",
  },
  {
    id: 383,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 308,
    description: "Description for Subcategory 3 of Category 38",
  },
  {
    id: 384,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 308,
    description: "Description for Subcategory 4 of Category 38",
  },
  {
    id: 309,
    name: "Category 39",
    slug: "category-39",
    parent: 0,
    description: "Description for Category 39",
  },
  {
    id: 391,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 309,
    description: "Description for Subcategory 1 of Category 39",
  },
  {
    id: 392,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 309,
    description: "Description for Subcategory 2 of Category 39",
  },
  {
    id: 393,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 309,
    description: "Description for Subcategory 3 of Category 39",
  },
  {
    id: 394,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 309,
    description: "Description for Subcategory 4 of Category 39",
  },
  {
    id: 310,
    name: "Category 40",
    slug: "category-40",
    parent: 0,
    description: "Description for Category 40",
  },
  {
    id: 401,
    name: "Subcategory 1",
    slug: "subcategory-1",
    parent: 310,
    description: "Description for Subcategory 1 of Category 40",
  },
  {
    id: 402,
    name: "Subcategory 2",
    slug: "subcategory-2",
    parent: 310,
    description: "Description for Subcategory 2 of Category 40",
  },
  {
    id: 403,
    name: "Subcategory 3",
    slug: "subcategory-3",
    parent: 310,
    description: "Description for Subcategory 3 of Category 40",
  },
  {
    id: 404,
    name: "Subcategory 4",
    slug: "subcategory-4",
    parent: 310,
    description: "Description for Subcategory 4 of Category 40",
  },
];
