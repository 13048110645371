import styled from "styled-components";

export const ContactUs = styled.div`
  margin: 1rem 4rem;
  background-color: #ffffff;

  @media screen and (max-width: 950px) {
    margin: 1rem 1rem;
  }
`;
export const ContactTitleDiv = styled.div`
  display: grid;
  place-items: center;
  margin: 2rem 0;
`;
export const ContactTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: green;

  @media screen and (max-width: 800px) {
    font-size: 25px;
    font-weight: 600;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 800px) {
    display: grid;
  }
`;
export const ContactBox = styled.div`
  border: 1px solid black;
  padding: 2rem;
  width: 350px;

  display: grid;
  place-items: center;

  @media screen and (max-width: 950px) {
    width: 280px;
    height: 120px;
    padding: 0.5rem;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
    width: 300px;
    height: 120px;
    padding: 1rem;
  }
`;
export const ContactBoxIcon = styled.div`
  font-size: 25px;
`;
export const ContactBoxTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
export const ContactBoxText = styled.div`
  font-weight: 300;
`;

//FORM

export const ContactUsForm = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
  background-color: #fbfbfb;
`;

export const FormDiv = styled.div`
  flex: 1;
  display: grid;
  margin: 2rem;
  width: 600px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const ContactInput = styled.input`
  height: 2.5rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  outline: none;
`;
export const ContactInputMessage = styled.textarea`
  height: 10rem;
  padding: 0.5rem;
  outline: none;
`;

export const ContactImgDiv = styled.div`
  flex: 1;

  iframe {
    width: 600px;
    height: 350px;
    border: none;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
