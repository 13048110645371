import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { InnerBanner } from "../components/Home/Home";
import Footer from "../components/Footer/Footer";
import Blog from "../components/Blogs/Blog";

const BlogPage = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner />
      <Blog />
      <Footer />
    </div>
  );
};

export default BlogPage;
