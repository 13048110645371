import React from "react";
import {
  AddressCancekBtn,
  GenderDiv,
  GenderInput,
  GenderInputLabel,
  InputsDiv,
  MyProfileDiv,
  MyProfileGender,
  MyProfileHead,
  ProfileInput,
  ProfileOption,
  SaveMe,
  UserEditIcon,
} from "./MyProfileElements";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import { useUserDetails } from "../../hook/useUserDetails";
import { useEffect } from "react";
import { useUsersQuery } from "../../queries/api";

const ProfileSection = () => {
  const [editMode, setEditMode] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  // IMPORTED HOOKS FUNCTIONS
  const { updateUser } = useUserDetails();

  // FETCHING API DATA
  const { data: userProfile } = useUsersQuery();

  useEffect(() => {
    setUserDetails(userProfile);
  }, [userProfile]);

  // FUNCTION TO HANDLE UPDATE USER DETAILS
  const handleUpdateUserDetails = async () => {
    const requestBody = {
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      email: userDetails.email,
      billing: {
        phone: userDetails.billing.phone,
      },
    };

    updateUser(requestBody);
    setEditMode(false);
  };

  return (
    <MyProfileDiv>
      <MyProfileHead>
        Personal Information
        {!editMode && (
          <UserEditIcon
            onClick={() => setEditMode(true)}
            className="fas fa-user-edit"
          ></UserEditIcon>
        )}
      </MyProfileHead>
      <ProfileInput
        type="text"
        placeholder="First Name"
        disabled={!editMode}
        value={userDetails?.first_name}
        onChange={(e) =>
          setUserDetails({ ...userDetails, first_name: e.target.value })
        }
      />
      <ProfileInput
        type="text"
        placeholder="Last Name"
        disabled={!editMode}
        value={userDetails?.last_name}
        onChange={(e) =>
          setUserDetails({ ...userDetails, last_name: e.target.value })
        }
      />

      <GenderDiv>
        <MyProfileGender>Your Gender</MyProfileGender>
        <GenderInput
          disabled={!editMode}
          type="radio"
          value="Male"
          checked={userDetails?.gender === "Male"}
          onChange={(e) =>
            setUserDetails({ ...userDetails, gender: e.target.value })
          }
        />
        <GenderInputLabel>Male</GenderInputLabel>
        <GenderInput
          disabled={!editMode}
          type="radio"
          value="Female"
          checked={userDetails?.gender === "Female"}
          onChange={(e) =>
            setUserDetails({ ...userDetails, gender: e.target.value })
          }
        />
        <GenderInputLabel>Female</GenderInputLabel>
      </GenderDiv>
      <br />
      <InputsDiv>
        <ProfileInput
          placeholder="Email Address"
          disabled={!editMode}
          value={userDetails?.email}
          onChange={(e) =>
            setUserDetails({ ...userDetails, email: e.target.value })
          }
        />

        <PhoneInput
          disabled={!editMode}
          className="flag-border"
          value={userDetails?.billing?.phone}
          onChange={(value) =>
            setUserDetails({
              ...userDetails,
              billing: {
                ...userDetails.billing,
                phone: value,
              },
            })
          }
        />
      </InputsDiv>

      <br />

      {editMode && (
        <>
          <SaveMe onClick={handleUpdateUserDetails}>Save Changes</SaveMe>
          <AddressCancekBtn onClick={() => setEditMode(false)}>
            Cancel
          </AddressCancekBtn>
        </>
      )}
    </MyProfileDiv>
  );
};

export default ProfileSection;
