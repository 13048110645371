import React from "react";
import {
  MyProfileHead,
  OrderCard,
  OrderCards,
  OrderContentDiv,
  OrderImg,
  OrderPrice,
  OrderStatus,
  ProfileOption,
} from "./MyProfileElements";
import Skeleton from "react-loading-skeleton";
import { useOrdersQuery } from "../../queries/api";
import { useNavigate } from "react-router-dom";
import { WishListNull } from "../WishList/WishListElements";

const OrdersSection = () => {
  const navigate = useNavigate();

  // FETCHING API DATA
  const { data: orders, error: ordsEr, isLoading: ordsLdg } = useOrdersQuery();
  console.log("orders", orders);

  // CHECK IF ORDER LIST IS NULL
  const isNotOrder = !ordsLdg && !ordsEr && orders?.length === 0;

  return (
    <>
      <MyProfileHead>My Orders</MyProfileHead>
      {ordsLdg ? (
        <WishListNull>
          <div style={{ width: "100%" }}>
            <Skeleton height={80} />
            <Skeleton height={80} />
          </div>
        </WishListNull>
      ) : isNotOrder ? (
        <WishListNull>
          <p>No orders yet</p>
        </WishListNull>
      ) : (
        <OrderCards>
          {orders.map((order) => {
            const orderMessage = {
              completed: `Delivered on ${new Date(
                order?.date_completed
              ).toLocaleString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}`,
              processing: `Ordered on ${new Date(
                order?.date_created
              ).toLocaleString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}`,
              failed: "Order not placed",
              pending: "Order Pending",
              cancelled: "Order Cancelled",
              refunded: "Refunded Completed",
            }[order.status];

            const statusColor = {
              completed: "green",
              processing: "green",
              failed: "red",
              pending: "green",
              cancelled: "red",
              refunded: "red",
            }[order.status];
            return order.line_items.map((item, index) => {
              return (
                <OrderCard key={index}>
                  <OrderImg src={item.image.src} alt="" />
                  <OrderContentDiv>
                    <div style={{ display: "grid", gap: "3px" }}>
                      <p>{item.name}</p>
                      <p style={{ fontWeight: "400" }}>
                        Lorem ipsum dolor sit amet
                      </p>
                    </div>
                    <OrderPrice>
                      <p>{item.total}</p>
                    </OrderPrice>
                    <OrderStatus>
                      <i
                        style={{ color: { statusColor } }}
                        className="fa-solid fa-circle"
                      ></i>
                      <p>
                        {orderMessage}
                        {item.status}
                      </p>
                    </OrderStatus>
                  </OrderContentDiv>
                </OrderCard>
              );
            });
          })}
        </OrderCards>
      )}
    </>
  );
};

export default OrdersSection;
