import React from "react";
import {
  MarkIcon,
  TrashDiv,
  TrashIcon,
  WishFigCaption,
  WishFigure,
  WishImg,
  WishListNull,
  WishTable,
  WishTh,
  WishTr,
  Wishtd,
  XIcon,
} from "./WishListElements";
import Button from "../Button";
import { useState } from "react";
import { useWishList } from "../../hook/useWishList";

const WishlistTable = ({ wishList }) => {
  // STATE HOOKS
  const [wishId, setWishId] = useState(null);

  // IMPORTED HOOKS FUNCTIONS
  const { wishLoadingBtn, deleteWishList } = useWishList();

  // FUNCTION TO DELETE WISH LIST ITEM
  const handleDeleteWishList = (item) => {
    if (wishLoadingBtn) {
      return null;
    }

    setWishId(item.product_id);
    const data = {
      product_key: item.ID,
      wishlist_id: wishList?.wishlist_data[0].ID,
    };
    deleteWishList(data);
  };

  return (
    <WishTable>
      <WishTr>
        <WishTh>Product Name</WishTh>
        <WishTh>Unit Price</WishTh>
        <WishTh>Date Added</WishTh>
        <WishTh>Stock Status</WishTh>
        <WishTh></WishTh>
        <WishTh></WishTh>
      </WishTr>
      {wishList?.wishlist_items?.map((wishItems, index) => {
        const productId = parseInt(wishItems?.product_id);

        const body_data = wishItems?.product_data?.body;
        const productData = JSON.parse(body_data);

        const item = productData.find((item) => item?.id === productId);
        return (
          <WishTr key={index}>
            <Wishtd>
              <WishFigure>
                <WishImg src={item?.images[0]?.src} alt="" />
                <WishFigCaption>{item?.name}</WishFigCaption>
              </WishFigure>
            </Wishtd>
            <Wishtd>
              {item?.variable_max_price != item?.variable_min_price ? (
                <>
                  <span style={{ textDecoration: "line-through" }}>
                    ${item?.variable_max_price}
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    ${item?.variable_min_price}
                  </span>
                </>
              ) : (
                <span style={{ textDecoration: "underline" }}>
                  ${item?.variable_min_price}
                </span>
              )}
            </Wishtd>
            <Wishtd>November 14, 2023</Wishtd>
            <Wishtd>
              <WishFigure>
                <MarkIcon className="fa-solid fa-check"> </MarkIcon>
                <WishFigCaption>{item?.stock_status}</WishFigCaption>
              </WishFigure>
            </Wishtd>
            <Wishtd>
              <Button
                title={"Buy Now"}
                style={{
                  borderRadius: "0",
                  minWidth: "100px",
                  height: "35px",
                }}
              />
            </Wishtd>
            <Wishtd>
              {wishLoadingBtn && wishId === wishItems.product_id ? (
                <TrashDiv>
                  <TrashIcon className="fa-regular fa-trash-can fa-beat-fade"></TrashIcon>
                </TrashDiv>
              ) : (
                <TrashDiv>
                  <TrashIcon
                    onClick={() => handleDeleteWishList(wishItems)}
                    className="fa-regular fa-trash-can"
                  ></TrashIcon>
                </TrashDiv>
              )}
            </Wishtd>
          </WishTr>
        );
      })}
    </WishTable>
  );
};

export default WishlistTable;
